export { DynamicForm } from './context/DynamicFormContext';
export { default as Form } from './context/StyledForm';
export * from './context/Buttons';

export const TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  ARRAY: 'array',
  OBJECT: 'object',
};

export const TYPE_HELPER = {
  string: { defaultValue: '', class: String },
  number: { defaultValue: 0, class: Number },
  boolean: { defaultValue: false, class: Boolean },
  date: { defaultValue: null, class: Date },
  array: { defaultValue: [], class: Array },
  object: { defaultValue: {}, class: Object },
};
