import Editor from './Editor';
import Component from './Component';

const BigNumber = {
  Editor,
  Component,
  name: 'Total Quantities By Product',
  description: 'Displays Total Quantities By Product.'
}

export default BigNumber;