export default [
  { countryCode: 'CI', name: 'Africa/Abidjan' },
  { countryCode: 'GH', name: 'Africa/Accra' },
  { countryCode: 'DZ', name: 'Africa/Algiers' },
  { countryCode: 'GW', name: 'Africa/Bissau' },
  { countryCode: 'EG', name: 'Africa/Cairo' },
  { countryCode: 'MA', name: 'Africa/Casablanca' },
  { countryCode: 'ES', name: 'Africa/Ceuta' },
  { countryCode: 'EH', name: 'Africa/El_Aaiun' },
  { countryCode: 'ZA', name: 'Africa/Johannesburg' },
  { countryCode: 'SS', name: 'Africa/Juba' },
  { countryCode: 'SD', name: 'Africa/Khartoum' },
  { countryCode: 'NG', name: 'Africa/Lagos' },
  { countryCode: 'MZ', name: 'Africa/Maputo' },
  { countryCode: 'LR', name: 'Africa/Monrovia' },
  { countryCode: 'KE', name: 'Africa/Nairobi' },
  { countryCode: 'TD', name: 'Africa/Ndjamena' },
  { countryCode: 'LY', name: 'Africa/Tripoli' },
  { countryCode: 'TN', name: 'Africa/Tunis' },
  { countryCode: 'NA', name: 'Africa/Windhoek' },
  { countryCode: 'US', name: 'America/Adak' },
  { countryCode: 'US', name: 'America/Anchorage' },
  { countryCode: 'BR', name: 'America/Araguaina' },
  { countryCode: 'AR', name: 'America/Argentina/Buenos_Aires' },
  { countryCode: 'AR', name: 'America/Argentina/Catamarca' },
  { countryCode: 'AR', name: 'America/Argentina/Cordoba' },
  { countryCode: 'AR', name: 'America/Argentina/Jujuy' },
  { countryCode: 'AR', name: 'America/Argentina/La_Rioja' },
  { countryCode: 'AR', name: 'America/Argentina/Mendoza' },
  { countryCode: 'AR', name: 'America/Argentina/Rio_Gallegos' },
  { countryCode: 'AR', name: 'America/Argentina/Salta' },
  { countryCode: 'AR', name: 'America/Argentina/San_Juan' },
  { countryCode: 'AR', name: 'America/Argentina/San_Luis' },
  { countryCode: 'AR', name: 'America/Argentina/Tucuman' },
  { countryCode: 'AR', name: 'America/Argentina/Ushuaia' },
  { countryCode: 'PY', name: 'America/Asuncion' },
  { countryCode: 'CA', name: 'America/Atikokan' },
  { countryCode: 'BR', name: 'America/Bahia' },
  { countryCode: 'MX', name: 'America/Bahia_Banderas' },
  { countryCode: 'BB', name: 'America/Barbados' },
  { countryCode: 'BR', name: 'America/Belem' },
  { countryCode: 'BZ', name: 'America/Belize' },
  { countryCode: 'CA', name: 'America/Blanc-Sablon' },
  { countryCode: 'BR', name: 'America/Boa_Vista' },
  { countryCode: 'CO', name: 'America/Bogota' },
  { countryCode: 'US', name: 'America/Boise' },
  { countryCode: 'CA', name: 'America/Cambridge_Bay' },
  { countryCode: 'BR', name: 'America/Campo_Grande' },
  { countryCode: 'MX', name: 'America/Cancun' },
  { countryCode: 'VE', name: 'America/Caracas' },
  { countryCode: 'GF', name: 'America/Cayenne' },
  { countryCode: 'US', name: 'America/Chicago' },
  { countryCode: 'MX', name: 'America/Chihuahua' },
  { countryCode: 'CR', name: 'America/Costa_Rica' },
  { countryCode: 'CA', name: 'America/Creston' },
  { countryCode: 'BR', name: 'America/Cuiaba' },
  { countryCode: 'CW', name: 'America/Curacao' },
  { countryCode: 'GL', name: 'America/Danmarkshavn' },
  { countryCode: 'CA', name: 'America/Dawson' },
  { countryCode: 'CA', name: 'America/Dawson_Creek' },
  { countryCode: 'US', name: 'America/Denver' },
  { countryCode: 'US', name: 'America/Detroit' },
  { countryCode: 'CA', name: 'America/Edmonton' },
  { countryCode: 'BR', name: 'America/Eirunepe' },
  { countryCode: 'SV', name: 'America/El_Salvador' },
  { countryCode: 'CA', name: 'America/Fort_Nelson' },
  { countryCode: 'BR', name: 'America/Fortaleza' },
  { countryCode: 'CA', name: 'America/Glace_Bay' },
  { countryCode: 'GL', name: 'America/Godthab' },
  { countryCode: 'CA', name: 'America/Goose_Bay' },
  { countryCode: 'TC', name: 'America/Grand_Turk' },
  { countryCode: 'GT', name: 'America/Guatemala' },
  { countryCode: 'EC', name: 'America/Guayaquil' },
  { countryCode: 'GY', name: 'America/Guyana' },
  { countryCode: 'CA', name: 'America/Halifax' },
  { countryCode: 'CU', name: 'America/Havana' },
  { countryCode: 'MX', name: 'America/Hermosillo' },
  { countryCode: 'US', name: 'America/Indiana/Indianapolis' },
  { countryCode: 'US', name: 'America/Indiana/Knox' },
  { countryCode: 'US', name: 'America/Indiana/Marengo' },
  { countryCode: 'US', name: 'America/Indiana/Petersburg' },
  { countryCode: 'US', name: 'America/Indiana/Tell_City' },
  { countryCode: 'US', name: 'America/Indiana/Vevay' },
  { countryCode: 'US', name: 'America/Indiana/Vincennes' },
  { countryCode: 'US', name: 'America/Indiana/Winamac' },
  { countryCode: 'CA', name: 'America/Inuvik' },
  { countryCode: 'CA', name: 'America/Iqaluit' },
  { countryCode: 'JM', name: 'America/Jamaica' },
  { countryCode: 'US', name: 'America/Juneau' },
  { countryCode: 'US', name: 'America/Kentucky/Louisville' },
  { countryCode: 'US', name: 'America/Kentucky/Monticello' },
  { countryCode: 'BO', name: 'America/La_Paz' },
  { countryCode: 'PE', name: 'America/Lima' },
  { countryCode: 'US', name: 'America/Los_Angeles' },
  { countryCode: 'BR', name: 'America/Maceio' },
  { countryCode: 'NI', name: 'America/Managua' },
  { countryCode: 'BR', name: 'America/Manaus' },
  { countryCode: 'MQ', name: 'America/Martinique' },
  { countryCode: 'MX', name: 'America/Matamoros' },
  { countryCode: 'MX', name: 'America/Mazatlan' },
  { countryCode: 'US', name: 'America/Menominee' },
  { countryCode: 'MX', name: 'America/Merida' },
  { countryCode: 'US', name: 'America/Metlakatla' },
  { countryCode: 'MX', name: 'America/Mexico_City' },
  { countryCode: 'PM', name: 'America/Miquelon' },
  { countryCode: 'CA', name: 'America/Moncton' },
  { countryCode: 'MX', name: 'America/Monterrey' },
  { countryCode: 'UY', name: 'America/Montevideo' },
  { countryCode: 'BS', name: 'America/Nassau' },
  { countryCode: 'US', name: 'America/New_York' },
  { countryCode: 'CA', name: 'America/Nipigon' },
  { countryCode: 'US', name: 'America/Nome' },
  { countryCode: 'BR', name: 'America/Noronha' },
  { countryCode: 'US', name: 'America/North_Dakota/Beulah' },
  { countryCode: 'US', name: 'America/North_Dakota/Center' },
  { countryCode: 'US', name: 'America/North_Dakota/New_Salem' },
  { countryCode: 'MX', name: 'America/Ojinaga' },
  { countryCode: 'PA', name: 'America/Panama' },
  { countryCode: 'CA', name: 'America/Pangnirtung' },
  { countryCode: 'SR', name: 'America/Paramaribo' },
  { countryCode: 'US', name: 'America/Phoenix' },
  { countryCode: 'TT', name: 'America/Port_of_Spain' },
  { countryCode: 'HT', name: 'America/Port-au-Prince' },
  { countryCode: 'BR', name: 'America/Porto_Velho' },
  { countryCode: 'PR', name: 'America/Puerto_Rico' },
  { countryCode: 'CL', name: 'America/Punta_Arenas' },
  { countryCode: 'CA', name: 'America/Rainy_River' },
  { countryCode: 'CA', name: 'America/Rankin_Inlet' },
  { countryCode: 'BR', name: 'America/Recife' },
  { countryCode: 'CA', name: 'America/Regina' },
  { countryCode: 'CA', name: 'America/Resolute' },
  { countryCode: 'BR', name: 'America/Rio_Branco' },
  { countryCode: 'BR', name: 'America/Santarem' },
  { countryCode: 'CL', name: 'America/Santiago' },
  { countryCode: 'DO', name: 'America/Santo_Domingo' },
  { countryCode: 'BR', name: 'America/Sao_Paulo' },
  { countryCode: 'GL', name: 'America/Scoresbysund' },
  { countryCode: 'US', name: 'America/Sitka' },
  { countryCode: 'CA', name: 'America/St_Johns' },
  { countryCode: 'CA', name: 'America/Swift_Current' },
  { countryCode: 'HN', name: 'America/Tegucigalpa' },
  { countryCode: 'GL', name: 'America/Thule' },
  { countryCode: 'CA', name: 'America/Thunder_Bay' },
  { countryCode: 'MX', name: 'America/Tijuana' },
  { countryCode: 'CA', name: 'America/Toronto' },
  { countryCode: 'CA', name: 'America/Vancouver' },
  { countryCode: 'CA', name: 'America/Whitehorse' },
  { countryCode: 'CA', name: 'America/Winnipeg' },
  { countryCode: 'US', name: 'America/Yakutat' },
  { countryCode: 'CA', name: 'America/Yellowknife' },
  { countryCode: 'AQ', name: 'Antarctica/Casey' },
  { countryCode: 'AQ', name: 'Antarctica/Davis' },
  { countryCode: 'AQ', name: 'Antarctica/DumontDUrville' },
  { countryCode: 'AU', name: 'Antarctica/Macquarie' },
  { countryCode: 'AQ', name: 'Antarctica/Mawson' },
  { countryCode: 'AQ', name: 'Antarctica/Palmer' },
  { countryCode: 'AQ', name: 'Antarctica/Rothera' },
  { countryCode: 'AQ', name: 'Antarctica/Syowa' },
  { countryCode: 'AQ', name: 'Antarctica/Troll' },
  { countryCode: 'AQ', name: 'Antarctica/Vostok' },
  { countryCode: 'KZ', name: 'Asia/Almaty' },
  { countryCode: 'JO', name: 'Asia/Amman' },
  { countryCode: 'RU', name: 'Asia/Anadyr' },
  { countryCode: 'KZ', name: 'Asia/Aqtau' },
  { countryCode: 'KZ', name: 'Asia/Aqtobe' },
  { countryCode: 'TM', name: 'Asia/Ashgabat' },
  { countryCode: 'KZ', name: 'Asia/Atyrau' },
  { countryCode: 'IQ', name: 'Asia/Baghdad' },
  { countryCode: 'AZ', name: 'Asia/Baku' },
  { countryCode: 'TH', name: 'Asia/Bangkok' },
  { countryCode: 'RU', name: 'Asia/Barnaul' },
  { countryCode: 'LB', name: 'Asia/Beirut' },
  { countryCode: 'KG', name: 'Asia/Bishkek' },
  { countryCode: 'BN', name: 'Asia/Brunei' },
  { countryCode: 'RU', name: 'Asia/Chita' },
  { countryCode: 'MN', name: 'Asia/Choibalsan' },
  { countryCode: 'LK', name: 'Asia/Colombo' },
  { countryCode: 'SY', name: 'Asia/Damascus' },
  { countryCode: 'BD', name: 'Asia/Dhaka' },
  { countryCode: 'TL', name: 'Asia/Dili' },
  { countryCode: 'AE', name: 'Asia/Dubai' },
  { countryCode: 'TJ', name: 'Asia/Dushanbe' },
  { countryCode: 'CY', name: 'Asia/Famagusta' },
  { countryCode: 'PS', name: 'Asia/Gaza' },
  { countryCode: 'PS', name: 'Asia/Hebron' },
  { countryCode: 'VN', name: 'Asia/Ho_Chi_Minh' },
  { countryCode: 'HK', name: 'Asia/Hong_Kong' },
  { countryCode: 'MN', name: 'Asia/Hovd' },
  { countryCode: 'RU', name: 'Asia/Irkutsk' },
  { countryCode: 'ID', name: 'Asia/Jakarta' },
  { countryCode: 'ID', name: 'Asia/Jayapura' },
  { countryCode: 'IL', name: 'Asia/Jerusalem' },
  { countryCode: 'AF', name: 'Asia/Kabul' },
  { countryCode: 'RU', name: 'Asia/Kamchatka' },
  { countryCode: 'PK', name: 'Asia/Karachi' },
  { countryCode: 'NP', name: 'Asia/Kathmandu' },
  { countryCode: 'RU', name: 'Asia/Khandyga' },
  { countryCode: 'IN', name: 'Asia/Kolkata' },
  { countryCode: 'RU', name: 'Asia/Krasnoyarsk' },
  { countryCode: 'MY', name: 'Asia/Kuala_Lumpur' },
  { countryCode: 'MY', name: 'Asia/Kuching' },
  { countryCode: 'MO', name: 'Asia/Macau' },
  { countryCode: 'RU', name: 'Asia/Magadan' },
  { countryCode: 'ID', name: 'Asia/Makassar' },
  { countryCode: 'PH', name: 'Asia/Manila' },
  { countryCode: 'RU', name: 'Asia/Novokuznetsk' },
  { countryCode: 'RU', name: 'Asia/Novosibirsk' },
  { countryCode: 'RU', name: 'Asia/Omsk' },
  { countryCode: 'KZ', name: 'Asia/Oral' },
  { countryCode: 'ID', name: 'Asia/Pontianak' },
  { countryCode: 'KP', name: 'Asia/Pyongyang' },
  { countryCode: 'QA', name: 'Asia/Qatar' },
  { countryCode: 'KZ', name: 'Asia/Qyzylorda' },
  { countryCode: 'SA', name: 'Asia/Riyadh' },
  { countryCode: 'RU', name: 'Asia/Sakhalin' },
  { countryCode: 'UZ', name: 'Asia/Samarkand' },
  { countryCode: 'KR', name: 'Asia/Seoul' },
  { countryCode: 'CN', name: 'Asia/Shanghai' },
  { countryCode: 'SG', name: 'Asia/Singapore' },
  { countryCode: 'RU', name: 'Asia/Srednekolymsk' },
  { countryCode: 'TW', name: 'Asia/Taipei' },
  { countryCode: 'UZ', name: 'Asia/Tashkent' },
  { countryCode: 'GE', name: 'Asia/Tbilisi' },
  { countryCode: 'IR', name: 'Asia/Tehran' },
  { countryCode: 'BT', name: 'Asia/Thimphu' },
  { countryCode: 'JP', name: 'Asia/Tokyo' },
  { countryCode: 'RU', name: 'Asia/Tomsk' },
  { countryCode: 'MN', name: 'Asia/Ulaanbaatar' },
  { countryCode: 'CN', name: 'Asia/Urumqi' },
  { countryCode: 'RU', name: 'Asia/Ust-Nera' },
  { countryCode: 'RU', name: 'Asia/Vladivostok' },
  { countryCode: 'RU', name: 'Asia/Yakutsk' },
  { countryCode: 'MM', name: 'Asia/Yangon' },
  { countryCode: 'RU', name: 'Asia/Yekaterinburg' },
  { countryCode: 'AM', name: 'Asia/Yerevan' },
  { countryCode: 'PT', name: 'Atlantic/Azores' },
  { countryCode: 'BM', name: 'Atlantic/Bermuda' },
  { countryCode: 'ES', name: 'Atlantic/Canary' },
  { countryCode: 'CV', name: 'Atlantic/Cape_Verde' },
  { countryCode: 'FO', name: 'Atlantic/Faroe' },
  { countryCode: 'PT', name: 'Atlantic/Madeira' },
  { countryCode: 'IS', name: 'Atlantic/Reykjavik' },
  { countryCode: 'GS', name: 'Atlantic/South_Georgia' },
  { countryCode: 'FK', name: 'Atlantic/Stanley' },
  { countryCode: 'AU', name: 'Australia/Adelaide' },
  { countryCode: 'AU', name: 'Australia/Brisbane' },
  { countryCode: 'AU', name: 'Australia/Broken_Hill' },
  { countryCode: 'AU', name: 'Australia/Currie' },
  { countryCode: 'AU', name: 'Australia/Darwin' },
  { countryCode: 'AU', name: 'Australia/Eucla' },
  { countryCode: 'AU', name: 'Australia/Hobart' },
  { countryCode: 'AU', name: 'Australia/Lindeman' },
  { countryCode: 'AU', name: 'Australia/Lord_Howe' },
  { countryCode: 'AU', name: 'Australia/Melbourne' },
  { countryCode: 'AU', name: 'Australia/Perth' },
  { countryCode: 'AU', name: 'Australia/Sydney' },
  { countryCode: 'NL', name: 'Europe/Amsterdam' },
  { countryCode: 'AD', name: 'Europe/Andorra' },
  { countryCode: 'RU', name: 'Europe/Astrakhan' },
  { countryCode: 'GR', name: 'Europe/Athens' },
  { countryCode: 'RS', name: 'Europe/Belgrade' },
  { countryCode: 'DE', name: 'Europe/Berlin' },
  { countryCode: 'BE', name: 'Europe/Brussels' },
  { countryCode: 'RO', name: 'Europe/Bucharest' },
  { countryCode: 'HU', name: 'Europe/Budapest' },
  { countryCode: 'MD', name: 'Europe/Chisinau' },
  { countryCode: 'DK', name: 'Europe/Copenhagen' },
  { countryCode: 'IE', name: 'Europe/Dublin' },
  { countryCode: 'GI', name: 'Europe/Gibraltar' },
  { countryCode: 'FI', name: 'Europe/Helsinki' },
  { countryCode: 'TR', name: 'Europe/Istanbul' },
  { countryCode: 'RU', name: 'Europe/Kaliningrad' },
  { countryCode: 'UA', name: 'Europe/Kiev' },
  { countryCode: 'RU', name: 'Europe/Kirov' },
  { countryCode: 'PT', name: 'Europe/Lisbon' },
  { countryCode: 'GB', name: 'Europe/London' },
  { countryCode: 'LU', name: 'Europe/Luxembourg' },
  { countryCode: 'ES', name: 'Europe/Madrid' },
  { countryCode: 'MT', name: 'Europe/Malta' },
  { countryCode: 'BY', name: 'Europe/Minsk' },
  { countryCode: 'MC', name: 'Europe/Monaco' },
  { countryCode: 'RU', name: 'Europe/Moscow' },
  { countryCode: 'CY', name: 'Asia/Nicosia' },
  { countryCode: 'NO', name: 'Europe/Oslo' },
  { countryCode: 'FR', name: 'Europe/Paris' },
  { countryCode: 'CZ', name: 'Europe/Prague' },
  { countryCode: 'LV', name: 'Europe/Riga' },
  { countryCode: 'IT', name: 'Europe/Rome' },
  { countryCode: 'RU', name: 'Europe/Samara' },
  { countryCode: 'RU', name: 'Europe/Saratov' },
  { countryCode: 'UA', name: 'Europe/Simferopol' },
  { countryCode: 'BG', name: 'Europe/Sofia' },
  { countryCode: 'SE', name: 'Europe/Stockholm' },
  { countryCode: 'EE', name: 'Europe/Tallinn' },
  { countryCode: 'AL', name: 'Europe/Tirane' },
  { countryCode: 'RU', name: 'Europe/Ulyanovsk' },
  { countryCode: 'UA', name: 'Europe/Uzhgorod' },
  { countryCode: 'AT', name: 'Europe/Vienna' },
  { countryCode: 'LT', name: 'Europe/Vilnius' },
  { countryCode: 'RU', name: 'Europe/Volgograd' },
  { countryCode: 'PL', name: 'Europe/Warsaw' },
  { countryCode: 'UA', name: 'Europe/Zaporozhye' },
  { countryCode: 'CH', name: 'Europe/Zurich' },
  { countryCode: 'IO', name: 'Indian/Chagos' },
  { countryCode: 'CX', name: 'Indian/Christmas' },
  { countryCode: 'CC', name: 'Indian/Cocos' },
  { countryCode: 'TF', name: 'Indian/Kerguelen' },
  { countryCode: 'SC', name: 'Indian/Mahe' },
  { countryCode: 'MV', name: 'Indian/Maldives' },
  { countryCode: 'MU', name: 'Indian/Mauritius' },
  { countryCode: 'RE', name: 'Indian/Reunion' },
  { countryCode: 'WS', name: 'Pacific/Apia' },
  { countryCode: 'NZ', name: 'Pacific/Auckland' },
  { countryCode: 'PG', name: 'Pacific/Bougainville' },
  { countryCode: 'NZ', name: 'Pacific/Chatham' },
  { countryCode: 'FM', name: 'Pacific/Chuuk' },
  { countryCode: 'CL', name: 'Pacific/Easter' },
  { countryCode: 'VU', name: 'Pacific/Efate' },
  { countryCode: 'KI', name: 'Pacific/Enderbury' },
  { countryCode: 'TK', name: 'Pacific/Fakaofo' },
  { countryCode: 'FJ', name: 'Pacific/Fiji' },
  { countryCode: 'TV', name: 'Pacific/Funafuti' },
  { countryCode: 'EC', name: 'Pacific/Galapagos' },
  { countryCode: 'PF', name: 'Pacific/Gambier' },
  { countryCode: 'SB', name: 'Pacific/Guadalcanal' },
  { countryCode: 'GU', name: 'Pacific/Guam' },
  { countryCode: 'US', name: 'Pacific/Honolulu' },
  { countryCode: 'KI', name: 'Pacific/Kiritimati' },
  { countryCode: 'FM', name: 'Pacific/Kosrae' },
  { countryCode: 'MH', name: 'Pacific/Kwajalein' },
  { countryCode: 'MH', name: 'Pacific/Majuro' },
  { countryCode: 'PF', name: 'Pacific/Marquesas' },
  { countryCode: 'NR', name: 'Pacific/Nauru' },
  { countryCode: 'NU', name: 'Pacific/Niue' },
  { countryCode: 'NF', name: 'Pacific/Norfolk' },
  { countryCode: 'NC', name: 'Pacific/Noumea' },
  { countryCode: 'AS', name: 'Pacific/Pago_Pago' },
  { countryCode: 'PW', name: 'Pacific/Palau' },
  { countryCode: 'PN', name: 'Pacific/Pitcairn' },
  { countryCode: 'FM', name: 'Pacific/Pohnpei' },
  { countryCode: 'PG', name: 'Pacific/Port_Moresby' },
  { countryCode: 'CK', name: 'Pacific/Rarotonga' },
  { countryCode: 'PF', name: 'Pacific/Tahiti' },
  { countryCode: 'KI', name: 'Pacific/Tarawa' },
  { countryCode: 'TO', name: 'Pacific/Tongatapu' },
  { countryCode: 'UM', name: 'Pacific/Wake' },
  { countryCode: 'WF', name: 'Pacific/Wallis' },
];
