import {ConnexDataGrid} from "../../../../../../../components/connex-data-grid/components";
import React, {useEffect, useState} from "react";
import { Row } from 'react-data-grid';
import nonce from "../../../../../../../util/nonce";
import {useWidgetContext} from "../../../../useWidgetContext";
import getUomAbbreviationOrDescription from "../../../../../../../util/uom";

const getNumericValue = val => {
  return (val && !isNaN(+val)) ? +val : 0;
};

const accumulateSum = (total, value) => total + value;

const calcSummaryValue = (col, rows) => {
  const values = rows.map(r => getNumericValue(r[col.columnName]));
  switch (col.summaryType) {
    case 'average':
      const avg = values.reduce(accumulateSum) / values.length;
      return Math.round(avg * 100) / 100;
    case 'sum':
      const sum = values.reduce(accumulateSum);
      return Math.round(sum * 100) / 100;
    case 'count':
      return values.length;
    default:
      return undefined;
  }
};

const calcSummaryRow = (config, rows) => {
  if (config?.options?.showSummary && config.options.summaryColumns?.length && rows?.length) {
    const summary = { isSummary: true };

    let hasValues = false;
    for (let col of config.options.summaryColumns) {
      const value = calcSummaryValue(col, rows);
      if (value !== undefined) {
        summary[col.columnName] = value;
        hasValues = true;
      }
    }

    if (hasValues) {
      return summary;
    }
  }
  return undefined;
};

const Table = ({data}) => {
  const {config} = useWidgetContext();
  const [results, setResults] = useState();
  const [columns, setColumns] = useState([]);
  const loading = false;

  const rowRenderer = props => {
    const { row } = props;
    const isSummary = row?.isSummary ?? false;
    return (
      <div className={isSummary ? 'grid-row-summary' : 'grid-row'}>
        <Row {...props} />
      </div>
    );
  };

  useEffect(() => {
    const columns = [];
    const columnsLookup = {};
    if (data?.items) {

      for (const item of data.items) {
        for (const fieldName of Object.keys(item)) {
          const existingColumn = columnsLookup[fieldName];
          if (existingColumn) {
            continue;
          }

          columnsLookup[fieldName] = true;

          const fieldType = typeof item[fieldName];

          if (fieldType !== 'object' && !Array.isArray(item[fieldName])) {
            if(fieldName.startsWith('UOMCODE_')) {
              const formattedName = fieldName.substr(8);
              columns.push({
                key: fieldName,
                name: formattedName,
                getValue: row => getUomAbbreviationOrDescription(row[fieldName]),
              })
            } else {
              columns.push({
                key: fieldName,
                name: fieldName,
                getValue: row => typeof(row[fieldName]) === 'string' ? row[fieldName] : JSON.stringify(row[fieldName]),
              })
            }
          }
        }

        item._id = nonce();
      }

      const summary = calcSummaryRow(config, data?.items);

      setResults(summary ? data.items.concat([summary]) : data?.items);
      setColumns(columns);
    }
  }, [data])

  return (<ConnexDataGrid
    // showFiltersByDefault
    gridName={`${config.crn}::${config.id}`}
    columns={columns}
    rows={results}
    rowKeyPath="_id"
    rowRenderer={rowRenderer}
    // onRowClick={handleRowClick}
    // defaultFilters={{}}
    allowExport={true}
    filterable
    loading={loading}
    // rowClickable
    autoSizeColumns
    // actions={actions}
  />)
}

export default Table;