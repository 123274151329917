import style from './style';
import styled from 'styled-components';
import {useDashboardContext} from "../../../dashboard/useDashboardContext";
import {rangeOptions, getDateRange} from './rangeOptions';
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {TYPE, DynamicForm, Form} from "../../../../../components/dynamic-form";
import {useUserContext} from "../../../../../UserContextProvider";
import moment from "moment";
import {DateTime} from 'luxon';

const Styled = styled.div`${style}`;

const options = rangeOptions.map(id => ({value: id, label: id}));



const DateRangePicker = () => {
  const {filterValueChanged, filterValues} = useDashboardContext();
  const {entity} = useUserContext();

  const updateFilters = useCallback(value => {
    const range = getDateRange(value, entity.timeZone);
    filterValueChanged('startDateTime', range.startDate);
    filterValueChanged('endDateTime', range.endDate);
    filterValueChanged('quickDate', value);
  }, [filterValueChanged, entity])

  const formConfig = useMemo(() => {
    const zoneAbbr = DateTime.now().setZone(entity.timeZone).toFormat('ZZZZ');

    if(!entity) {
      return null;
    }

    return {
      sections: [{
        direction: 'row',
        fields: [
          {
            id: 'quickDate',
            label: 'Quick Dates',
            type: TYPE.STRING,
            ignoreIntl: true,
          },
          {
            id: 'startDateTime',
            label: `Start Date (${zoneAbbr})`,
            type: TYPE.DATE,
            ignoreIntl: true,
          }, {
            id: 'endDateTime',
            label: `End Date (${zoneAbbr})`,
            type: TYPE.DATE,
            ignoreIntl: true,
          }]
      }]
    }
  }, [entity])

  useEffect(() => {
    if(!filterValues?.quickDate) {
      updateFilters('Today');
    }
  }, [filterValues])

  if(!formConfig) {
    return null;
  }

  return <Styled>
    <DynamicForm values={filterValues}
                 formConfig={formConfig}
                 listValues={{quickDate: options}}
                 onChange={(id, value) => {
                   if(id === 'quickDate') {
                     updateFilters(value);
                   } else {
                     filterValueChanged(id, value)
                     filterValueChanged('quickDate', 'Custom Range');
                   }
                 }}>
      <Form formConfig={formConfig}/>
    </DynamicForm>


  </Styled>;
}

export default DateRangePicker;
