import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from '../../../filters/getParametersFromFilters';

export const useEntityMetrics = () => {
  const { entityRef } = useParams();
  const [ticketsByPlant, setTicketsByPlant] = useState({ loading: false, data: [] });

  const getTicketsByPlant = useCallback(
    filters => {
      const Parameters = getParametersFromFilters(filters);
      setTicketsByPlant(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Tickets By Plant', { Parameters }).then(rs => {
        setTicketsByPlant({ data: rs && rs.items, loading: false });
      });
    },
    [entityRef]
  );

  return {
    ticketsByPlant,
    getTicketsByPlant,
  };
};

export default useEntityMetrics;
