import { css } from 'styled-components';

export default css`
  padding: 0;
  margin: 15px;
  cursor: pointer;

  .button-content {
    width: 100px;
    border-radius: 2px;
    background-color: #177ddc;
    display: flex;
    flex-direction: column;
    height: 24px;
    position: relative;

    .icon-label {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      margin: 0 10px;
      z-index: 100;
    }
  }
`;
