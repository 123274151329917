import React from 'react';
import cx from 'classnames';
import { useDynamicFormContext } from '../context/DynamicFormContext';
import { isRequired } from '../util';

const DynamicCustom = ({ config, ...props }) => {
  const { touched, errors, isValid } = useDynamicFormContext();

  return (
    <div className="cx-dynamic-field">
      {React.cloneElement(config.component, {
        config,
        className: cx(props.className, `cx-dynamic-custom_${config.id}`, {
          'has-error': errors[config.id] && touched[config.id],
          required: isRequired(config),
          valid: isValid,
        }),
        error: errors[config.id],
        touched: touched[config.id],
        ...props,
      })}
    </div>
  );
};

export default DynamicCustom;
