import moment from 'moment';
import { RecordMetricsRq } from './api';
import { Queue } from './Queue';

const queue = new Queue(RecordMetricsRq, 10000);

export const callWithMetrics = (fn, metric, entityRef) => (...args) => {
  const startTime = moment();

  const result = fn(...args);

  // In case fn is a synchronous call.
  let endTime = moment();

  // Check for a promise
  if (result?.finally) {
    result.finally(() => {
      endTime = moment();
      const payload = { ...metric, entityRef, values: { run_time: endTime.diff(startTime) } };
      queue.add(payload);
    });
  } else {
    const payload = { ...metric, entityRef, values: { run_time: endTime.diff(startTime) } };
    queue.add(payload);
  }
};
