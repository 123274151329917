import {DynamicForm, Form, TYPE} from "../../../../../../components/dynamic-form";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {Space, Table} from "antd";
import Button from "../../../../../../components/button/Button";
import api from "../../../../../../api";
import {useParams} from "react-router-dom";
import {find} from "lodash";
import Input from "../../../../../../components/input";
import Select from "../../../../../../components/select";

const Styled = styled.div`
  margin: 15px;
  margin-top: 0;

  .form-group {
    margin-bottom: 5px;
  }
`;

const configPropertyName = 'summaryColumns';
const listValues = {
  summaryType: [
    {label: 'Count', value: 'count'},
    {label: 'Average', value: 'average'},
    {label: 'Sum', value: 'sum'}
  ]
};

const tableColumns = [
  { title: 'Column', dataIndex: 'columnName', key: 'columnName'},
  { title: 'Calculation', dataIndex: 'summaryType', key: 'summaryType'}
];

const SummaryColumns = ({ onChange, values }) => {
  const [columns, setColumns] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [column, setColumn] = useState(null);

  useEffect(() => {
    setColumns(values?.summaryColumns?.length ? values.summaryColumns : []);
  }, [values]);

  const onColumnChange = (field, value) => {
    setColumn({ ...column, [field]: value });
  };

  const onSaveColumn = () => {
    if (column?.columnName && column.summaryType) {
      if (column.key !== undefined) { delete column.key; }

      const idx = columns.findIndex(c => c.columnName === column.columnName);
      if (idx >= 0) {
        onChange(configPropertyName, columns.map((c, i) => i === idx ? column : c));
      } else {
        onChange(configPropertyName, [...columns, column]);
      }

      setColumn(null);
    }
    setEditMode(false);
  };

  const onRemoveColumn = () => {
    if (columns?.length > column.key) {
      const deleteName = columns[column.key].columnName;
      onChange(configPropertyName, columns.filter(c => c.columnName !== deleteName));
    }
    setColumn(null);
    setEditMode(false);
  };

  const onCancelColumnEdit = () => {
    setColumn(null);
    setEditMode(false);
  };

  const onColumnClick = record => {
    setColumn({ ...record });
    setEditMode(true);
  };

  return (
    <Styled>
      {editMode && (
        <Space direction={'vertical'}>
          <div className="form-group">
            <span>Column Name</span>
            <Input name={'columnName'} defaultValue={column?.columnName} onChange={e => onColumnChange('columnName', e.target.value)} />
          </div>

          <div className="form-group">
            <span>Summary Type</span>
            <Select
              name={'summaryType'}
              defaultValue={column?.summaryType}
              onChange={val => onColumnChange('summaryType', val)}
              options={listValues.summaryType}
              valuePath={'value'}
              displayPath={'label'}
            />
          </div>

          <Space>
            <Button onClick={onSaveColumn}>Save Column</Button>
            <Button onClick={onCancelColumnEdit}>Cancel</Button>
            {column?.key !== undefined && (
              <Button onClick={onRemoveColumn}>Delete</Button>
            )}
          </Space>
        </Space>
      )}

      {!editMode && (
        <Space direction={'vertical'}>
          <Table
            columns={tableColumns}
            dataSource={columns.map((c, i) => { return {...c, key: i}; })}
            pagination={false}
            onRow={record => {
              return {
                onClick: _ => onColumnClick(record)
              };
            }}
          />
          <Button onClick={() => setEditMode(true)}>Add Column</Button>
        </Space>
      )}
    </Styled>
  );
};

export default SummaryColumns;
