import React from 'react';
import Select from '../../select';
import countries from './countries';

export const Country = props => {
  return (
    <div className="country">
      <Select {...props} displayPath="countryName" valuePath="countryCode" options={countries} />
    </div>
  );
};

export default Country;
