import React, { useCallback } from 'react';
import { Space } from 'antd';
import {Form, DynamicForm, TYPE} from '../../../../../components/dynamic-form';

const listValues = {
  displayedStatus: [{
    label: 'PENDING', value: 'PENDING'
  },{
    label: 'RECEIVED', value: 'ACCEPTED'
  },{
    label: 'REJECTED', value: 'REJECTED'
  }]
}

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [{
      id: 'displayedStatus',
      label: `Select Field Label`,
      type: TYPE.STRING,
      ignoreIntl: true,
    },]
  }]
}

const Editor = ({ onChange, options = {displayedStatus: 'PENDING'}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>

    </Space>
  );
};

export default Editor;
