import React, { useCallback } from 'react';
import { Space } from 'antd';

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <p>No Additional Setup Needed</p>
    </Space>
  );
};

export default Editor;
