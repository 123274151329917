import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import style from './style';

const Input = ({ className, innerRef, password, ...props }) => {
  return (
    <AntInput
      type={password ? 'password' : 'text'}
      className={cn('cx-input', className)}
      {...props}
      ref={innerRef}
    />
  );
};

Input.propTypes = {
  className: propTypes.string,
  innerRef: propTypes.string,
  password: propTypes.oneOfType([propTypes.bool, propTypes.string]),
};

export default styled(Input)`
`;
