import React from 'react';
import _ from 'lodash';
import { trimName } from '../../../../../components/charts/util';
import BarChart from '../../../../../components/charts/BarChart';
import getUomAbbreviationOrDescription from '../../../../../util/uom';

const TotalQuantitiesByCustomer = ({ data, config, onClick, limit = 35 }) => {
  const fieldName = 'customer_name';
  let sortedData = [];

  const reducedData = data?.reduce((acc, item) => {
    const itemName = trimName(item[fieldName], 'No Customer');
    const newItem = {};
    const type = trimName(item.type, 'NONE');
    const predicate = {};
    _.set(predicate, fieldName, itemName);
    const indexOfExistingItemInResult = _.findIndex(acc, predicate);
    let displayLabelText = `${itemName}`;
    if (indexOfExistingItemInResult === -1) {
      if (config) {
        displayLabelText = `
        ${config.customerLabelShowId ? item.customer_id : ''}
        ${config.customerLabelShowId && config.customerLabelShowName ? ' / ' : ''}
        ${config.customerLabelShowName ? itemName : ''}               
        ${config.customerLabelShowQuantity ? ` : ${item.val}` : ''}
      `;
      }
      _.set(newItem, fieldName, itemName);
      _.set(newItem, 'name', displayLabelText);
      _.set(newItem, type, item.val);
      _.set(newItem, 'type', type);
      _.set(newItem, 'qty_sum', item.val);
      _.set(newItem, 'unit', getUomAbbreviationOrDescription(item.UOM));
      acc.push(newItem);
    } else {
      if (acc[indexOfExistingItemInResult][type] > 0) {
        _.set(acc[indexOfExistingItemInResult], type, +acc[indexOfExistingItemInResult][type] + +item.val);
      } else {
        _.set(acc[indexOfExistingItemInResult], type, item.val);
      }
      _.set(acc[indexOfExistingItemInResult], 'qty_sum', +acc[indexOfExistingItemInResult].qty_sum + +item.val);
      if (config) {
        displayLabelText = `
        ${config.customerLabelShowId ? item.customer_id : ''}
        ${config.customerLabelShowId && config.customerLabelShowName ? '/' : ''}
        ${config.customerLabelShowName ? itemName : ''}                            
        ${config.customerLabelShowQuantity ? ` : ${acc[indexOfExistingItemInResult].qty_sum}` : ''}
      `;
      }
      _.set(acc[indexOfExistingItemInResult], 'name', displayLabelText);
    }
    return acc;
  }, []);

  if (reducedData) {
    sortedData = _.orderBy(reducedData, ['qty_sum'], ['desc']);
  }

  return (
    <BarChart
      fieldName={fieldName}
      onClick={onClick}
      showXAxis={config?.displayXAxis || false}
      xAxisLabel={false}
      yAxis={false}
      data={sortedData.slice(0, limit)}
      xValue={'qty_sum'}
      xValueType={'number'}
      layout={'vertical'}
      yAxisWidth={500}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
    />
  );
};

export default TotalQuantitiesByCustomer;
