import { Button as AntButton } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { callWithMetrics } from '../../util/metrics/recordMetric';
import style from './style';
import { kebabCase } from 'lodash';

const Button = ({ metricId, onClick, ...props }) => {
  let clickHandler = onClick;

  const { entityRef } = useParams();

  if (metricId) {
    const metric = { type: 'user-interaction', data: { type: 'button-click', id: metricId } };
    clickHandler = callWithMetrics(onClick, metric, entityRef);
  }

  return <AntButton {...props} onClick={clickHandler} data-testid={props.children === '...' ? 'btn-dashboard-menu' : kebabCase(`btn-${props.children}`)} />;
};

export default styled(Button)`
  ${style}
`;
