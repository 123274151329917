import { css } from 'styled-components';

export default css`
  .label-spinner {
    display: flex;
    align-items: center;
    .label {
      
    }
    .spinner {
      margin-left: 10px;
      font-size: .8em;
      &:not(.spinning) {
        display: none;
      }
    }
  }
`;
