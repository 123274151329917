import { Switch } from 'antd';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
  display: flex;
  align-items: center;
`;
const FilterSwitch = props => {
  const { filterable, filterEnabled, setFilterEnabled } = useGridContext();

  return (
    <Styled className={cn('filter-switch')} {...props}>
      {filterable && (
        <span>
          <Switch size="small" checked={filterEnabled} onChange={setFilterEnabled} /> Column Filters
        </span>
      )}
    </Styled>
  );
};

export default FilterSwitch;
