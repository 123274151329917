import { css } from 'styled-components';

export default css`
  &&& {
    margin: 0;
    padding: 0;
    background: var(--tabs-tabs-list-background-color);
    border: 0;
    outline: none;
    transition: all 0.3s;
    &.ant-tabs-top {
      .ant-tabs-nav {
        &:before {
          border: none;
        }
        margin: 0;
      }
    }

    &.ant-tabs-card {
      > .ant-tabs-nav {
        margin: 5px 0 0 0;
        &:before {
          border-bottom: 1px solid var(--tabs-card-border-bottom-color);
          transition: all 0.3s;
        }
        .ant-tabs-extra-content {
          padding-right: 5px;
        }

        .ant-tabs-nav-list {
          left: 5px;
        }

        .ant-tabs-nav-add {
          border: 1px solid var(--tabs-card-border-color);
          border-bottom: 1px solid var(--tabs-card-border-bottom-color);
          background-color: var(--tabs-card-background-color);
          color: var(--tabs-card-add-color);
          transition: all 0.3s;
          &:focus,
          &:active {
            color: inherit;
          }
          &:hover {
            color: var(--tabs-card-add-hover-color);
          }
          svg {
            height: 1.3em;
            width: 1.3em;
          }
        }

        .ant-tabs-tab {
          margin-right: 5px !important;
          //max-width: 201px;
          height: ${({ theme }) => `${32 * theme.fontSizeMultiplier}px`};
          transition: all 0.3s;
          &.ant-tabs-tab-active {
            border: 1px solid var(--tabs-card-active-border-color);
            border-bottom: 1px solid var(--tabs-card-active-background-color);
            background-color: var(--tabs-card-active-background-color);
            transition: all 0.3s;
            .cx-text {
              color: var(--tabs-card-active-color);
              transition: color 0.3s;
            }
            .ant-tabs-tab-remove {
              color: var(--tabs-card-active-close-icon-color);
            }
            .ant-tabs-tab-btn {
              color: var(--tabs-card-active-color);
              whitespace: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: all 0.3s;
            }
          }
          &:not(.ant-tabs-tab-active) {
            border: 1px solid var(--tabs-card-border-color);
            border-bottom: 1px solid var(--tabs-card-border-bottom-color);
            background-color: var(--tabs-card-background-color);
            transition: all 0.3s;
            //top: 3px;
            .ant-tabs-tab-remove {
              color: var(--tabs-card-close-icon-color);
              transition: all 0.3s;
            }
            .ant-tabs-tab-btn {
              color: var(--tabs-card-color);
              transition: all 0.3s;
              .ant-typography {
                color: var(--tabs-card-color) !important;
                transition: all 0.3s;
              }
              whitespace: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .ant-tabs-tab-btn {
      &:focus {
        outline: none;
      }
    }
  }
`;
