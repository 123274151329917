import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import style from './style';
import API from '../../../../../api';
import cn from "classnames";
import {useParams} from "react-router-dom";
import {ConnexDataGrid} from "../../../../../components/connex-data-grid/components";
import nonce from "../../../../../util/nonce";

const Styled = styled.div`
  ${style}
`

const QueryResultsComponent = ({ className }) => {
  const {entityRef} = useParams();
  const { config, parentRef, filterValues } = useWidgetContext();
  const [results, setResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const queryRef = config?.options?.queryRef;

    const run = async () => {
      setLoading(true);
      const queryResults = await API.executeQuery(entityRef, queryRef, {
        Parameters: [{
          Name: 'StartDt',
          Value: filterValues.startDateTime,
          Type: 'String',
        },{
          Name: 'EndDt',
          Value: filterValues.endDateTime,
          Type: 'String',
        }]
      });

      const items = queryResults?.items ?? [];

      const columns = [];
      const columnsLookup = {};

      for(const item of items) {
        for(const fieldName of Object.keys(item)) {
          const existingColumn = columnsLookup[fieldName];
          if(existingColumn) {
            continue;
          }

          columnsLookup[fieldName] = true;

          const fieldType = typeof item[fieldName];

          if(fieldType !== 'object' && !Array.isArray(item[fieldName])) {
            columns.push({
              key: fieldName,
              name: fieldName,
              // getValue: row => row[fieldName],
            })
          }
        }

        item._id = nonce();
      }

      setResults(items);
      setColumns(columns);
      setLoading(false);
    };

    if(queryRef && entityRef) {
      run().then();
    }
  }, [config?.options?.queryRef, entityRef, filterValues])

  return (
    <Styled ref={parentRef} className={cn(className)}>
      <ConnexDataGrid
        // showFiltersByDefault
        gridName={`${config.crn}::${config.id}`}
        columns={columns}
        rows={results}
        rowKeyPath="_id"
        // onRowClick={handleRowClick}
        // defaultFilters={{}}
        allowExport={true}
        filterable
        loading={loading}
        // rowClickable
        autoSizeColumns
        // actions={actions}
      />
    </Styled>
  );
};

export default QueryResultsComponent;

