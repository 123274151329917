import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [features, setFeatures] = useState({ loading: false, data: [] });

  const getFeatures = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setFeatures(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Entity Feature Count', Parameters).then(rs => {
        setFeatures({ data: rs && rs.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    features,
    getFeatures,
  };
};

export default useEntityMetrics;
