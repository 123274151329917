import React from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import styled from 'styled-components';
import useWidgetSubscription from '../../../hooks/useWidgetSubscription';


const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const columns = [
  { key: 'entity', name: 'Entity' },
  { key: 'crn', name: 'CRN' },
  { key: 'featureCount', name: 'Feature Count' },
];

const EntityFeaturesCount = ({ data, loading, config }) => {
  const { publish } = useWidgetSubscription();

  const handleRowClick = React.useCallback(
    row => {
      publish({ action: 'select-entity', value: row.crn });
    },
    [data]
  );

  return (
    <Styled>
      <ConnexDataGrid
        showFiltersByDefault
        filterable
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        rowKeyPath="crn"
        onRowClick={handleRowClick}
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default EntityFeaturesCount;
