import React, { useCallback, useMemo } from 'react';
import { Space } from 'antd';
import { DynamicForm, Form, TYPE } from '../../../../../components/dynamic-form';
import { cloneDeep } from 'lodash';

const listValues = {
  primaryLabelShowId: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  primaryLabelShowDescription: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  displayRowNumbers: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  displayLegend: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  displayTooltip: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  displayXAxis: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  maxResults: [
    {
      label: '10',
      value: 10,
    },
    {
      label: '15',
      value: 15,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '35',
      value: 35,
    },
    {
      label: '50',
      value: 50,
    },
  ],
  groupBy: [
    {
      label: 'Customer',
      value: 'customer',
    },
    {
      label: 'Project',
      value: 'project',
    },
    {
      label: 'Plant',
      value: 'plant',
    },
  ],
};

const formConfig = {
  sections: [
    {
      label: 'Section A',
      fields: [
        {
          id: 'primaryLabelShowId',
          label: `Show Primary ID On Label?`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'primaryLabelShowDescription',
          label: `Show Primary Name On Label?`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'displayRowNumbers',
          label: `Show Row Numbers?`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'displayLegend',
          label: `Show Margin Legend?`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'displayTooltip',
          label: `Show Tooltip`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'displayXAxis',
          label: `Show X Axis?`,
          type: TYPE.BOOLEAN,
          ignoreIntl: true,
        },
        {
          id: 'maxResults',
          label: 'Maximum Rows Shown',
          type: TYPE.STRING,
          ignoreIntl: true,
        },
        {
          id: 'groupBy',
          label: 'Group By (Requires Refresh)',
          type: TYPE.STRING,
          ignoreIntl: true,
        },
      ],
    },
  ],
};

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  const procesedConfig = useMemo(() => {
    if (options.groupBy !== 'plant') return formConfig;

    const clone = cloneDeep(formConfig);
    clone.sections[0].fields.push({
      id: 'plantMarginTrend',
      label: 'Plant Margin Trend (Requires Refresh)',
      type: TYPE.STRING,
      ignoreIntl: true,
    });
    return clone;
  }, [options.groupBy]);

  const procesedListValues = useMemo(() => {
    if (options.groupBy !== 'plant') return listValues;

    const clone = cloneDeep(listValues);
    clone.plantMarginTrend = [
      {
        label: 'Daily',
        value: 'daily',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
      {
        label: 'None',
        value: 'none',
      },
    ];
    return clone;
  }, [options.groupBy]);

  return (
    <Space direction={'vertical'}>
      <DynamicForm
        values={options}
        onChange={onConfigChange}
        formConfig={procesedConfig}
        listValues={procesedListValues}
      >
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
