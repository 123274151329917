import attachmentsPerOrigin from './attachments-per-origin';
import displayOptions from './display-options';
import displayLayoutSize from './display-layout-size';
import widgetsOnScreen from './widgets-on-screen';
import avgLoadTime from './avg-load-time';
import avgLoadsPerDriver from './avg-loads-per-driver';
import deliveredQty from './delivered-qty';
import entityFeatureCount from './entity-feature-count';
import entityFeatures from './entity-feature-flags';
import firstLoadOnTime from './first-load-on-time';
import probeEventHistoryByVehicle from './probe-events-history-by-vehicle';
import probeVehicles from './probe-vehicles';
import ticketsByPlant from './tickets-by-plant';
import ticketCountByDeliveryMethod from './ticket-count-by-delivery-method';
import ticketCountByStatus from './ticket-count-by-status';
import ticketCountByStatusBar from './ticket-count-by-status-bar';
import ticketsMissingAttachments from './tickets-missing-attachments';
import ticketsProduced from './tickets-produced';
import ticketsExpectingUnloadingEvents from './tickets-expecting-unloading-events';
import totalQuantitiesByCustomer from './total-quantities-by-customer';
import totalQuantitiesByHour from './total-quantities-by-hour';
import totalQuantitiesByPlant from './total-quantities-by-plant';
import totalQuantitiesByProduct from './total-quantities-by-product';
import totalQuantitiesByProject from './total-quantities-by-project';
import totalQuantitiesBySupplier from './total-quantities-by-supplier';
import totalQuantitiesByStatus from './total-quantities-by-status';
import widgetPublisherTest from './widget-publisher-test';
import widgetSubscriberTest from './widget-subscriber-test';
import unbilledDeliverySummary from './unbilled-delivery-summary';
import unbilledDeliveryList from './unbilled-delivery-list';
import unbilledDeliveryItem from './unbilled-delivery-item';
import iframeWidget from './iframe';
import queryResults from './query-results';
import customWidget from './custom-widget';
import totalMarginsGroupedBy from './total-margins-grouped-by';

export const UnusedWidgets = {
  'display-options': displayOptions,
  'display-layout-size': displayLayoutSize,
  'widgets-on-screen': widgetsOnScreen,
  'widget-publisher-test': widgetPublisherTest,
  'widget-subscriber-test': widgetSubscriberTest,
};

const PresetWidgets = {
  'unbilled-delivery-summary': unbilledDeliverySummary,
  'unbilled-delivery-list': unbilledDeliveryList,
  'unbilled-delivery-item': unbilledDeliveryItem,
  'attachments-per-origin': attachmentsPerOrigin,
  'avg-load-time': avgLoadTime,
  // 'avg-load-size': avgLoadSize,
  'avg-loads-per-driver': avgLoadsPerDriver,
  'delivered-qty': deliveredQty,
  'entity-feature-count': entityFeatureCount,
  'entity-feature-flags': entityFeatures,
  'first-load-on-time': firstLoadOnTime,
  'probe-event-history-by-vehicle': probeEventHistoryByVehicle,
  'probe-vehicles': probeVehicles,
  'tickets-by-plant': ticketsByPlant,
  'ticket-count-by-delivery-method': ticketCountByDeliveryMethod,
  'ticket-count-by-status': ticketCountByStatus,
  'ticket-count-by-status-bar': ticketCountByStatusBar,
  'tickets-expecting-unloading-events': ticketsExpectingUnloadingEvents,
  'tickets-missing-attachments': ticketsMissingAttachments,
  'tickets-produced': ticketsProduced,
  'total-quantities-by-customer': totalQuantitiesByCustomer,
  'total-quantities-by-hour': totalQuantitiesByHour,
  'total-quantities-by-plant': totalQuantitiesByPlant,
  'total-quantities-by-product': totalQuantitiesByProduct,
  'total-quantities-by-project': totalQuantitiesByProject,
  'total-quantities-by-supplier': totalQuantitiesBySupplier,
  'total-quantities-by-status': totalQuantitiesByStatus,
  'total-margins-grouped-by': totalMarginsGroupedBy,
  iframe: iframeWidget,
  'query-results': queryResults,
  'custom-widget': customWidget,
};

export default PresetWidgets;
