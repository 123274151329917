import React from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import FieldContainer from "../../../../../../../../components/field-container/FieldContainer";
import Select from "../../../../../../../../components/form/Select";

const { Option } = Select;

const View = styled(FieldContainer)`${style}`;

const InputSelect = ({ className, id, label, value, options = [], onChange, required = true }) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Select value={value || ''}
              disabled={false}
              data-testid={id}
              onChange={value => onChange ? onChange(id, value) : console.log(id, value)}>
        {!required && <Option value=''>Select...</Option>}
        {options?.map((option, index) => (
          <Option key={`${index}-${id}-option`} value={option.value}>{option.label || option.value}</Option>
        ))}
      </Select>
    </View>
  );
};

export default InputSelect;
