import React from 'react';
import _ from 'lodash';
import { trimName } from '../../../../../components/charts/util';
import BarChart from '../../../../../components/charts/BarChart';

const TicketsByPlant = ({ data, config, limit = 35 }) => {
  const fieldName = 'plant';
  let sortedData = [];

  const reducedData = data?.reduce((acc, item) => {
    const itemName = trimName(item[fieldName], 'No Plants');
    const newItem = {};
    const type = 'Tickets';
    const predicate = {};
    _.set(predicate, fieldName, itemName);
    const indexOfExistingItemInResult = _.findIndex(acc, predicate);
    let displayLabelText = `${itemName} ${item.ticketCount}`;
    if (config) {
      displayLabelText = `
        ${config.showPlantName ? itemName : ''}
        ${config.showPlantName && config.showTicketCount ? ':' : ''}
        ${config.showTicketCount ? `${item.ticketCount} tickets` : ''}
       `;
    }
    if (indexOfExistingItemInResult === -1) {
      _.set(newItem, fieldName, itemName);
      _.set(newItem, 'ticketCount', item.ticketCount);
      _.set(newItem, 'name', displayLabelText);
      _.set(newItem, 'type', type);
      _.set(newItem, type, item.ticketCount);
      acc.push(newItem);
    } else {
      _.set(acc[indexOfExistingItemInResult], type, item.ticketCount);
    }
    return acc;
  }, []);

  if (reducedData) {
    sortedData = _.orderBy(reducedData, ['ticketCount'], ['desc']);
  }

  return (
    <BarChart
      fieldName={'plant'}
      yAxis={false}
      data={sortedData.slice(0, limit)}
      yValue="name"
      yValueType="category"
      xValue="ticketCount"
      xValueType="number"
      layout={'vertical'}
      showXAxis={config?.displayXAxis || false}
      yAxisWidth={500}
      showLegend={config?.displayLegend || false}
    />
  );
};

export default TicketsByPlant;
