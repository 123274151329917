import React, { useCallback, useEffect } from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import TotalQuantitiesByProduct from './TotalQuantitiesByProduct';
import useAPI from './useAPI';
import Spinner from '../../../../../components/spinner/Spinner';
import styled from 'styled-components';
import cn from 'classnames';

const Component = ({ className }) => {
  const { config, filterValues, parentRef } = useWidgetContext();
  const { totalQuantitiesByProduct, getTotalQuantitiesByProduct } = useAPI();

  const refresh = useCallback(() => {
    getTotalQuantitiesByProduct(filterValues);
  }, [filterValues, getTotalQuantitiesByProduct]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={totalQuantitiesByProduct.loading}>
        <TotalQuantitiesByProduct
          config={config?.options}
          data={totalQuantitiesByProduct.data}
          limit={config?.options?.maxResults}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
