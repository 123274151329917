import hostname from './hostname';
import queryString from 'query-string';

async function post(path = '', data = {}, queryParams = {}) {
  const query = queryString.stringify(queryParams);

  const response = await fetch(`${hostname(path)}${path}${query ? `?${query}` : ''}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM',
      'x-connex-id': localStorage.getItem('x-connex-id'),
    },
    body: JSON.stringify(data),
  });
  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  throw error;
}

async function patch(path = '', data = {}, queryParams = {}) {
  const query = queryString.stringify(queryParams);

  const response = await fetch(`${hostname(path)}${path}${query ? `?${query}` : ''}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM',
      'x-connex-id': localStorage.getItem('x-connex-id'),
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  throw error;
}

async function get(path = '', queryParams = {}) {
  const query = queryString.stringify(queryParams);

  const response = await fetch(`${hostname(path)}${path}${query ? `?${query}` : ''}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM',
      'x-connex-id': localStorage.getItem('x-connex-id'),
    },
  });

  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  throw error;
}

export default {
  get,
  post,
  patch,
};
