import React from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import styled from 'styled-components';

const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const columns = [
  { key: 'origin', name: 'Origin' },
  { key: 'ticketCount', name: 'Ticket Count' },
  { key: 'attachmentCount', name: 'Attachment Count' },
];

const AttachmentsPerOrigin = ({ data, loading, config }) => {

  return (
    <Styled>
      <ConnexDataGrid
        showFiltersByDefault
        filterable
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        rowKeyPath="crn"
        //onRowClick={handleRowClick}
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default AttachmentsPerOrigin;
