import React from 'react';
import _ from 'lodash';
import useFitText from '../../components/useFitText'

const FirstLoadOnTime = ({ data, config}) => {
  const value = JSON.stringify(data, null, 2);
  const {fontSize, ref, parentRef} = useFitText(value); 
  const timeWindow = config?.timeWindow || 15;
  let percentOnTime = 0;

  if (data){
    const ticketCount = data.length > 0 ? data.length : 1;
    let onTime = 0;
    for (let i = 0; i < data.length; i++){
      const d = data[i].atJobDiff;
      if(d >= -timeWindow  && d <= timeWindow) {
      onTime += 1
      }
      else{
        onTime += 0 
      }
    }
    const calc = (onTime / ticketCount)*100;
    percentOnTime = calc.toFixed(2);
  }
  

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        <div>{percentOnTime}{'%'}</div>
      </div>
    </div>
  );
};

export default FirstLoadOnTime;