import React from 'react';
import _ from 'lodash';
import { trimName} from "../../../../../components/charts/util";
import BarChart from "../../../../../components/charts/BarChart";

const TicketsByDeliveryMethod = ({ data}) => {
  const fieldName = 'deliveredMethod';

  const reducedData = data?.reduce((acc, item) => {
    const itemName = trimName(item[fieldName], 'No Delivery Method');
    const newItem = {};
    const type = 'Tickets';
    const predicate = {};
    _.set(predicate, fieldName, itemName);
    const indexOfExistingItemInResult = _.findIndex(acc, predicate)
    let displayLabelText = `${itemName}: ${item.ticketCount}`;
    if (indexOfExistingItemInResult === -1) {
      _.set(newItem, fieldName, itemName);
      _.set(newItem, 'ticketCount', item.ticketCount);
      _.set(newItem, 'name', displayLabelText);
      _.set(newItem, 'type', type);
      _.set(newItem, type, item.ticketCount);
      acc.push(newItem);
    } else {
      _.set(acc[indexOfExistingItemInResult], type, item.ticketCount);
    }
    return acc;
  }, []);



  return (
    <BarChart
      fieldName={fieldName}
      yAxis={false}
      data={reducedData}
      yValue="name"
      yValueType="category"
      xValue="ticketCount"
      xValueType="number"
      layout={'vertical'}
      showXAxis={true}
      yAxisWidth={500}
      showLegend={false}
    />
  );
};

export default TicketsByDeliveryMethod;


