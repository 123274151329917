import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styled, { ThemeProvider } from 'styled-components';
import { useDashboardContext } from './useDashboardContext';
import Tabs from '../../../components/tabs';
import Worksheet from './worksheet';
import nonce from '../../../util/nonce';
import EditableInput from '../../../components/editable-input';
import { useDashboardPreview } from '../hooks/useDashboardPreview';

const { TabPane } = Tabs;

const DashboardContent = ({ className }) => {
  const { dashboard, updateDashboard, frozen } = useDashboardContext();
  const PreviewButton = useDashboardPreview();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const remove = useCallback(
    targetKey => {
      let newSelectedWorksheet =
        dashboard.selectedWorksheet === targetKey ? dashboard?.worksheets?.[0].id : dashboard.selectedWorksheet;
      updateDashboard({
        ...dashboard,
        worksheets: dashboard?.worksheets.filter(w => targetKey !== w.id),
        selectedWorksheet: `${newSelectedWorksheet}`,
      });
    },
    [updateDashboard, dashboard]
  );

  const add = useCallback(() => {
    const id = nonce();
    const worksheets = dashboard?.worksheets || [];
    updateDashboard({
      ...dashboard,
      worksheets: [
        ...worksheets,
        {
          name: `New Tab (${worksheets.length + 1})`,
          widgets: [],
          id,
        },
      ],
      selectedWorksheet: id,
    });
  }, [updateDashboard, dashboard]);

  const worksheetNameRef = useRef({});

  if (loading) {
    return <div>Loading...</div>;
  }

  const showSinglePage = frozen && dashboard?.worksheets?.length === 1;

  if (showSinglePage) {
    return (
      <Worksheet
        config={dashboard.worksheets[0]}
        onChange={updatedWorksheet => {
          const newDashboard = {
            ...dashboard,
            worksheets: dashboard.worksheets.map((value, i) => {
              if (updatedWorksheet.id === value.id) {
                return updatedWorksheet;
              }
              return value;
            }),
          };
          updateDashboard(newDashboard);
        }}
      />
    );
  }

  return (
    <div className={cn(className)}>
      <div className="preview-selection">
        <PreviewButton />
      </div>
      <Tabs
        type={frozen ? 'card' : 'editable-card'}
        defaultActiveKey={dashboard?.selectedWorksheet || dashboard?.worksheets?.[0]}
        onChange={activeKey => {
          updateDashboard({
            ...dashboard,
            worksheets: dashboard.worksheets.map((value, i) => {
              if (worksheetNameRef.current[value.id]) {
                return {
                  ...value,
                  name: worksheetNameRef.current[value.id],
                };
              }
              return value;
            }),
            selectedWorksheet: activeKey,
          });
        }}
        onEdit={(e, action) => {
          switch (action) {
            case 'add': {
              add();
              break;
            }
            case 'remove': {
              remove(e);
              break;
            }
          }
        }}
      >
        {dashboard?.worksheets?.map((worksheet, index) => {
          return (
            <TabPane
              key={worksheet.id}
              tab={
                <EditableInput
                  readOnly={frozen || dashboard?.selectedWorksheet !== worksheet.id}
                  value={worksheet.name}
                  onChange={newName => {
                    worksheetNameRef.current[worksheet.id] = newName;
                    const newDashboard = {
                      ...dashboard,
                      worksheets: dashboard.worksheets.map((value, i) => {
                        if (worksheet.id === value.id) {
                          return {
                            ...worksheet,
                            name: newName,
                          };
                        }
                        return value;
                      }),
                    };
                    updateDashboard(newDashboard);
                  }}
                />
              }
            >
              <Worksheet
                config={worksheet}
                onChange={updatedWorksheet => {
                  const newDashboard = {
                    ...dashboard,
                    worksheets: dashboard.worksheets.map((value, i) => {
                      if (value.id === worksheet.id) {
                        return updatedWorksheet;
                      }
                      return value;
                    }),
                  };
                  updateDashboard(newDashboard);
                }}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default styled(DashboardContent)`
  flex: 1;
  height: calc(100% - 40px);
  padding: 0;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }

  .preview-selection {
    margin-left: 15px;
    top: 10px;
  }
`;
