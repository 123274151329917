import React from 'react';
import {ConnexDataGrid} from '../../../../../components/connex-data-grid/components';
import useWidgetSubscription from "../../../hooks/useWidgetSubscription";

const columns = [
  {key: 'entity', name: 'Entity'},
  {key: 'crn', name: 'CRN'},
  {key: 'vehicleId', name: 'Vehicle ID'},
  {key: 'ticketCount', name: 'Ticket Count'},
  {key: 'probeTicketCount', name: 'Probe Ticket Count'},
];

const ProbeVehicles = ({data, loading, config}) => {
  const {publish} = useWidgetSubscription();

  const handleRowClick = React.useCallback(
    row => {
      publish({action: 'select-probe-vehicle', value: [row.crn, row.vehicleId]});
    },
    [data]
  );

  return (
      <ConnexDataGrid
        showFiltersByDefault
        filterable
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        rowKeyPath={["crn","vehicleId"]}
        onRowClick={handleRowClick}
        rowClickable
        autoSizeColumns
      />
  );
};

export default ProbeVehicles;
