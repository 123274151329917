import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [totalQuantitiesByHour, setTotalQuantitiesByHour] = useState({ loading: false, data: [] });

  const getTotalQuantitiesByHour = useCallback(
    (filters) => {
      const Parameters = getParametersFromFilters(filters);
      setTotalQuantitiesByHour(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Quantities Per Hour', {Parameters}).then(rs => {
        setTotalQuantitiesByHour({ data: rs && rs.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    totalQuantitiesByHour,
    getTotalQuantitiesByHour,
  };
};

export default useEntityMetrics;
