import React, { useCallback, useEffect } from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import TotalQuantitiesByStatus from "./TotalQuantitiesByStatus";
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import styled from "styled-components";
import cn from "classnames";

const Component = ({ className }) => {
  const { config, filterValues, parentRef} = useWidgetContext();
  const {
    totalQuantitiesByStatus,
    getTotalQuantitiesByStatus,
  } = useAPI();

  const refresh = useCallback(() => {
    getTotalQuantitiesByStatus(filterValues, config?.options?.maxResults || 35);
  }, [filterValues, getTotalQuantitiesByStatus]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={totalQuantitiesByStatus.loading}>
        <TotalQuantitiesByStatus
          config={config?.options}
          data={totalQuantitiesByStatus.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
