import React, {useCallback} from 'react';
import {DynamicForm, Form, TYPE} from '../../../../../components/dynamic-form';
import styled from "styled-components";

const listValues = {
  widgetType: [
    {
      label: 'Summary of Progress', value: 'progress'
    },
    {
      label: 'Summary of Problems', value: 'problems'
    },
    {
      label: 'List of Billables', value: 'billable-list'
    },
  ]
}

const formConfig = {
  sections: [{
    label: 'Configuration',
    fields: [
      {
        id: 'widgetType',
        label: `Chart Type`,
        type: TYPE.STRING,
        ignoreIntl: true,
      },
    ]
  }]
}

const Styled = styled.div`
  width: 100%;
  height: 100%;
  
  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;


const Editor = ({ onChange, options = { displayedStatus: 'PENDING' }, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };

      newConfig[field] = value;

      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Styled>
      <DynamicForm values={options}
                   onChange={onConfigChange}
                   formConfig={formConfig}
                   listValues={listValues}>
        <Form/>
      </DynamicForm>
    </Styled>
  );
};

export default Editor;
