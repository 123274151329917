import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import propTypes from 'prop-types';
import style from './style';
import Panel from './Panel';

// const deriveDateFormat = () => {
//   const isoString = '2018-09-25';
//
//   const intlString = this.formatDate(isoString);
//   const dateParts = isoString.split('-');
//
//   return intlString
//     .replace(dateParts[2], 'DD')
//     .replace(dateParts[1], 'MM')
//     .replace(dateParts[0], 'YYYY');
// };

export const DatePicker = ({
  allowClear,
  className,
  defaultValue = undefined,
  id,
  'data-test': dataTestId,
  format,
  showTime,
  onChange,
  initialValue,
  value,
  today,
  placeholder,
}) => {
  if (!defaultValue) {
    if (today) {
      defaultValue = moment();
    } else if (initialValue) {
      defaultValue = moment(initialValue);
    }
  }

  const props = {
    showTime,
    onChange,
    defaultValue,
    format,
    value: moment(value),
    placeholder,
  };

  return (
    <AntDatePicker
      className={cx('cx-date-picker', className)}
      data-testid={dataTestId}
      id={id}
      panelRender={menu => {
        return <Panel className="cx-date-picker-panel">{menu}</Panel>;
      }}
      popupStyle={{ boxShadow: 'none' }}
      allowClear={allowClear}
      {...props}
    />
  );
};

DatePicker.propTypes = {
  allowClear: propTypes.bool,
  className: propTypes.string,
  defaultValue: propTypes.any,
  id: propTypes.string,
  'data-test': propTypes.string,
  format: propTypes.string,
  showTime: propTypes.bool,
  onChange: propTypes.func,
  initialValue: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.object]),
  today: propTypes.string,
  placeholder: propTypes.string,
};

export default styled(DatePicker)`
  ${style}
`;
