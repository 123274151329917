export const getEnvironment = () => {
  if (window.location.hostname.indexOf('-model') !== -1) return 'model';
  if (window.location.hostname.indexOf('-stage') !== -1) return 'stage';
  if (window.location.hostname.indexOf('integr8-develop') !== -1) return 'integr8-develop';
  if (window.location.hostname.indexOf('-dev') !== -1) return 'dev';
  if (window.location.hostname.indexOf('localhost') !== -1) return 'localhost';
  if (window.location.hostname.indexOf('myproxy') !== -1) return 'myproxy';
  if (window.location.hostname.indexOf('10.0.2.2') !== -1) return 'android';

  return 'prod';
};

export default getEnvironment;
