import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from '../../../filters/getParametersFromFilters';

export const useEntityMetrics = () => {
  const { entityRef } = useParams();
  const [totalQuantitiesBySupplier, setTotalQuantitiesBySupplier] = useState({ loading: false, data: [] });

  const getTotalQuantitiesBySupplier = useCallback(
    filters => {
      const Parameters = getParametersFromFilters(filters);
      setTotalQuantitiesBySupplier(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Total Quantities By Supplier', { Parameters }).then(rs => {
        setTotalQuantitiesBySupplier({ data: rs && rs.items, loading: false });
      });
    },
    [entityRef]
  );

  return {
    totalQuantitiesBySupplier,
    getTotalQuantitiesBySupplier,
  };
};

export default useEntityMetrics;
