export const isRequired = fieldConfig => {
  /*
      {
        id: 'firstName',
        label: 'form.name.first',
        type: TYPE.STRING,
        validation: {
          required: ['validation.first.name.required'],
          min: [2, 'validation.first.name.minLength'],
          max: [10, 'validation.first.name.maxLength'],
        },
      }
  */

  if (!fieldConfig.validation) return false;

  const validationKeys = Object.keys(fieldConfig.validation);

  if (validationKeys.length === 0) return false;

  return !!fieldConfig.validation.required;
};
