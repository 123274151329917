import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import ProbeEventHistoryByVehicle from "./ProbeEventHistoryByVehicle";
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import cn from "classnames";
import useWidgetSubscription from '../../../hooks/useWidgetSubscription';

const filter = { action: 'select-probe-vehicle' };

const Component = ({ className }) => {
  const { config, parentRef} = useWidgetContext();
  const {
    probeEvents,
    getProbeEvents,
  } = useAPI();

  const refresh = useCallback((data) => {
    console.log('component ', data.value);
    getProbeEvents(data.value[0], data.value[1]);
  }, [getProbeEvents]);

  useWidgetSubscription(filter, refresh);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={probeEvents.loading}>
        <ProbeEventHistoryByVehicle
          config={config}
          data={probeEvents.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  * > .filter-input-wrapper {
    > input {
      background-color: #eee !important;
    }
  }
  &&& {
    > .connex-data-grid, .default-header {
      width: 100%;

      > .filtered-data-grid {
        overflow-x: hidden;
      }
    }
  }

`;





