import React, { useCallback } from 'react';
import { Space } from 'antd';
import {DynamicForm, Form, TYPE} from "../../../../../components/dynamic-form";

const listValues = {}

const formConfig = {}

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
