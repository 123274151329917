import Editor from './Editor';
import Component from './Component';

const BigNumber = {
  Editor,
  Component,
  name: 'Tickets By Plant',
  description: 'Displays Total Tickets By Plant.'
}

export default BigNumber;