import {css} from 'styled-components';

export default css`
  display: flex;
  flex-direction: row;
  flex: 1;
  .date-select {
    max-width: 200px;
    margin-left: 10px;
  }
`
