import React, {useMemo} from 'react';
import _ from 'lodash';
import useFitText from '../../components/useFitText'
import getUomAbbreviationOrDescription from '../../../../../util/uom';
import {trimName} from "../../../../../components/charts/util";

const DeliveredQuantity = ({ data, config }) => {
  const value = JSON.stringify(data, null, 2);
  const {fontSize, ref, parentRef} = useFitText(value);

  const fieldName = 'itemId';
  const displayedType = config?.options?.selectedType || 'CONCRETE';

  let displayText = useMemo(() => {

    const reducedData = data?.reduce((acc, item) => {
      const itemName = trimName(item.itemId, 'No Product');
      const newItem = {};
      const type = item.itemType || 'Unknown';
      const predicate = {};
      _.set(predicate, fieldName, item.itemId);
      const indexOfExistingItemInResult = _.findIndex(acc, predicate);
      const itemUOM = getUomAbbreviationOrDescription(item.uom);
      let displayLabelText = `${type}: ${item.qty_sum} ${itemUOM}`;

      if (itemName.includes(displayedType)) {
        if (indexOfExistingItemInResult === -1) {
          _.set(newItem, fieldName, itemName);
          _.set(newItem, 'qty_sum', item.qty_sum);
          _.set(newItem, 'name', displayLabelText);
          _.set(newItem, 'type', type);
          _.set(newItem, 'unit', itemUOM);
          _.set(newItem, type, item.qty_sum);
          acc.push(newItem);
        } else {
          _.set(acc[indexOfExistingItemInResult], type, item.val);
        }
      }
      return acc;
    }, []);

    let text = '';
    if(reducedData) {
      for (let i = 0; i < reducedData.length; i++){
        text += reducedData[i].qty_sum + ' ' + reducedData[i].unit + ' ';
      }
    }

    return text;
  }, [config, data])

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'left',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        <div>{displayText}</div>
      </div>
    </div>
  );
};

export default DeliveredQuantity;
