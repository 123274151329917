import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Space } from 'antd';
import {Form, DynamicForm, TYPE} from '../../../../../components/dynamic-form';
import API from '../../../../../api';
import {useParams} from "react-router-dom";

// const listValues = {
//   selectedType: [{
//     label: 'AGGREGATE', value: 'AGGREGATE'
//   },{
//     label: 'CONCRETE', value: 'CONCRETE'
//   }]
// }

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [{
      id: 'queryRef',
      label: `Select Query`,
      type: TYPE.STRING,
      ignoreIntl: true,
    },]
  }]
}

const Editor = ({ onChange, options = {queryRef: ''}, widget }) => {
  const { entityRef } = useParams();
  const [queries, setQueries] = useState([])

  useEffect(() => {
    API.listQueries(entityRef, ['Support']).then(results => {
      const filteredResults = (results?.items || []).filter(result => result.Tags.indexOf('Support') >= 0);
      setQueries(filteredResults);
    })
  }, [entityRef])

  const listValues = useMemo(() => {
    return {
      queryRef: queries.map(q => ({label: q.Name, value: q.UID})),
    }
  }, [queries]);

  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>

    </Space>
  );
};

export default Editor;
