import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import { applyFilters } from "../../utils";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [probeVehicles, setProbeVehicles] = useState({ loading: false, data: [] });

  const getProbeVehicles = useCallback(
    (filters, limit) => {
        const newBody = applyFilters(filters, limit);
        setProbeVehicles(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Probe Vehicles', newBody).then(rs => {
          setProbeVehicles({ data: rs && rs.items, loading: false });
      });
    },
    [appId, applyFilters, entityRef]
  );

  return {
      probeVehicles,
      getProbeVehicles,
  };
};

export default useEntityMetrics;
