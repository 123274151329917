import React, {useCallback} from 'react';
import {DynamicForm, Form, TYPE} from '../../../../../components/dynamic-form';
import styled from "styled-components";

const formConfig = {
  sections: []
}

const Styled = styled.div`
  width: 100%;
  height: 100%;
  
  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;


const Editor = ({ onChange, options = { displayedStatus: 'PENDING' }, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };

      newConfig[field] = value;

      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Styled>
      <DynamicForm values={options}
                   onChange={onConfigChange}
                   formConfig={formConfig}>
        <Form/>
      </DynamicForm>
    </Styled>
  );
};

export default Editor;
