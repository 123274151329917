import React, { useEffect, useState } from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import API from '../unbilled-delivery-summary/api';
import { useParams } from 'react-router-dom';
import getStartDate from '../unbilled-delivery-summary/api/get-start-date';
import styled from 'styled-components';
import useWidgetSubscription from '../../../hooks/useWidgetSubscription';
import BillableRenderer from './BillableRenderer';

const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const columns = [
  // {
  //   key: 'isBillable',
  //   name: 'Billable',
  //   width: 80,
  //   filterFn: (item, filterStrings) => {
  //     if (!filterStrings[0]) return true;
  //     return item ? filterStrings[0] === 'true' : filterStrings[0] === 'false';
  //   },
  //   filterComponent: ({ innerRef, ...props }) => {
  //     return (
  //       <select ref={innerRef} {...props}>
  //         <option value="" />
  //         <option value="true">True</option>
  //         <option value="false">False</option>
  //       </select>
  //     );
  //   },
  //   formatter: props => <BillableRenderer {...props} />,
  // },
  { key: 'locationId', name: 'Location' },
  { key: 'customerId', name: 'Customer ID' },
  { key: 'projectId', name: 'Project ID' },
  { key: 'orderId', name: 'Order ID' },
  { key: 'ticketId', name: 'Ticket ID' },
  { key: 'dispatchDate', name: 'Dispatch Date' },
];

const BillableList = ({ filterValues, config }) => {
  const [billables, setBillables] = useState(null);
  const [loading, setLoading] = useState(false);
  const { publish } = useWidgetSubscription();

  const { entityRef } = useParams();

  useEffect(() => {
    if (entityRef && filterValues) {
      setLoading(true);

      const params = { startDate: getStartDate(filterValues), endDate: filterValues?.endDateTime };

      API.searchBillables(entityRef, params)
        .then(rs => {
          setBillables(rs.billables?.filter(b => !b.isBillable));
        })
        .catch(e => alert(e.message))
        .finally(() => setLoading(false));
    }
  }, [entityRef, filterValues]);

  useEffect(() => {
    if (Array.isArray(billables)) {
      console.log(billables?.[0] ?? 'No Items');
    }
  }, [billables]);

  const handleRowClick = React.useCallback(
    row => {
      publish({ action: 'select-billable', value: row.crn });
    },
    [billables]
  );

  return (
    <Styled>
      <ConnexDataGrid
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(billables) ? billables : []}
        rowKeyPath="crn"
        onRowClick={handleRowClick}
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default BillableList;
