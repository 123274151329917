import React from "react";
import style from './style';
import styled from 'styled-components';
import { Steps } from "antd";

const { Step } = Steps;

const Styled = styled.div`${style}`;

const BillableProgress = ({ summary, direction = 'horizontal' }) => {

  return (
    <Styled>
      <Steps size='small' direction={direction}>
        {summary?.progress?.map((step, index) => (
          <Step key={`step-${index}`}
                status={step.status}
                title={step.title}
                description={step.description}
                icon={<i className={step.icon} />} />
        ))}
      </Steps>
    </Styled>
  )
}

export default BillableProgress;
