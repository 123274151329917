import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Average Load Time',
  description: 'Displays average time between Loading Started and Loading Complete.'
}

export default DisplayOptions;