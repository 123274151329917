import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import API from '../../../api';
import useDrawer from "../../../components/drawer/useDrawer";
import { useParams } from 'react-router-dom';
import nonce from "../../../util/nonce";
import {notification} from 'antd';

const {
  getDashboard,
  saveDashboard: saveDashboardAPI
} = API;

const findWidgetLocation = (dashboard, widgetId) => {
  for(const worksheet of dashboard.worksheets) {
    for(const widget of worksheet.widgets) {
      if(widget.id === widgetId) {
        return {worksheetId: worksheet.id, widgetId,};
      }
    }
  }
}

const WorkspacesContext = createContext();
const useWorkspaces = ({ }) => {
  const {entityRef, dashboardRef} = useParams();
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const {Drawer, setContent, setVisible} = useDrawer();
  const [dashboardStateDirty, setDashboardStateDirty] = useState(false);

  const actions = useMemo(() => {
    return [];
  }, [])

  const isPlatformSupportEntity = useMemo(() => {
    return selectedDashboard.isPlatformSupportEntity || false;
  }, [selectedDashboard?.isPlatformSupportEntity])

  const updateSelectedDashboard = useCallback(async (dashboard, canSave = true) => {
    setSelectedDashboard(dashboard)
    if(dashboard?.shared) {
      canSave = false;
    }
    if(canSave === true) {
      setDashboardStateDirty(true);
    }
  }, [setSelectedDashboard, entityRef]);

  const saveDashboard = useCallback(async () => {
    if(!selectedDashboard.shared) {
      await saveDashboardAPI(entityRef, selectedDashboard.crn, selectedDashboard);
    }
    setDashboardStateDirty(false);
  }, [selectedDashboard, entityRef]);

  useEffect(() => {
    const run = async () => {
      let existingDashboard = await getDashboard(entityRef, dashboardRef);

      updateSelectedDashboard(existingDashboard, false).then();
    }
    if(entityRef && dashboardRef) {
      run().then()
    }
  }, [entityRef, dashboardRef])

  const saveWidget = useCallback((widget) => {

    const {worksheetId} = findWidgetLocation(selectedDashboard, widget.id);
    updateSelectedDashboard({
      ...selectedDashboard,
      worksheets: selectedDashboard.worksheets.map(worksheet => {
        if(worksheet.id === worksheetId) {
          return {
            ...worksheet,
            widgets: worksheet.widgets.map(existingWidget => {
              if(existingWidget.id === widget.id) {
                return widget;
              }
              return existingWidget;
            })
          }
        }
        return worksheet;
      })
    }).then();
  }, [updateSelectedDashboard, selectedDashboard]);

  const removeWidgetFromDashboard = useCallback((widget) => {
    const {worksheetId} = findWidgetLocation(selectedDashboard, widget.id);
    updateSelectedDashboard({
      ...selectedDashboard,
      worksheets: selectedDashboard.worksheets.map(worksheet => {
        if(worksheet.id === worksheetId) {
          return {
            ...worksheet,
            widgets: worksheet.widgets.filter(existingWidget => {
              return existingWidget.id !== widget.id;
            })
          }
        }
        return worksheet;
      })
    }).then();
  }, [updateSelectedDashboard, selectedDashboard]);

  const addWidgetToCurrentWorksheet = useCallback((newWidget) => {
    const widgetResult = {
      ...newWidget,
      id: nonce(),
      layout: {
        width: 2,
        height: 2,
        x: 0,
        y: 0
      }
    };

    const result = {
      ...selectedDashboard,
      worksheets: selectedDashboard.worksheets.map(w => {
        if(w.id === selectedDashboard.selectedWorksheet) {
          const newWidgets = [
            ...w.widgets,
            widgetResult
          ];
          return {
            ...w,
            widgets: newWidgets
          }
        }
        return w;
      })
    };
    updateSelectedDashboard(result).then();
    return widgetResult;
  }, [selectedDashboard, updateSelectedDashboard])

  return {
    isPlatformSupportEntity,
    selectedDashboard,
    updateSelectedDashboard,
    addWidgetToCurrentWorksheet,
    actions,
    Drawer,
    saveWidget,
    saveDashboard,
    dashboardStateDirty,
    setDrawerContent: setContent,
    setDrawerVisible: setVisible,
    removeWidgetFromDashboard,
  };
};

export const WorkspacesContextProvider = ({ children, config, frozen }) => {
  const contextProps = useWorkspaces({ config, frozen });
  const Drawer = contextProps.Drawer;
  return (
    <WorkspacesContext.Provider value={{ ...contextProps }}>
      {children}
      <Drawer width={500} onClose={() => {
        contextProps.setDrawerVisible(false);
      }} />
    </WorkspacesContext.Provider>
  );
};

export const useWorkspacesContext = () => {
  const value = useContext(WorkspacesContext);
  if (!value) {
    throw new Error('WorkspacesContext must be used beneath WorkspacesContextProvider');
  }
  return value;
};
