import React, {useEffect, useState} from 'react';
import {ResponsiveContainer} from 'recharts';
import {useParams} from "react-router-dom";
import styled from 'styled-components';
import API from './api';
import Spinner from "../../../../../components/spinner/Spinner";
import BillableProgress from "./billable-progress";
import LineItems from "./line-items";
import {Empty} from "antd";

const Styled = styled.div`
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 300px;
  padding: 5px;
  
  .empty {
    margin-top: 50px;
  }
`;

const BillableItem = ({billableRef}) => {
  const [loading, setLoading] = useState(false);
  const [billable, setBillable] = useState(null);
  const [summary, setSummary] = useState(null);
  const [products, setProducts] = useState(null);

  const { entityRef } = useParams();

  useEffect(() => {
    if(entityRef && billableRef) {
      setLoading(true);

      API.getBillable(entityRef, billableRef)
        .then(({billable: b, summary, products}) => {
          setBillable(b);
          setSummary(summary);
          setProducts(products);
        })
        .catch(e => alert(e.message ?? 'Error Contacting Server'))
        .finally(() => setLoading(false))
    }
  }, [entityRef, billableRef])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Styled>
        {!billable && !loading && <Empty className='empty' />}
        <Spinner spin={loading}>
          <BillableProgress summary={summary} />
          <LineItems billable={billable} products={products} />
        </Spinner>
      </Styled>
    </ResponsiveContainer>
  );
}

export default BillableItem;
