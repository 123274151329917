import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [totalCountByStatus, setTotalCountByStatus] = useState({ loading: false, data: [] });

  const getTotalCountByStatus = useCallback(
    filterValues => {
      const Parameters = getParametersFromFilters(filterValues);
      setTotalCountByStatus(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Ticket Count By Status', {Parameters}).then(rs => {
        setTotalCountByStatus({ data: rs && rs?.items?.[0], loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    totalCountByStatus,
    getTotalCountByStatus,
  };
};

export default useEntityMetrics;
