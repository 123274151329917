import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import TicketsByPlant from './TicketsByPlant';
import useAPI from './useAPI';
import Spinner from '../../../../../components/spinner/Spinner';
import cn from 'classnames';

const Component = ({ className }) => {
  const { config, filterValues, parentRef } = useWidgetContext();
  const { ticketsByPlant, getTicketsByPlant } = useAPI();

  const refresh = useCallback(() => {
    getTicketsByPlant(filterValues);
  }, [filterValues, getTicketsByPlant]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={ticketsByPlant.loading}>
        <TicketsByPlant config={config?.options} data={ticketsByPlant.data} limit={config?.options?.maxResults} />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
