import StyledDrawer from './index';
import {useCallback, useEffect, useState} from "react";

const useDrawer = () => {
  const [content, setContent] = useState();
  const [visible, setVisible] = useState();

  useEffect(() => {
    let timeoutId = null;
    let nulledContent = false;

    if(!visible) {
      timeoutId = setTimeout(() => {
        setContent(null);
        nulledContent = true;
      }, 1000);
    }
    return () => {
      if(!nulledContent && timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [visible])

  const Drawer = useCallback((props) => {
    return <StyledDrawer {...props} visible={visible}>
      {content}
    </StyledDrawer>
  }, [content, visible])

  return {
    Drawer,
    setContent,
    setVisible,
  }
}

export default useDrawer;