import {useWidgetContext} from './useWidgetContext';
import cn from 'classnames';
import WidgetActions from './WidgetActions';
import styled from 'styled-components';
import React from 'react';
import {useWorkspacesContext} from "../context/useWorkspacesContext";
import {useDashboardContext} from "../dashboard/useDashboardContext";

const WidgetHeader = ({className, title}) => {
  const {showEditor, config, extraActions} = useWidgetContext();
  const {removeWidgetFromDashboard} = useWorkspacesContext();
  const {frozen} = useDashboardContext();
  const actions = [
    {
      Label: 'Remove Widget',
      handler: () => {
        removeWidgetFromDashboard(config);
      },
    },
  ];

  if (extraActions.length > 0) {
    actions.push({type: 'divider'});
    actions.push(...extraActions);
  }

  // if(config.crn === 'iframe') {
  //   actions.unshift({
  //     Label: 'Publish as Widget',
  //     handler: () => {
  //
  //     }
  //   })
  // }

  actions.unshift({
    Label: 'Edit',
    handler: () => showEditor(true),
  });

  return (
    <div className={cn(className)}>
      <div className={'widget-header'}>
        {title}
      </div>
      {!frozen && <WidgetActions actions={actions} config={config}/>}
    </div>
  );
};

export default styled(WidgetHeader)`
  &&& {
    height: 30px;
    max-height: 30px;
    width: 100%;
  }

  padding: 5px;
  font-size: 1em;

  background: #212122;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;

  .widget-header {
    width: 100%;
  }
`;
