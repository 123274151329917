import {DynamicForm, Form, TYPE} from "../../../../../../components/dynamic-form";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {Space} from "antd";
import Button from "../../../../../../components/button/Button";
import api from "../../../../../../api";
import {useParams} from "react-router-dom";
import {useWidgetContext} from "../../../useWidgetContext";
import {useDashboardContext} from "../../../../dashboard/useDashboardContext";
import {useWorkspacesContext} from "../../../../context/useWorkspacesContext";

const Styled = styled.div`
  margin-top: 40px;
`

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [
      {
        id: 'publishToAllEntities',
        label: `Publish To All`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'entityRefs',
        label: `Entities`,
        type: TYPE.ARRAY,
        ignoreIntl: true,
        disabled: (value) => {
          return value.publishToAllEntities === true;
        }
      },
      {
        id: 'name',
        label: `Name`,
        type: TYPE.STRING,
        ignoreIntl: true,
      },
      {
        id: 'description',
        label: `Description`,
        type: TYPE.STRING,
        ignoreIntl: true,
      },
    ]
  }]
};

const PublishWidget = ({setDrawerVisible, config}) => {
  const {saveWidget} = useWorkspacesContext();
  const [formValue, setFormValue] = useState(config.publish || {});
  const {entityRef} = useParams();
  const [entities, setEntities] = useState([])

  useEffect(() => {
    const run = async () => {
      api.listEntities(entityRef).then(result => {
        setEntities(result.items.map(i => ({label: i.name, value: i.crn})));
      })
    }

    if(entityRef) {
      run().then();
    }
  }, [entityRef])

  const onConfigChange = useCallback((id, value) => {
    setFormValue(existing => ({...existing, [id]: value}));
  }, []);

  const listValues = useMemo(() => {
    return {
      entityRefs: entities,
    }
  }, [entities])

  return (
    <Styled>
      <DynamicForm values={formValue} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
      <Space>
        <Button onClick={() => setDrawerVisible(false)}>Cancel</Button>
        <Button type="primary" onClick={() => {
          api.publishWidget(entityRef, {...config, crn: config.publishedWidgetRef, publish: formValue}).then(result => {
            const updatedConfig = {...config, publishedWidgetRef: result.crn};
            saveWidget(updatedConfig);
            setDrawerVisible(false)
          })

        }}>
          Publish
        </Button>
      </Space>
    </Styled>
  )
}

export default PublishWidget;