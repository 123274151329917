import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const FlexColumn = ({ className, children, style }) => {
  return (
    <div className={cn('FlexColumn', className)} style={style}>
      {children}
    </div>
  );
};

export default styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
`;
