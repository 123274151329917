import styled from 'styled-components';
import style from './style';
import {useParams} from "react-router";
import API from '../../../../api';
import {TYPE, DynamicForm, Form, SubmitButton, CancelButton} from "../../../../components/dynamic-form";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useWorkspacesContext} from "../../context/useWorkspacesContext";
import {Space} from "antd";
import Button from '../../../../components/button/Button';
import React from 'react';

const {
  shareDashboard,
  listRoles,
  listDashboardShares,
  unshareDashboard,
} = API;

const Styled = styled.div`${style}`


const ShareDashboard = ({dashboard}) => {
  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();
  const {entityRef} = useParams();
  const [listValues, setListValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const [sharedToValues, setSharedToValues] = useState({});

  const refresh = useCallback(async () => {
    const result = await listRoles(entityRef);
    setListValues({
      roleRef: result.items.map(r => ({label: r.name, value: r.crn})),
    })

    const shares = await listDashboardShares(entityRef, dashboard.crn);
    let filteredShares = shares?.items.filter(obj => Object.keys(obj).includes("sharedTo"));

    setSharedToValues({
      shared: filteredShares.map(obj => {
        const r = result?.items
        if (obj.sharedTo === 'role' && r) {
          for (let i = 0; i < r.length; i++) {
            if (r[i].crn === obj.roleRef) {
              return {...obj, typeOfShare: obj.sharedTo, roleRef: obj.roleRef, label: r[i].name};
            }
          }
        } else if (obj.sharedTo === 'entity') {
          return {...obj, typeOfShare: obj.sharedTo, label: 'Company'};
        }

        return obj;

      }),
    })

  }, [entityRef])

  useEffect(() => {
    refresh().then()
  }, [refresh])

  
  const handleClick = React.useCallback((pk) => {
    unshareDashboard(entityRef, dashboard.crn, {sharePartitionKey: pk}).then(refresh)
  }, [entityRef, dashboard, refresh]);

  const listItems = sharedToValues?.shared?.map((s) => <div key={s.pk}
    style={{flex: 1, display: 'flex', flexDirection: 'row', marginBottom: '1rem', justifyContent: 'space-between'}}>
    <div>
      {s.label}
    </div>
    <Button onClick={() => handleClick(s.pk)} metricId={'workspace-share-remove-share'}>
      Remove Share
    </Button>
  </div>);

  const formConfig = useMemo(() => {
    const fields = [{
      id: 'shareToCompany',
      label: 'Share To Company',
      type: TYPE.BOOLEAN,
      ignoreIntl: true,
    }, {
      id: 'roleRef',
      label: 'Role',
      type: TYPE.ARRAY,
      ignoreIntl: true,
      disabled: formValues?.shareToCompany || false,
    }];

    return {
      sections: [{
        fields,
      }]
    }
  }, [formValues])

  return <Styled>
    <DynamicForm onCancel={() => {
      setDrawerVisible(false);
      setDrawerContent(null);
    }} onChange={async (id, value) => {
      setFormValues(existing => ({...existing, [id]: value}))
    }} onSubmit={async value => {
      await shareDashboard(entityRef, value, dashboard);
      setDrawerVisible(false);
      setDrawerContent(null);
    }} values={formValues} formConfig={formConfig} listValues={listValues}>
      <Form/>
      <div>
        Already Shared To:
        {listItems}
      </div>
      <Space>
        <CancelButton label={'Cancel'} metricId={'workspace-share-dashboard-cancel'}/>
        <SubmitButton label={'Share'} metricId={'workspace-share-dashboard'}/>
      </Space>
    </DynamicForm>
  </Styled>
}

export default ShareDashboard;