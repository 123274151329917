import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";
const {executeQueryByName} = API;

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [ticketCount, setTicketCount] = useState({ });
  const [ticketsInProgress, setTicketsInProgress] = useState({ loading: false, data: 0 });

  const getTicketsInProgress = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setTicketsInProgress(t => ({ data: t.data, loading: true }));
      executeQueryByName(entityRef, 'Tickets In Progress List', {Parameters}).then(result => {
        setTicketsInProgress({ data: result && result?.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  const getTicketCount = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setTicketCount(t => ({ data: t.data, loading: true }));
      executeQueryByName(entityRef, 'Tickets In Progress Count', {Parameters}).then(result => {
        setTicketCount({ data: result && result?.items[0]['?COUNT'], loading: false });
      });
    },
    [appId, entityRef]
  );


  return {
    ticketsInProgress,
    ticketCount,
    getTicketsInProgress,
    getTicketCount,
  };
};

export default useEntityMetrics;