import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Probe Event History By Vehicle',
  description: 'Select the Entity and Vehicle from the Probe Vehicles widget.'
}

export default DisplayOptions;
