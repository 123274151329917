import React, { useState } from 'react';
import cx from 'classnames';
import Select from '../../select';
import { useDynamicFormContext } from '../context/DynamicFormContext';

const DynamicSelect = ({ config, listValues, onBlur, ...otherProps }) => {
  const [touched, setTouched] = useState(false);

  const { setFieldTouched, errors } = useDynamicFormContext();

  const handleBlur = () => {
    // Ant Design's Select doesn't pass down the name prop which
    // breaks formik validation.
    // https://github.com/ant-design/ant-design/issues/12612

    // capture the onBlur event so we can manually set touched.

    setTouched(true);
    setFieldTouched(config.id, true);
  };

  return (
    <div className="cx-dynamic-field">
      <Select
        name={config.id}
        className={cx('cx-dynamic-select', { 'has-error': touched && errors[config.id] })}
        options={listValues}
        valuePath="value"
        displayPath="label"
        error={errors[config.id]}
        touched={touched}
        onBlur={handleBlur}
        allowClear={config.allowClear}
        disabled={config.disabled}
        mode={config.mode}
        {...otherProps}
      />
    </div>
  );
};

export default DynamicSelect;
