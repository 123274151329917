import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import TicketsMissingAttachments from "./TicketsMissingAttachments";
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import cn from "classnames";

const Component = ({ className }) => {
  const { config, filterValues, parentRef} = useWidgetContext();
  const {
    ticketMissingAttachment,
    getTicketMissingAttachment,
  } = useAPI();

  const refresh = useCallback(() => {
    getTicketMissingAttachment(filterValues, 1000);
  }, [filterValues, getTicketMissingAttachment]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={ticketMissingAttachment.loading}>
        <TicketsMissingAttachments
          config={config}
          data={ticketMissingAttachment.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

