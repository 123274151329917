import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useWorkspacesContext } from '../context/useWorkspacesContext';
import WidgetList from '../configuration/widget-list';
import ShareDashboard from './share-dashboard';
import nonce from '../../../util/nonce';
import { get, pick } from 'lodash';
import { getDateRange } from '../filters/filter-bar/date-range-picker/rangeOptions';
import DuplicateDashboard from './duplicate-dashboard';
import API from '../../../api';
import RenameDashboard from './rename-dashboard';
import DeleteDashboard from './delete-dashboard';
import ManageFilters from './manage-filters';
import RemoveDashboard from './remove-dashboad';

const { refreshDashboards } = API;

const defaultFrozen = JSON.parse(localStorage.getItem('DASHBOARD_FROZEN') || 'false');

let savedFilterValues = JSON.parse(localStorage.getItem('SAVED_FILTER_VALUES') || '{}');

if (savedFilterValues.quickDate !== 'Custom Range') {
  const { startDate, endDate } = getDateRange(savedFilterValues.quickDate || 'Today');
  savedFilterValues.startDateTime = startDate;
  savedFilterValues.endDateTime = endDate;
}

const DashboardContext = createContext();
const useDashboard = ({ dashboard }) => {
  const {
    actions: workspacesActions,
    updateSelectedDashboard,
    setDrawerContent,
    setDrawerVisible,
    saveDashboard,
    selectedDashboard,
  } = useWorkspacesContext();

  const filterValuesRef = useRef(savedFilterValues);
  const [filterValues, setFilterValues] = useState(savedFilterValues);
  const [frozen, setFrozen] = useState(defaultFrozen);
  const widgetSubscriptionRef = useRef({});

  useEffect(() => {
    localStorage.setItem('DASHBOARD_FROZEN', JSON.stringify(frozen));
  }, [frozen]);

  useEffect(() => {
    localStorage.setItem(
      'SAVED_FILTER_VALUES',
      JSON.stringify(
        pick(
          filterValues,
          'startDateTime',
          'endDateTime',
          'quickDate',
          'locationRef',
          'customerRef',
          'supplierRef',
          'itemType',
          'projectRef',
          'ticketType',
          'vehicleRef'
        )
      )
    );
  }, [filterValues]);

  useEffect(() => {
    if (selectedDashboard.shared === true) {
      setFrozen(true);
    }
  }, [selectedDashboard]);

  const refreshAllWidgets = useCallback(() => {
    filterValuesRef.current._id = nonce();

    if (filterValuesRef.current.quickDate !== 'Custom Range') {
      const range = getDateRange(filterValuesRef.current.quickDate);

      filterValuesRef.current.startDateTime = range.startDate;
      filterValuesRef.current.endDateTime = range.endDate;
    }

    setFilterValues({ ...filterValuesRef.current });
  }, [setFilterValues]);

  const actions = useMemo(() => {
    let actionsList = [
      {
        id: 'show-share-dashboard',
        handler: () => {
          setDrawerContent(<ShareDashboard dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Sharing',
        shared: false,
        defaultDashboard: false,
      },
      {
        id: 'rename-dashboard',
        handler: () => {
          setDrawerContent(<RenameDashboard dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Rename Dashboard',
        shared: false,
      },
      {
        id: 'show-duplicate-dashboard',
        handler: () => {
          setDrawerContent(<DuplicateDashboard dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Duplicate Dashboard',
        shared: false,
        defaultDashboard: false,
      },
      {
        id: 'refresh-dashboards',
        handler: () => {
          refreshDashboards(dashboard);
        },
        label: 'Refresh Dashboards',
      },
      {
        id: 'delete-dashboard',
        handler: () => {
          setDrawerContent(<DeleteDashboard dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Delete Dashboard',
        shared: false,
        defaultDashboard: false,
      },
      {
        id: 'manage-filters',
        handler: () => {
          setDrawerContent(<ManageFilters dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Dashboard Settings',
        shared: false,
      },
      {
        id: 'freeze-dashboard',
        handler: () => {
          saveDashboard();
          setFrozen(existing => !existing);
        },
        label: frozen ? 'Un-freeze' : 'Freeze',
        icon: frozen ? 'tint' : 'snowflake',
        shared: false,
      },
      {
        id: 'remove-dashboard',
        handler: () => {
          setDrawerContent(<RemoveDashboard dashboard={dashboard} />);
          setDrawerVisible(true);
        },
        label: 'Remove Dashboard',
        shared: true,
        defaultDashboard: false,
      },
    ];

    if (!frozen) {
      actionsList.unshift({
        id: 'show-widget-list',
        handler: () => {
          setDrawerContent(<WidgetList />);
          setDrawerVisible(true);
        },
        label: 'Add Widgets',
      });
    }

    if (dashboard?.shared) {
      actionsList = actionsList.filter(a => a.shared !== false);
    } else {
      actionsList = actionsList.filter(a => a.shared !== true);
    }

    if (dashboard?.defaultDashboard) {
      actionsList = actionsList.filter(a => a.defaultDashboard !== false);
    }

    return actionsList;
  }, [dashboard, frozen, saveDashboard]);

  const filterValueChanged = useCallback(
    (id, value) => {
      if (id === 'endDateTime') {
        value = moment(value).endOf('day').toISOString();
      } else if (id === 'startDateTime') {
        value = moment(value).startOf('day').toISOString();
      }

      filterValuesRef.current[id] = value;
      filterValuesRef.current._id = nonce();

      if (filterValuesRef.current.itemType === '') {
        delete filterValuesRef.current.itemType;
      }

      if (filterValuesRef.current.ticketType === '') {
        delete filterValuesRef.current.ticketType;
      }

      setFilterValues({ ...filterValuesRef.current });
    },
    [setFilterValues, dashboard]
  );

  const updateDashboard = useCallback(
    (dashboard, canSave = true) => {
      updateSelectedDashboard(dashboard, canSave);
    },
    [updateSelectedDashboard]
  );

  const subscribe = useCallback((filter, callback) => {
    const id = nonce();
    widgetSubscriptionRef.current[id] = { filter, callback };
    console.log(`SUBSCRIBE::${id}`);
    return id;
  }, []);

  const unsubscribe = useCallback(id => {
    if (widgetSubscriptionRef.current[id]) {
      delete widgetSubscriptionRef.current[id];
      console.log(`UN-SUBSCRIBE::${id}`);
    }
  }, []);

  const publish = useCallback(data => {
    const objectFilterMatches = filter => {
      for (const filterKey of Object.keys(filter)) {
        if (get(data, filterKey) !== filter[filterKey]) {
          return false;
        }
      }
      return true;
    };

    const subscriptionIds = Object.keys(widgetSubscriptionRef.current);
    for (const subscriptionId of subscriptionIds) {
      const { filter, callback } = widgetSubscriptionRef.current[subscriptionId];
      let filterMatches = false;
      if (typeof filter === 'function') {
        filterMatches = filter(data);
      } else if (typeof filter === 'object') {
        filterMatches = objectFilterMatches(filter);
      }
      if (filterMatches) {
        callback({ ...data });
      }
    }
  }, []);

  return {
    dashboard,
    frozen,
    updateDashboard,
    filterValueChanged,
    filterValues,
    subscribe,
    unsubscribe,
    publish,
    refreshAllWidgets,
    actions: [...actions, ...workspacesActions],
  };
};

export const DashboardContextProvider = ({ children, dashboard, frozen }) => {
  const contextProps = useDashboard({ dashboard, frozen });
  return <DashboardContext.Provider value={{ ...contextProps }}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  const value = useContext(DashboardContext);
  if (!value) {
    throw new Error('Dashboard Context must be used beneath DashboardContextProvider');
  }
  return value;
};
