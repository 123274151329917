import {DynamicForm, Form, TYPE} from "../../../../../../components/dynamic-form";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {Space} from "antd";
import Button from "../../../../../../components/button/Button";
import api from "../../../../../../api";
import {useParams} from "react-router-dom";
import {find} from "lodash";

const Styled = styled.div`
  margin-top: 40px;
`

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [
      {
        id: 'publishedWidget',
        label: `Published Widgets`,
        type: TYPE.STRING,
        ignoreIntl: true,
      },
    ]
  }]
};

const OpenPublishedWidget = ({setDrawerVisible, onOpenPublishedWidget, config}) => {
  const [formValue, setFormValue] = useState({});
  const {entityRef} = useParams();
  const [publishedWidgets, setPublishedWidgets] = useState([])
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    const run = async () => {
      api.listPublishedWidgets(entityRef).then(result => {
        setWidgets(result.items);
        setPublishedWidgets(result.items.map(i => ({label: i?.publish?.name, value: i.crn})));
      })
    }

    if(entityRef) {
      run().then();
    }
  }, [entityRef])

  const onConfigChange = useCallback((id, value) => {
    setFormValue(existing => ({...existing, [id]: value}));
  }, []);

  const listValues = useMemo(() => {
    return {
      publishedWidget: publishedWidgets,
    }
  }, [publishedWidgets])


  return (
    <Styled>
      <DynamicForm values={formValue} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
      <Space>
        <Button onClick={() => setDrawerVisible(false)}>Cancel</Button>
        <Button type="primary" onClick={() => {
          const selectedWidget = find(widgets, w => w.crn === formValue.publishedWidget);
          if(selectedWidget) {
            onOpenPublishedWidget({...selectedWidget, publishedWidgetRef: selectedWidget.crn, typeId: undefined, crn: 'custom-widget', name: selectedWidget.publish.name, id: config.id,});
          }

          setDrawerVisible(false);
        }}>
          Open
        </Button>
      </Space>
    </Styled>
  )
}

export default OpenPublishedWidget;