import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDynamicFormContext } from './DynamicFormContext';
import { FormFactory } from '../FormFactory';

export const Form = ({ className, ignoreInitialValues = false, formConfig: formConfigOverride, listValues: listValuesOverride }) => {
  const {
    initialValues,
    initialFocus,
    formConfig,
    listValues,
    setValues,
  } = useDynamicFormContext();

  useEffect(() => {
    if (initialFocus) {
      setTimeout(() => {
        initialFocus.ref.current.focus();
      });
    }
  }, [initialValues, initialFocus]);

  useEffect(() => {
    if(ignoreInitialValues) {
      return;
    }
    setValues(initialValues);
  }, [initialValues, setValues]);

  return (
    <div className={cx(className, 'cx-dynamic-form')}>
      <FormFactory formConfig={formConfigOverride || formConfig} listValues={listValuesOverride || listValues} />
    </div>
  );
};
