import {css} from 'styled-components';

export default css`
width: 100%;

table, tbody, tr {
  width: 100%;
}

td {
  padding: 3px;
}

.ant-steps-item-description {
  font-size: 12px;
}
`;
