import React, { useCallback } from 'react';
import cx from 'classnames';
import { DynamicForm, TYPE, TYPE_HELPER } from './index';
import * as Dynamic from './fields';
import Label from '../label/Label';
import {Spin, Tooltip} from 'antd';
import { useDynamicFormContext } from './context/DynamicFormContext';
import { isRequired } from './util';
import {get} from 'lodash'
import getEnvironment from "../../util/getEnvironment";

const getDefaultValue = type => {
  switch (type) {
    case TYPE.ARRAY:
      return [];
    default:
      return '';
  }
};

const isLocalhost = getEnvironment() === 'localhost';

const testFieldDependency = (config, values) => {
  // Return true if field should be hidden;
  const dependencyValue = values[config.fieldId];
  return config.specificValue ? config.specificValue !== dependencyValue : !dependencyValue;
};

const isFieldHidden = (fieldConfig, values) => {
  if (fieldConfig?.dependencies) {
    if (!Array.isArray(fieldConfig.dependencies)) {
      return testFieldDependency(fieldConfig.dependencies, values);
    } else if (fieldConfig.dependencies.length) {
      return !!fieldConfig.dependencies.find(d => testFieldDependency(d, values));
    }
  }
  return false;
};

const FormGroup = ({ fieldConfig, children }) => {
  const { errors, touched, values, namespace, busyFields } = useDynamicFormContext();

  const required = React.useMemo(() => {
    return isRequired(fieldConfig);
  }, []);

  const value = get(values || {}, fieldConfig.id) || getDefaultValue(fieldConfig.type);
  const isBusy = busyFields[fieldConfig.id] === true;
  const isHidden = isFieldHidden(fieldConfig, values);

  return (
    <div
      className={cx('form-group', {
        required,
        'has-error': errors[fieldConfig.id] && touched[fieldConfig.id],
        valid: !errors[fieldConfig.id] && values[fieldConfig.id],
        hidden: isHidden
      })}
    >
      <Spin spinning={isBusy}>
        <div className="form-label">
          {fieldConfig.label && <Label label={fieldConfig.label} ignoreIntl={fieldConfig.ignoreIntl} text={{ id: `${namespace}.${fieldConfig.label}` }} required={required} />}
          {isLocalhost && value && <Tooltip title={value}>
            <span className={'fa fa-info-circle'} style={{float: 'right'}} />
          </Tooltip>}
        </div>
        <div className="form-control">{children}</div>
      </Spin>
    </div>
  );
};

const coerceValue = ({ type, value }) => {
  if (type === TYPE.STRING || type === TYPE.ARRAY) {
    return value;
  }

  if (type === TYPE.DATE) {
    // expect moment() instance.
    return value ? value.toISOString() : null;
  }

  const coercedValue = TYPE_HELPER[type].class(value);

  return coercedValue;
};

export const DynamicFieldFactory = ({ fieldConfig, listValues }) => {
  const { setFieldValue, initialFocus, handleBlur, values, publish } = useDynamicFormContext();

  const value = get(values || {}, fieldConfig.id) || getDefaultValue(fieldConfig.type);

  const handleChange = useCallback(
    (value, ...extraArgs) => {
      setFieldValue(fieldConfig.id, coerceValue({ type: fieldConfig.type, value }));
      publish(DynamicForm.EVENTS.CHANGE, {
        set: setFieldValue,
        id: fieldConfig.id,
        value: fieldConfig.type === TYPE.DATE ? extraArgs[0] : value,
      });
    },
    [fieldConfig, publish, setFieldValue]
  );

  const handleInputChange = useCallback(
    e => {
      setFieldValue(fieldConfig.id, coerceValue({ type: fieldConfig.type, value: e.target.value }));
      publish(DynamicForm.EVENTS.CHANGE, {
        set: setFieldValue,
        id: fieldConfig.id,
        value: e.target.value,
      });
    },
    [fieldConfig, publish, setFieldValue]
  );

  const handleCheckboxChange = useCallback(
    e => {
      setFieldValue(fieldConfig.id, e.target.checked);
      publish(DynamicForm.EVENTS.CHANGE, {
        set: setFieldValue,
        id: fieldConfig.id,
        value: e.target.checked,
      });
    },
    [fieldConfig, setFieldValue, publish]
  );

  const onFocus = useCallback(() => {
    publish(`${DynamicForm.EVENTS.FOCUS}.${fieldConfig.id}`, {
      set: setFieldValue,
      id: fieldConfig.id,
      value,
    });
  }, [setFieldValue, fieldConfig, value, publish]);

  if (fieldConfig.component) {
    return (
      <FormGroup fieldConfig={fieldConfig}>
        <Dynamic.Custom
          name={fieldConfig.id}
          config={fieldConfig}
          listValues={listValues}
          onChange={handleChange}
          value={value}
          onFocus={onFocus}
          onBlur={handleBlur}
          innerRef={initialFocus && fieldConfig.id === initialFocus.id ? initialFocus.ref : undefined}
        />
      </FormGroup>
    );
  }

  switch (fieldConfig.type) {
    case TYPE.STRING:
      return listValues ? (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Select
            config={fieldConfig}
            listValues={listValues}
            onChange={handleChange}
            value={value}
            onFocus={onFocus}
            onBlur={handleBlur}
            innerRef={initialFocus && fieldConfig.id === initialFocus.id ? initialFocus.ref : undefined}
          />
        </FormGroup>
      ) : (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Input
            config={fieldConfig}
            onChange={handleInputChange}
            value={value}
            onFocus={onFocus}
            onBlur={handleBlur}
            innerRef={initialFocus && fieldConfig.id === initialFocus.id ? initialFocus.ref : undefined}
          />
        </FormGroup>
      );
    case TYPE.DATE:
      return (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Date
            placeholder="Enter a Date"
            config={fieldConfig}
            onChange={handleChange}
            // initialValue={value}
            value={value}
          />
        </FormGroup>
      );
    case TYPE.BOOLEAN:
      return (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Checkbox config={fieldConfig} onChange={handleCheckboxChange} checked={value} />
        </FormGroup>
      );
    case TYPE.NUMBER:
      return (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Currency config={fieldConfig} onChange={handleChange} value={value} />
        </FormGroup>
      );
    case TYPE.ARRAY:
      return (
        <FormGroup fieldConfig={fieldConfig}>
          <Dynamic.Array config={fieldConfig} listValues={listValues} onChange={handleChange} value={value} />
        </FormGroup>
      );
    default:
      return null;
  }
};
