import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Tickets Produced',
  description: 'Displays Total Tickets Produced.'
}

export default DisplayOptions;