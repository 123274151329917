import React, {useCallback} from 'react';
import {DynamicForm, Form, TYPE} from '../../../../../components/dynamic-form';
import { Space } from 'antd';

const listValues = {
    ticketType: [
        {
            label: 'Seller - View Shipments', value: 'seller'
        },
        {
            label: 'Buyer - Accept Materials', value: 'buyer'
        },
    ]
}

const formConfig = {
    sections: [{
        label: 'Configuration',
        fields: [
            {
                id: 'ticketType',
                label: `Ticket Search Link`,
                type: TYPE.STRING,
                ignoreIntl: true,
            },
        ]
    }]
}


const Editor = ({ onChange, options = {ticketType: 'seller'}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      console.log('options ', options)
        console.log('newConfig', newConfig)
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>

    </Space>
  );
};

export default Editor;