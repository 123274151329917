import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import { applyFilters } from "../../utils";

export const useEntityMetrics = () => {
  const { appId } = useParams();
  const [features, setFeatures] = useState({ loading: false, data: [] });

  const getFeatures = useCallback(
    (entityRef) => {
      setFeatures(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Entity Feature Flags', '').then(rs => {
        setFeatures({ data: rs && rs.items, loading: false });
      });
    },
    [appId, applyFilters]
  );

  return {
    features,
    getFeatures,
  };
};

export default useEntityMetrics;
