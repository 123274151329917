import React, {useCallback, useState} from 'react';
import {useWidgetContext} from '../../useWidgetContext';
import styled from 'styled-components';
import BillableItem from "./BillableItem";
import useWidgetSubscription from "../../../hooks/useWidgetSubscription";

const filter = { action: 'select-billable' };

const Styled = styled.div`
  width: 100%;
  height: 100%;
  
  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Component = () => {
  const [billableRef, setBillableRef] = useState(null);
  const { parentRef } = useWidgetContext();

  const handleSelectBillable = useCallback(data => {
    setBillableRef(data.value);
  }, [])

  useWidgetSubscription(filter, handleSelectBillable);

  return (
    <Styled ref={parentRef}>
      <BillableItem billableRef={billableRef}/>
    </Styled>
  );
};

export default Component;
