import React, {useMemo} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import DataComponent from './DataComponent';
import cn from 'classnames';
import { WidgetContextProvider } from './useWidgetContext';
import DataContainer from './DataContainer';
import WidgetHeader from './WidgetHeader';
import {kebabCase} from "lodash";

const Widget = ({ className, config, key }) => {
  const name = useMemo(() => {
    return `${config.name}${config.publish ? ' (PUBLISHED)' : ''}`
  }, [config])
  return (
    <WidgetContextProvider config={config} key={key}>
        <div className={cn(className, 'cx-widget')}  data-testid={`widget-${kebabCase(name)}`}>
          <WidgetHeader title={name} />
          <DataContainer>
            <DataComponent />
          </DataContainer>
        </div>
    </WidgetContextProvider>
  );
};

export default styled(Widget)`
  height: 100%;
  width: 100% !important;
  border: 1px solid #444;
  border-radius: ${(props) => {
    return props?.theme?.frozen ? 0 : '3px';
  }};
  display: flex;
  flex-direction: column;
`;
