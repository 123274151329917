import React, {useCallback, useMemo, useState} from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Dropdown, Menu, Select, Space } from 'antd';
import {useParams} from "react-router-dom";
import { useDashboardContext } from './useDashboardContext';
import API from '../../../api';
import Button from "../../../components/button/Button";
import {callWithMetrics} from "../../../util/metrics/recordMetric";
import {kebabCase} from "lodash";

const {

} = API;

const RefButton = React.forwardRef((props, ref) => {
  return <div ref={ref}>
    <Button size='small' {...props} />
  </div>
});

const DashboardActions = ({ className }) => {
  const { actions } = useDashboardContext();
  const { entityRef } = useParams();

  const menu = useMemo(() => {
    return (<Menu className={className}>
      {
        actions.map(a => {
          const metric = { type: 'user-interaction', data: { type: 'button-click', id: `workspace-${a.label.replace(/\s+/g, '-').toLowerCase()}` } };
          return <Menu.Item key={a.id} onClick={callWithMetrics(a.handler, metric , entityRef)} data-testid={`action-${kebabCase(a.label)}`}>
            {a.icon && <span className={`fa fa-${a.icon} action-item-icon`} />}
            <span>
              {a.label}
            </span>
          </Menu.Item>
        })
      }
    </Menu>);
  }, [actions, className])

  return (
    <Space className={cn(className, 'dashboard-actions')}>
      <Dropdown trigger={['click']} overlay={menu}>
        <RefButton>...</RefButton>
      </Dropdown>
    </Space>
  );
};

export default styled(DashboardActions)`
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .action-item-icon {
    margin-right: .25rem;
  }
`;
