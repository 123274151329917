import React from 'react';
import presetWidgets from './preset-widgets';
import { useWidgetContext } from './useWidgetContext';

const getChart = config => {

  let widgetType = config.crn;
  if(config.typeId === 'published-widget') {
    widgetType = 'custom-widget';
  }

  const visualization = presetWidgets[widgetType]?.Component;

  if (!visualization) {
    return null;
  }

  return visualization;
};

const DataComponent = () => {
  const { config } = useWidgetContext();
  const DataChart = getChart(config);

  if (!DataChart) {
    return <div>{`Widget Type '${config.type}' not found`}</div>;
  }

  return <DataChart />;
};

export default DataComponent;
