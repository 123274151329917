import React from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';

const columns = [
  { key: 'ownerEntityID', name: 'CRN' },
  { key: 'vehicleId', name: 'Vehicle ID' },
  { key: 'ticketRef', name: 'Ticket CRN' },
  { key: 'ticketNumber', name: 'Ticket Number' },
  { key: 'primaryKey', name: 'Event Time' },
  { key: 'latitude', name: 'Latitude' },
  { key: 'longitude', name: 'Longitude' },
  { key: 'volumeReliability', name: 'Volume Reliability' },
  { key: 'volume', name: 'Volume' },
  { key: 'waterInDrum', name: 'Water In Drum' },
  { key: 'slump', name: 'Slump' },
  { key: 'temperature', name: 'Temperature' },
  { key: 'waterAdded', name: 'Water Added' },
  { key: 'returnedWater', name: 'Returned Water' },
  { key: 'drumRPM', name: 'Drum RPM' },
];

const ProbeEventHistoryByVehicle = ({ data, loading, config }) => {
  return (<ConnexDataGrid
        showFiltersByDefault
        filterable
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        autoSizeColumns
        allowExport={true}
      />
  );
};

export default ProbeEventHistoryByVehicle;
