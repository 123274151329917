import React, {useEffect, useMemo, useState} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import {sortBy, toLower} from 'lodash';
import {Input} from 'antd';

import WidgetPreview from './widget-preview';
import PresetWidgets from "../../widgets/preset-widgets";
import API from '../../../../api';

function compare( a, b ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}

const {listWidgets} = API;

const WidgetList = ({ className, value, onChange  }) => {
  const {entityRef} = useParams();
  const [filterValue, setFilterValue] = useState('');
  const [selectableWidgets, setSelectableWidgets] = useState([]);

  useEffect(() => {
    listWidgets(entityRef).then(r => {
      const widgets = [];
      for(const w of r.items) {
        if(w.typeId === 'published-widget') {
          widgets.push({...PresetWidgets['custom-widget'], publishedWidgetRef: w.crn, options: w.options, name: w.name, description: w.description, type: 'preset', crn: 'custom-widget', typeId: 'published-widget'})
        } else {
          PresetWidgets[w.id] && widgets.push({...PresetWidgets[w.id], crn: w.id, type: 'preset'});
        }
      }
      setSelectableWidgets(widgets);
    })
  }, [entityRef, setSelectableWidgets]);

  const filteredWidgets = useMemo(() => {
    if(filterValue === '') {
      return sortBy(selectableWidgets, ['name']);
    }
    const lowerFilteredValue = toLower(filterValue);
    const widgetList = selectableWidgets.filter(w => toLower(w.name).indexOf(lowerFilteredValue) >= 0 || toLower(w.description).indexOf(lowerFilteredValue) >= 0)
    return sortBy(widgetList, 'name');
  }, [selectableWidgets, filterValue])

  return <div className={cn(className)}>
    <div className={'widget-list-header'}>
      <div className={'widget-list-title'}>Select widgets to add the dashboard</div>
      <Input addonBefore={<span className={'fa fa-search'} />} onChange={e => setFilterValue(e.target.value)} className={'widget-list-search'}></Input>
    </div>
    {filteredWidgets.map(w => <WidgetPreview key={w.crn === 'custom-widget' ? w.publishedWidgetRef : w.crn} widget={w} />)}
  </div>;
};

export default styled(WidgetList)`
  width: 100%;
  font-size: 1.00rem;
  
  .widget-list-header {
    margin-bottom: 10px;
  }
`;
