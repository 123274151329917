import styled from 'styled-components';
import style from './style';
import {useParams} from "react-router";
import API from '../../../../api';
import {TYPE, DynamicForm, Form, SubmitButton, CancelButton} from "../../../../components/dynamic-form";
import {useEffect, useMemo, useState} from "react";
import {useWorkspacesContext} from "../../context/useWorkspacesContext";
import {Space} from "antd";

const {
  saveDashboard,
  refreshDashboards,
} = API;

const Styled = styled.div`${style}`

const formConfig = {
  sections: [{
    fields: [{
      id: 'hideDateFilter',
      label: 'Hide Date Filter',
      type: TYPE.BOOLEAN,
      ignoreIntl: true,
    },{
      id: 'hideExtraFilters',
      label: 'Hide Extra Filters',
      type: TYPE.BOOLEAN,
      ignoreIntl: true,
    },{
      id: 'showRefreshButton',
      label: 'Show Auto-Refresh Button',
      type: TYPE.BOOLEAN,
      ignoreIntl: true,
    }]
  }]
}

const ManageFilters = ({dashboard}) => {
  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();
  const {entityRef} = useParams();

  const values = useMemo(() => {

    return {
      hideDateFilter: dashboard.hideDateFilter,
      hideExtraFilters: dashboard.hideExtraFilters,
      showRefreshButton: dashboard.showRefreshButton,
    }

  }, [dashboard])

  return <Styled>
    <DynamicForm onCancel={() => {
      setDrawerVisible(false);
      setDrawerContent(null);
    }} onSubmit={async value => {
      const newDashboard = await saveDashboard(entityRef, dashboard.crn, {
        ...dashboard,
        hideExtraFilters: value.hideExtraFilters,
        hideDateFilter: value.hideDateFilter,
        showRefreshButton: value.showRefreshButton,
        shared: undefined});
      setDrawerVisible(false);
      setDrawerContent(null);
      refreshDashboards(newDashboard);
    }} values={values} formConfig={formConfig} listValues={{}}>
      <Form />
      <Space>
        <CancelButton label={'Cancel'} />
        <SubmitButton label={'Save'} metricId={'workspace-manage-filters'}/>
      </Space>
    </DynamicForm>
  </Styled>
}

export default ManageFilters;