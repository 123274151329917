import {css} from 'styled-components';

export default css`
  flex-direction: column;
  flex: 1;
  display: flex;

  .delete-button-group {
    button {
      margin: 5px;
    }
  }
`;