import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Ticket Count By Status (Bar Chart)',
  description: 'Displays Ticket Count By Status.'
}

export default DisplayOptions;