import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import { useDashboardContext } from './useDashboardContext';
import DashboardContent from './DashboardContent';
import cn from 'classnames';


const StyledDashboard = ({ className }) => {
  const {frozen} = useDashboardContext();
  return (
    <ThemeProvider theme={{frozen}}>
      <div className={cn(className, 'cx-dashboard')}>
        <DashboardContent />
      </div>
    </ThemeProvider>
  );
};

export default styled(StyledDashboard)`
  flex-direction: column;
  display: flex;
  height: 100%;
  padding: 0;
  &&& {
    .recharts-responsive-container {
      overflow: hidden;
    }
  }
  .dashboard-header {
    background-color: #000000;
  }
`;
