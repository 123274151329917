import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { find } from 'lodash';
import propTypes from 'prop-types';
import allZones from './zone/zones';
import style from './style';
import CountrySelect from './countries';
import ZoneSelect from './zone';

const getTimeZone = (timeZone, initial) => {
  const zone = find(allZones, z => z.name === timeZone);
  // Set initial to true so that we don't emit a change upon initial render.
  // "initial" remains true until the change handlers are invoked.
  return zone ? { initial, ...zone } : {};
};

const TimeZone = ({ className, onChange, defaultValue, value, id, disabled }) => {
  const [timeZonesForSelectedCountry, setTimeZonesForSelectedCountry] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(getTimeZone(defaultValue, true));

  useEffect(() => {
    setTimeZonesForSelectedCountry(
      allZones.filter(z => z.countryCode === selectedTimeZone.countryCode)
    );
  }, [selectedTimeZone.countryCode]);

  useEffect(() => {
    // don't call onChange while initial is true;
    !selectedTimeZone.initial && selectedTimeZone.name && onChange(selectedTimeZone.name);
  }, [selectedTimeZone.name, onChange, selectedTimeZone.initial]);

  useEffect(() => {
    !selectedTimeZone.initial && setSelectedTimeZone(getTimeZone(value));
  }, [value, selectedTimeZone.initial]);

  useEffect(() => {
    if (timeZonesForSelectedCountry.length === 1) {
      setSelectedTimeZone(s => ({ ...s, name: timeZonesForSelectedCountry[0].name }));
    }
  }, [timeZonesForSelectedCountry]);

  const handleCountryChange = useCallback(country => {
    setSelectedTimeZone({ countryCode: country, name: null });
  }, []);

  const handleTimeZoneChange = useCallback(tz => {
    setSelectedTimeZone(s => ({ countryCode: s.countryCode, name: tz }));
  }, []);

  return (
    <div className={cn('cx-time-zone', className)}>
      <CountrySelect
        disabled={disabled}
        placeholder="Select a country..."
        className={cn('cx-time-zone-country')}
        id={id}
        value={selectedTimeZone.countryCode}
        style={{ width: '100%' }}
        onChange={handleCountryChange}
        allowclear={false}
      />
      <ZoneSelect
        disabled={disabled}
        placeholder="Select a time zone..."
        className={cn('cx-time-zone-zone')}
        id={id}
        value={selectedTimeZone.name}
        style={{ width: '100%' }}
        displayPath="name"
        valuePath="name"
        options={timeZonesForSelectedCountry}
        onChange={handleTimeZoneChange}
        allowclear={false}
      />
    </div>
  );
};

TimeZone.propTypes = {
  className: propTypes.string,
  onChange: propTypes.func,
  defaultValue: propTypes.oneOfType([propTypes.object, propTypes.string]),
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  id: propTypes.string,
  disabled: propTypes.bool,
};

export default styled(TimeZone)`
  ${style}
`;
