import { useState, useEffect } from 'react';
import { flattenConfig } from './flattenConfig';
import { TYPE_HELPER } from '../index';

const getDefaultValues = formConfig => {
  const flattened = flattenConfig(formConfig);

  const instance = {};

  flattened.forEach(fieldConfig => {
    instance[fieldConfig.id] =
      fieldConfig.defaultValue || TYPE_HELPER[fieldConfig.type].defaultValue;
  });

  return instance;
};

export const useDefaultFormValues = formConfig => {
  const [defaultForm, setDefaultForm] = useState({});

  useEffect(() => {
    setDefaultForm(getDefaultValues(formConfig));
  }, [formConfig]);

  return { defaultForm };
};
