import styled from 'styled-components';
import { useDashboardContext } from '../../../dashboard/useDashboardContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TYPE, DynamicForm, Form } from '../../../../../components/dynamic-form';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import uuidByString from 'uuid-by-string';
import { find } from 'lodash';

const Styled = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  .date-select {
    max-width: 200px;
    margin-left: 10px;
  }
`;

// const options = rangeOptions.map(id => ({value: id, label: id}));
const options = [];

const row1FieldSet = {
  name: 'HIDE_ME_row1',
};

const row2FieldSet = {
  name: 'HIDE_ME_row2',
};

const formConfig = {
  sections: [
    {
      direction: 'row',
      fields: [
        {
          id: 'locationRef',
          label: 'Location',
          type: TYPE.ARRAY,
          ignoreIntl: true,
          fieldSet: row1FieldSet,
        },
        {
          id: 'customerRef',
          label: 'Customer',
          type: TYPE.ARRAY,
          ignoreIntl: true,
          fieldSet: row1FieldSet,
        },
        {
          id: 'supplierRef',
          label: 'Supplier',
          type: TYPE.ARRAY,
          ignoreIntl: true,
          fieldSet: row1FieldSet,
        },
        {
          id: 'itemType',
          label: 'Item Type',
          type: TYPE.STRING,
          ignoreIntl: true,
          fieldSet: row2FieldSet,
        },
        {
          id: 'projectRef',
          label: 'Project',
          type: TYPE.ARRAY,
          ignoreIntl: true,
          fieldSet: row2FieldSet,
        },
        {
          id: 'ticketType',
          label: 'Ticket Type',
          type: TYPE.STRING,
          ignoreIntl: true,
          fieldSet: row2FieldSet,
        },
        {
          id: 'vehicleRef',
          label: 'Vehicle',
          type: TYPE.ARRAY,
          ignoreIntl: true,
          fieldSet: row2FieldSet,
        },
      ],
    },
  ],
};

const findValues = (filterValues, listValues) => {
  if (!filterValues || !listValues) {
    return undefined;
  }
  const results = [];
  for (const filterItem of filterValues) {
    for (const listValue of listValues) {
      if (listValue.item.crn) {
        if (filterItem.ItemRef === listValue.item.crn) {
          results.push(listValue.value);
        }
      } else {
        if (listValue.item.id === filterItem.Value && listValue.item.name === filterItem.Label) {
          results.push(listValue.value);
        }
      }
    }
  }
  return results;
};

const ExtraFilters = () => {
  const { entityRef } = useParams();
  const { filterValueChanged, filterValues } = useDashboardContext();

  const [listValues, setListValues] = useState({
    itemType: [
      {
        label: 'Concrete',
        value: 'CONCRETE',
      },
      {
        label: 'Aggregate',
        value: 'AGGREGATE',
      },
      {
        label: 'Asphalt',
        value: 'ASPHALT',
      },
    ],
    ticketType: [
      {
        label: 'Inbound',
        value: 'BUYER',
      },
      {
        label: 'Outbound',
        value: 'SELLER',
      },
    ],
  });
  const [filterHash, setFilterHash] = useState({});
  const [busyFields, setBusyFields] = useState({});
  const [filterSource, setFilterSource] = useState({});

  const updatedFilterValues = useMemo(() => {
    const result = {
      itemType: filterValues.itemType,
      ticketType: filterValues.ticketType,
      locationRef:
        findValues(filterValues.locationRef, listValues.locationRef) ?? filterValues?.locationRef?.map(l => `${l.Label} / ${l.Value}`),
      customerRef:
        findValues(filterValues.customerRef, listValues.customerRef) ?? filterValues?.customerRef?.map(c => `${c.Label} / ${c.Value}`),
      projectRef:
        findValues(filterValues.projectRef, listValues.projectRef) ?? filterValues?.projectRef?.map(p => `${p.Label} / ${p.Value}`),
      supplierRef:
        findValues(filterValues.supplierRef, listValues.supplierRef) ?? filterValues?.supplierRef?.map(s => `${s.Label} / ${s.Value}`),
      vehicleRef:
        findValues(filterValues.vehicleRef, listValues.vehicleRef) ?? filterValues?.vehicleRef?.map(v => `${v.Label} / ${v.Value}`),
    };

    return result;
  }, [filterValues, listValues]);

  const updateListValues = useCallback(
    async selectedFieldId => {
      if (!selectedFieldId.endsWith('Ref')) {
        return;
      }

      if (filterSource[selectedFieldId] === 'setup') {
        return;
      }

      const filterValuesToHash = {
        ...filterValues,
        [selectedFieldId]: undefined,
      };

      const hashValue = uuidByString(JSON.stringify(filterValuesToHash));

      if (filterHash[selectedFieldId] === hashValue) {
        return;
      }

      setFilterHash(existingFilterHash => ({
        ...existingFilterHash,
        [selectedFieldId]: hashValue,
      }));

      setBusyFields(existingBusyFields => ({
        ...existingBusyFields,
        [selectedFieldId]: true,
      }));

      let { items, source } = await API.getFilterValues(entityRef, selectedFieldId, {
        filterValues,
      });

      setListValues(existingListValues => ({
        ...existingListValues,
        [selectedFieldId]: items,
      }));

      setFilterSource(existingFilterSource => ({
        ...existingFilterSource,
        [selectedFieldId]: source,
      }));

      setBusyFields(existingBusyFields => ({
        ...existingBusyFields,
        [selectedFieldId]: false,
      }));
    },
    [entityRef, filterValues, filterHash, busyFields]
  );

  return (
    <Styled>
      <DynamicForm
        values={updatedFilterValues}
        formConfig={formConfig}
        listValues={listValues}
        fieldFocused={updateListValues}
        busyFields={busyFields}
        onChange={(id, value) => {
          if (id.endsWith('Ref')) {
            const result = [];
            for (const item of value) {
              const existingItem = find(listValues[id], l => l.value === item);

              if (existingItem) {
                result.push({
                  Label: existingItem.item.name,
                  Value: existingItem.item.id,
                  ItemRef: existingItem.item.crn,
                });
              }
            }

            filterValueChanged(id, result);
          } else {
            filterValueChanged(id, value);
          }
        }}
      >
        <Form formConfig={formConfig} />
      </DynamicForm>
    </Styled>
  );
};

export default ExtraFilters;
