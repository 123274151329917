import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [ticketMissingAttachment, setTicketMissingAttachment] = useState({ loading: false, data: [] });

  const getTicketMissingAttachment = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setTicketMissingAttachment(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Tickets Missing Attachment', {Parameters}).then(rs => {
          setTicketMissingAttachment({ data: rs && rs.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
      ticketMissingAttachment,
      getTicketMissingAttachment,
  };
};

export default useEntityMetrics;
