import React, {useEffect, useState} from "react";
import style from './style';
import styled from 'styled-components';
import LineItem from "./line-item";
import {Divider} from "antd";

const Styled = styled.div`${style}`;

const LineItems = ({ billable, products, onChange }) => {
  console.log(billable)
  useEffect(() => {
    if (billable) {
      console.log(billable)
    }
  }, [billable])

  return (
    <Styled>
      {billable && <Divider>Products and Fees</Divider>}
      <table>
        <tbody>
        {billable?.lineItems?.map((lineItem, index) => (
          <LineItem key={`lineItem-${index}`}
                    lineItem={lineItem}
                    products={products}
                    onChange={onChange}
                    index={index + 1}
          />
        ))}
        </tbody>
      </table>

    </Styled>
  )
}

export default LineItems;
