import React, { useCallback } from 'react';
import { Space } from 'antd';
import {Form, DynamicForm, TYPE} from '../../../../../components/dynamic-form';
import {set} from 'lodash';

const listValues = {
  selectField: [{
    label: 'Label 1', value: 'VALUE_1'
  },{
    label: 'Label 2', value: 'VALUE_2'
  },{
    label: 'Label 3', value: 'VALUE_3'
  }]
}

const formConfig = {
  sections: [{
    label: 'Url',
    fields: [
    //   {
    //   id: 'description',
    //   label: `Widget Description`,
    //   type: TYPE.STRING,
    //   ignoreIntl: true,
    // },
      {
        id: 'iframeUrl',
        label: `Url`,
        type: TYPE.STRING,
        ignoreIntl: true,
      },{
        id: 'useConnexAuthentication',
        label: `Use Connex Authentication?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },]
  }]
}

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      set(newConfig, field, value);
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
