import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Average Loads Per Driver',
  description: 'Displays average number of loads per driver per day.'
}

export default DisplayOptions;