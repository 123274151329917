import {css} from 'styled-components';

export default css`
width: 100%;
margin-top: 25px;

td {
  padding: 3px;
}

.ant-input {
  width: 100px;
}

.product-description .ant-input {
  width: 100%;
}

.add-product {
  float: right;
  
  .fa-plus {
    margin-right: 10px;
  }
  
  margin-bottom: 20px;
}
`;
