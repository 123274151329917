import React from 'react';
import Input from '../../input';

const DynamicCurrency = ({ config, onChange, ...otherProps }) => {
  const handleChange = e => {
    const regexp = /^[0-9,.]*$/;
    const value = e.target.value;

    if (regexp.test(value)) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="cx-dynamic-field">
      <Input type="number" name={config.id} {...otherProps} onChange={handleChange} />
    </div>
  );
};

export default DynamicCurrency;
