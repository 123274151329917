import {useDashboardContext} from "../dashboard/useDashboardContext";
import {useEffect} from "react";

const useWidgetSubscription = (filter, callback, enabled = true) => {
  const {subscribe, unsubscribe, publish} = useDashboardContext();

  useEffect(() => {
    if(!enabled || !filter || !callback) {
      return;
    }

    const id = subscribe(filter, callback);

    return () => {
      unsubscribe(id);
    }
  }, [filter, callback, enabled])

  return {publish};
}

export default useWidgetSubscription;