import React from 'react';
import {useWidgetContext} from '../../useWidgetContext';
import styled from 'styled-components';
import TicketList from "./TicketList";

const Styled = styled.div`
  width: 100%;
  height: 100%;
  
  &&& {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Component = () => {
  const { config, filterValues, parentRef } = useWidgetContext();

  return (
    <Styled ref={parentRef}>
      <TicketList config={config} filterValues={filterValues}/>
    </Styled>
  );
};

export default Component;
