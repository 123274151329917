import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts';
import { productColors } from '../util';

const yAxisProps = {
  fontSize: 12,
  tick: {
    fill: 'white',
  },
};

const MultipleRowsBarChart = ({
  width,
  height = 400,
  data,
  xValueType = 'number',
  yAxisWidth,
  yValue = 'name',
  yValueType = 'category',
  showLegend = true,
  layout,
  showTooltip,
  showXAxis = true,
  bars,
  displayLabelList = true,
  valueFormatter,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={width}
        height={height}
        data={data}
        stackOffset="sign"
        layout={layout}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis type={xValueType} hide={!showXAxis} />
        <YAxis {...yAxisProps} dataKey={yValue} type={yValueType} width={yAxisWidth || width} interval={0} />

        {showTooltip && (
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ color: '#333' }} isAnimationActive={false} />
        )}
        {showLegend && <Legend verticalAlign="bottom" />}
        <ReferenceLine x={0} stroke="#ffffff" />
        {bars?.map(bar => (
          <Bar name={bar.name} key={bar.key} dataKey={bar.dataKey} stackId={bar.stackId} fill={productColors[bar.fill]}>
            {displayLabelList && <LabelList dataKey={bar.dataKey} position="insideRight" formatter={valueFormatter} />}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MultipleRowsBarChart;
