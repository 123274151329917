export const getItem = (key) => {
  const obj = window.localStorage.getItem(key);
  return (obj && JSON.parse(obj)) || null;
};
export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key) => {
  window.localStorage.removeItem(key);
}

export const clear = () => {
  window.localStorage.clear();
}

export default {
  getItem,
  setItem,
  removeItem,
  clear,
};
