import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [attachmentCount, setAttachmentCount] = useState({ loading: false, data: [] });

  const getAttachmentCount = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setAttachmentCount(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Total Attachments Per Origin', {Parameters}).then(rs => {
          setAttachmentCount({ data: rs && rs.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
      attachmentCount,
      getAttachmentCount,
  };
};

export default useEntityMetrics;
