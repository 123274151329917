export const flattenConfig = formConfig => {
  const fields = [];

  if(formConfig) {
    if (formConfig.sections) {
      formConfig.sections.forEach(section => {
        fields.push(...section.fields);
      });
    } else if(Array.isArray(formConfig)) {
      fields.push(...formConfig);
    }
  }

  return fields;
};
