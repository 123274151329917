import { isArray, sortBy } from 'lodash';
const sortFieldsByFieldSet = fields => sortBy(fields, f => f.fieldSet);

export const prepareConfig = (formConfig = {}) => {
  if (isArray(formConfig)) {
    return sortFieldsByFieldSet(formConfig);
  } else if (isArray(formConfig.sections)) {
    const sortedFormConfig = { ...formConfig };
    sortedFormConfig.sections = sortedFormConfig.sections.map(section => {
      section.fields = sortFieldsByFieldSet(section.fields);
      return section;
    });
    return sortedFormConfig;
  }
};
