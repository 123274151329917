import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useParams} from 'react-router-dom';
import api from './http';
import API from './api';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import Spinner from './components/spinner/Spinner';
import InvalidUser from './views/InvalidUser';
import UserContextProvider from './UserContextProvider';

const AuthCheck = () => {
  const [validUser, setValidUser] = useState(false);
  const [userRef, setUserRef] = useState(null);
  const [entity, setEntity] = useState({});
  const [permissions, setPermissions] = React.useState(null);
  const [loadingBusy, setLoadingBusy] = useState(true);
  const navigate = useNavigate();
  const {entityRef} = useParams();

  useEffect(() => {
    const url = `/connex/services/auth/${entityRef}`;
    const token = localStorage.getItem('x-connex-id');
    const run = async () => {
      const resp = await api
        .post(url, {
          source: localStorage.getItem('source') || 'connex',
          token,
        });

      setUserRef(resp.userRef);
      setValidUser(resp.valid);
      setPermissions(resp.permissions);


      const entity = await API.getEntity(entityRef);
      setEntity(entity);

      navigate(`/${window.location.pathname}`);

      setLoadingBusy(false);
    }

    if(entityRef) {
      run().then();
    }

  }, [entityRef, navigate, setUserRef]);

  return (
    <Spinner spin={loadingBusy}>
      {validUser ? (
        <UserContextProvider user={{userRef, permissions}} entity={entity}>
          <AuthenticatedRoutes/>
        </UserContextProvider>
      ) : (
        <InvalidUser/>
      )}
    </Spinner>
  );
};

export default AuthCheck;
