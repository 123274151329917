import React, {useMemo} from 'react';
import _ from 'lodash';
import BarChart from "../../../../../components/charts/BarChart";
import {useUserContext} from "../../../../../UserContextProvider";
import {DateTime} from 'luxon';

const getShiftedHour = (utcHourNumber, timeZone) => {
  const value = DateTime.utc().set({hour: utcHourNumber}).setZone(timeZone).hour;
  return value;
}

const TotalQuantitiesByHour = ({ data, config, onClick }) => {
  const fieldName = 'hourNum';

  const {entity} = useUserContext();

  const reducedData = useMemo(() => {
    let result = [...data];
    result = result.filter(item => !item.hourNum.isNaN).map(r => ({
      ...r,
      hourNum: getShiftedHour(r.hourNum, entity.timeZone),
    }))
    result.sort((a,b) => a.hourNum < b.hourNum ? -1 : 1);
    return result?.reduce((acc, item) => {
      const hourNum = item.hourNum;

      const itemName = `Hour : ${hourNum}`;
      const newItem = {};
      const type = 'Quantity';
      const predicate = {};
      _.set(predicate, fieldName, itemName);
      const indexOfExistingItemInResult = _.findIndex(acc, predicate);
      let displayLabelText = `${itemName}`;
      if (indexOfExistingItemInResult === -1) {
        if(config){
          displayLabelText = `
        ${itemName}                  
        ${config.projectLabelShowQuantity ? ` : ${item.qty_sum}` : ''}
      `
        }
        _.set(newItem, 'name', displayLabelText);
        _.set(newItem, fieldName, itemName);
        _.set(newItem, 'qty_sum', item.qty_sum);
        _.set(newItem, 'type', type);
        _.set(newItem, type, item.qty_sum);
        acc.push(newItem);
      } else {
        _.set(acc[indexOfExistingItemInResult], type, item.qty_sum);
      }
      return acc;
    }, []);


  }, [data, config, entity])

  return (
    <BarChart
      fieldName={fieldName}
      onClick={onClick}
      showXAxis={config?.displayXAxis || false}
      xAxisLabel={false}
      yAxis={false}
      data={reducedData}
      xValue={'qty_sum'}
      xValueType={'number'}
      layout={'vertical'}
      yAxisWidth={500}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
    />
  );
};

export default TotalQuantitiesByHour;