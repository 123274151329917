import Editor from "./Editor";
import Component from "./Component";

const DisplayOptions = {
  Editor,
  Component,
  name: 'Query Results',
  description: 'Query results table from selected console query.',
}

export default DisplayOptions;