import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const FieldContainer = ({ className, children, ...props }) => {
  return (
    <div className={cn('field-container', className)} {...props}>
      {children}
    </div>
  );
};

export default styled(FieldContainer)`
  ${style}
`;
