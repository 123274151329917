import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Entity Feature Counts',
  description: 'Count of features per entity.'
}

export default DisplayOptions;
