import Widget from "../../widgets/Widget";
import React, {useState} from "react";
import GridLayout, { WidthProvider } from 'react-grid-layout';
import cn from 'classnames';
import styled from 'styled-components';
import {find} from "lodash";
import {useParams} from "react-router-dom";
import {useDashboardContext} from "../useDashboardContext";
import FilterBar from "../../filters/filter-bar";
import {callWithMetrics} from "../../../../util/metrics/recordMetric";


const ReactGridLayout = WidthProvider(GridLayout);

const Worksheet = ({config = {}, className, onChange}) => {
  const [headerVisible] = useState(false);
  const { frozen, dashboard, updateDashboard } = useDashboardContext();
  const {widgets = []} = config;
  const { entityRef } = useParams();

  return <div className={cn(className)}>
    <FilterBar />
    <div className={'dashboard-grid-container'} style={{padding: frozen ? '8px': '0px'}}>
      <ReactGridLayout
        className={cn('layout')}
        cols={6}
        rowHeight={200}
        height={'100%'}
        margin={frozen ? [2,2] : [10,10]}
        measureBeforeMount={true}
        isDraggable={!frozen}
        isResizable={!frozen}
        useCSSTransforms={false}
        draggableHandle={'.widget-header'}
        onLayoutChange={newLayoutItems => {
          callWithMetrics(updateDashboard({
            ...dashboard,
            worksheets: dashboard.worksheets.map(w => {
              if(w.id !== config.id) {
                return w;
              }
              return {
                ...w,
                widgets: w.widgets.map(existingWidget => {
                  const layoutItem = find(newLayoutItems, l => l.i === existingWidget.id);
                  if(!layoutItem) {
                    return existingWidget;
                  }

                  return {
                    ...existingWidget,
                    layout: {
                      x: layoutItem.x,
                      y: layoutItem.y,
                      width: layoutItem.w,
                      height: layoutItem.h,
                    }
                  }
                })
              }
            })
          }, !frozen), { type: 'user-interaction', data: { type: 'button-click', id: 'workspace-move-widget' }, entityRef })
        }}
        // onDragStart={i => console.log('dragStart', i)}
        // onDrag={i => console.log('drag', i)}
        // onDragStop={i => console.log('dragStop', i)}
        // onResizeStart={i => console.log('ResizeStart', i)}
        // onResize={i => setLayout(i)}
        // onResizeStop={i => console.log('ResizeStop', i)}
      >
        {widgets.map((widget) => {
          return (
            <div
              key={widget.id}
              data-grid={{
                x: widget.layout.x,
                y: widget.layout.y,
                w: widget.layout.width,
                h: widget.layout.height,
              }}
            >
              <Widget config={widget} />
            </div>
          );
        })}
      </ReactGridLayout>
    </div>
  </div>
}

export default styled(Worksheet)`
  height: 100%;
  .dashboard-grid-container {
    width: 100%;
    height: calc(100% - 25px);

    overflow-y: auto;
    overflow-x: hidden;

  }
`;
