import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Space } from 'antd';
import {Form, DynamicForm, TYPE} from '../../../../../components/dynamic-form';
import API from '../../../../../api';
import {useParams} from "react-router-dom";
import SummaryColumns from './summary-columns';

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [{
      id: 'visualizationType',
      label: `Visualization Type`,
      type: TYPE.STRING,
      ignoreIntl: true,
    }, {
      id: 'queryRef',
      label: `Select Query`,
      type: TYPE.STRING,
      ignoreIntl: true,
    }, {
      id: 'showSummary',
      label: 'Show Summary Row',
      type: TYPE.BOOLEAN,
      ignoreIntl: true,
      dependencies: { fieldId: 'queryRef' },
    }]
  }]
}

const Editor = ({ onChange, options = {queryRef: '', visualizationType: 'table'}, widget }) => {
  const { entityRef } = useParams();
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    API.listQueries(entityRef, ['Support']).then(results => {
      const filteredResults = (results?.items || []).filter(result => result.Tags.indexOf('Support') >= 0);
      setQueries(filteredResults);
    })
  }, [entityRef])

  const listValues = useMemo(() => {
    return {
      queryRef: queries.map(q => ({label: q.Name, value: q.UID})),
      visualizationType: [{label: 'Table', value: 'table'},{label: 'BigNumber', value: 'bigNumber'}],
    };
  }, [queries]);

  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  if(widget.typeId === 'published-widget') {
    return null;
  }

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
      {options?.showSummary && (
        <SummaryColumns values={options} onChange={onConfigChange}></SummaryColumns>
      )}
    </Space>
  );
};

export default Editor;
