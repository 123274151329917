import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import propTypes from 'prop-types';
import style from './style';

// const deriveDateFormat = () => {
//   const isoString = '2018-09-25';
//
//   const intlString = this.formatDate(isoString);
//   const dateParts = isoString.split('-');
//
//   return intlString
//     .replace(dateParts[2], 'DD')
//     .replace(dateParts[1], 'MM')
//     .replace(dateParts[0], 'YYYY');
// };

export const DateRangePicker = ({
  className,
  id,
  'data-test': dataTest,
  showTime,
  showNow,
  onChange,
  initialValue,
  value,
  today,
  separator,
  allowEmpty,
  allowClear,
  inputReadOnly,
  format,
  column,
  placeholder,
  style,
  ...rest
}) => {
  const { RangePicker } = AntDatePicker;
  let defaultValue = null;

  if (today) {
    defaultValue = moment();
  } else if (initialValue) {
    defaultValue = moment(initialValue);
  }

  const props = {
    showTime,
    onChange,
    defaultValue,
    value,
    separator,
    showNow,
    allowEmpty,
    allowClear,
    inputReadOnly,
    format,
    column,
    placeholder,
    style,
  };

  return (
    <RangePicker
      {...rest}
      className={cx(className, 'cx-date-picker')}
      data-test={dataTest}
      id={id}
      {...props}
    />
  );
};

DateRangePicker.propTypes = {
  allowClear: propTypes.bool,
  allowEmpty: propTypes.bool,
  className: propTypes.string,
  column: propTypes.element,
  'data-test': propTypes.string,
  defaultValue: propTypes.any,
  format: propTypes.string,
  id: propTypes.string,
  initialValue: propTypes.string,
  onChange: propTypes.func,
  separator: propTypes.string,
  showNow: propTypes.bool,
  showTime: propTypes.bool,
  value: propTypes.oneOfType([propTypes.string, propTypes.object]),
  today: propTypes.string,
  placeholder: propTypes.string,
  style: propTypes.object,
};

export default styled(DateRangePicker)(style);
