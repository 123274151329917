import React from "react";
import useFitText from "../../../../components/useFitText";
import {toString} from 'lodash';

const Table = ({data}) => {
  const value = toString(data?.items?.[0]?.value);
  const {fontSize, ref, parentRef} = useFitText(value);

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        <div>{value}</div>
      </div>
    </div>
  );
}

export default Table;