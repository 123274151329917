const keyOverride = {
  // Uncomment for testing connex paths when running locally. -Mason
  '/v2/': 'G9mi9hDoZBaQvwGlJEgVn3StkIfCoJNu6IyylHWL',
  '/connex/services/analytics/': 'G9mi9hDoZBaQvwGlJEgVn3StkIfCoJNu6IyylHWL',
  '/connex/services/workspaces/': 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM',
}

const apiKey = (path) => {
  for(const key of Object.keys(keyOverride)) {
    if(path.startsWith(key)) {
      return keyOverride[key];
    }
  }

  return 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM';
}

export default apiKey;
