import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Ticket Count By Delivery Method',
  description: 'Displays ticket count by delivery methods.'
}

export default DisplayOptions;