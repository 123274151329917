import React, {useEffect, useMemo, useState} from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import useFitText from '../../components/useFitText'
import API from '../../../../../api';
import {useParams} from "react-router-dom";
import {find} from 'lodash';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

const {executeQueryByName} = API;

const Component = () => {
  const { config, filterValues } = useWidgetContext();
  const {entityRef} = useParams();
  const [result, setResult] = useState({});
  const value = JSON.stringify(result, null, 2);
  const {fontSize, ref, parentRef} = useFitText(value);

  useEffect(() => {
    const Parameters = getParametersFromFilters(filterValues);
    const timeoutId = setTimeout(() => {
      executeQueryByName(entityRef, 'Ticket Count By Status', {Parameters}).then(result => {
        setResult(result);
      })
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    }
  }, [config, filterValues, entityRef])

  const displayedValues = useMemo(() => {
    const displayedStatus = config?.options?.displayedStatus || 'PENDING';
    const count = result.items?.[0]?.data?.[displayedStatus] || 0;
    return {
      count,
      header: displayedStatus === 'ACCEPTED' ? 'RECEIVED' : displayedStatus,
    }
  }, [config?.options, result]);

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        <div>{displayedValues.header}</div>
        <div>{displayedValues.count}</div>
      </div>
    </div>
  );
};

export default Component;
