import React, {useEffect} from 'react';
import { Empty } from 'antd';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { productColors } from './util';

const Chart = ({
  width,
  height = 400,
  data,
  xAxisLabel = 'Quantity',
  xValue = 'val',
  xValueType = 'number',
  yAxisWidth,
  yValue = 'name',
  yValueType = 'category',
  showLegend = true,
  barSize = 36,
  barCategoryGap = 6,
  layout,
  typesArray,
  onClick,
  showTooltip,
  showXAxis = true,
  bars,
}) => {
  const barTypesUsed = [];

  const yAxisProps = {
    dataKey: 'name',
    tickLine: false,
    axisLine: false,
    fontSize: 12,
    mirror: true,
    type: 'category',
    tick: {
      fill: 'white',
    },
  };

  const handleClick = e => {
    if (e) {
      // eslint-disable-next-line no-unused-expressions
      onClick && onClick(e);
    }
  };

  if(!data || data.length < 1){
    return <Empty />;
  }

  return (
    <ResponsiveContainer>
      <BarChart
        barCategoryGap={barCategoryGap}
        barSize={barSize}
        layout={layout}
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        onClick={handleClick}
      >
        <XAxis
          type={xValueType}
          dataKey={xValue}
          hide={!showXAxis}
          height={75}
          label={xAxisLabel && {value: xAxisLabel, offset: 10, style: {fill: 'white', fontSize: 14}}}
        />
        {showTooltip && (
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ color: '#333' }} isAnimationActive={false} />
        )}
        {showLegend && <Legend verticalAlign="bottom" />}
        {bars &&
          bars.length > 0 &&
          bars.map(bar => {
            return (
              <Bar
                name={bar.name}
                key={bar.key}
                dataKey={bar.dataKey}
                stackId={bar.stackId}
                fill={productColors[bar.fill]}
              />
            );
          })}
        {(!bars || bars.length === 0) &&
          typesArray &&
          typesArray.map(bar => {
            if (!barTypesUsed.includes(bar)) {
              barTypesUsed.push(bar);
              return (
                <Bar
                  key={bar}
                  dataKey={bar}
                  stackId="stack1"
                  fill={productColors[bar.toUpperCase()] || productColors.DEFAULT}
                />
              );
            }
            return '';
          })}
        {(!bars || bars.length === 0) &&
          !typesArray &&
          data.map(bar => {
            if (!barTypesUsed.includes(bar.type)) {
              barTypesUsed.push(bar.type);
              return (
                <Bar
                  key={bar.type}
                  dataKey={bar.type}
                  stackId="stack1"
                  fill={productColors[bar && bar.type && bar.type.toUpperCase()] || productColors.DEFAULT}
                />
              );
            }
            return '';
          })}
        <YAxis {...yAxisProps} type={yValueType} dataKey={yValue} width={yAxisWidth || width} interval={0} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
