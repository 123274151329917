import React from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import Input from "../../../../../../../../components/form/Input";
import FieldContainer from "../../../../../../../../components/field-container/FieldContainer";
import getUomAbbreviationOrDescription from "../../../../../../../../util/uom";

const View = styled(FieldContainer)`${style}`;

const InputQuantity = ({ className, addOn, id, label, value, uomCode, min = 1, disabled = false, onChange }) => {
  const addOnAfter = addOn ?? (uomCode && getUomAbbreviationOrDescription(uomCode));

  return (
    <View className={cn(id, className)}>
      {label && <div className="label-spinner">
        <div className="label">{label}</div>
      </div>}
      <Input
        className={'input-quantity'}
        type="number"
        min={min}
        value={value || ''}
        disabled={disabled}
        data-testid={id}
        onChange={e => onChange ? onChange(id, e.target?.value) : console.log(id, e.target?.value)}
        addonAfter={addOnAfter}
      />
    </View>
  );
};

export default InputQuantity;
