import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Tickets Missing Attachment',
  description: 'Displays tickets with no associated images. Selecting a ticket will open ticket details.'
}

export default DisplayOptions;
