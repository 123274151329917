import React, {useCallback} from 'react';
import {DynamicForm, Form, TYPE} from '../../../../../components/dynamic-form';
import { Space } from 'antd';

const formConfig = {
  sections: []
}


const Editor = ({ onChange, options, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig}>
        <Form />
      </DynamicForm>

    </Space>
  );
};

export default Editor;