import Editor from './Editor';
import Component from './Component';

const UnbilledDeliveryItem = {
  Editor,
  Component,
  name: 'Unbilled Delivery Item',
  description: 'Displays a billable item (selected other widgets)'
}

export default UnbilledDeliveryItem;
