import Editor from './Editor';
import Component from './Component';

const UnbilledDeliverySummary = {
  Editor,
  Component,
  name: 'Unbillable Deliveries',
  description: 'List of tickets that cannot be invoiced'
}

export default UnbilledDeliverySummary;
