import _ from 'lodash';

export const productColors = {
  DEFAULT: '#2981D7',
  DARK: '#184d81',
  CY: '#2471A3',
  TN: '#5499C7',
  ORANGE: '#ED7D31',
  GRAY: '#A5A5A5',
};

export const productColorsMulti = {
  UNDEFINED: '#2f4b7c',
  UNKNOWN: '#2f4b7c',
  NONE: '#2f4b7c',
  AGGREGATE: '#665191',
  CEMENTITIOUS: '#d45087',
  WATER: '#003f5c',
  ADMIXTURE: '#f95d6a',
  ADDITIVE: '#a05195',
  OIL: '#ffa600',
  CONCRETE: '#ff7c43',
  ASPHALT: '#129490',
  CONSTITUENT_BLEND: '#efa46a',
  REINFORCING_MATERIAL: '#b2d5a3',
  MANUFACTURED_PRODUCT: '#4e99af',
  KIT: '#66c8c6',
  BAGGED_GOODS: '#ff9cc1',
  EXTRA_CHARGE: '#a490cd',
  OTHER_PRODUCT: '#7a7cb3',
  BUILDING_MATERIAL: '#ff90ae',
  SERVICE: '#d194d5',
  DEFAULT: '#ff7c43',
  Pending: '#ffa600',
  Accepted: '#129490',
  Rejected: '#f95d6a',
};

export const productColorsBlue = {
  Undefined: '#005272',
  NONE: '#005272',
  AGGREGATE: '#006788',
  CEMENTITIOUS: '#007c9e',
  WATER: '#0092b3',
  ADMIXTURE: '#00a8c8',
  ADDITIVE: '#00bfdb',
  OIL: '#00d7ee',
  CONCRETE: '#003f5c',
  ASPHALT: '#266182',
  CONSTITUENT_BLEND: '#82d4ff',
  REINFORCING_MATERIAL: '#15506f',
  MANUFACTURED_PRODUCT: '#00efff',
  KIT: '#367396',
  BAGGED_GOODS: '#4585aa',
  EXTRA_CHARGE: '#5498bf',
  OTHER_PRODUCT: '#63acd4',
  BUILDING_MATERIAL: '#72c0e9',
  SERVICE: '#00b2d8',
};

export const displayIdAndDesc = (id, desc, defaultValue = null, separator = ' / ') => {
  if (id && desc) {
    return id + separator + desc;
  }
  if (id) {
    return id;
  }
  if (desc) {
    return desc;
  }
  return defaultValue;
};

export const trimName = (itemName, defaultValue) => {
  const name = _.trim(itemName);
  const arrayOfFullName = name.split('/');
  return displayIdAndDesc(arrayOfFullName[0], arrayOfFullName[1], defaultValue);
};
