import React, { useCallback } from 'react';
import { Space } from 'antd';
import {Form, DynamicForm, TYPE} from '../../../../../components/dynamic-form';

const listValues = {
  selectField: [{
    label: 'Label 1', value: 'VALUE_1'
  },{
    label: 'Label 2', value: 'VALUE_2'
  },{
    label: 'Label 3', value: 'VALUE_3'
  }]
}

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [{
      id: 'stringField',
      label: `String Field Label`,
      type: TYPE.STRING,
      ignoreIntl: true,
    },{
      id: 'selectField',
      label: `Select Field Label`,
      type: TYPE.STRING,
      ignoreIntl: true,
    },]
  }]
}

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>

    </Space>
  );
};

export default Editor;
