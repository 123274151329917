import Editor from './Editor';
import Component from './Component';

const totalMarginsGroupedBy = {
  Editor,
  Component,
  name: 'Total Margins',
  description: 'Total Margins',
};

export default totalMarginsGroupedBy;
