const { REACT_APP_ENDPOINT = 'https://api.commandalkon.io' } = process.env;

console.log(`Using API ${REACT_APP_ENDPOINT}`)

const pathOverride = {
  // Uncomment for testing connex paths when running locally. -Mason
  '/v2/': 'http://localhost:8000',
  '/connex/services/auth/': 'https://api-stage.commandalkon.io',
}

const hostname = (path = '') => {
  const { hostname } = window.location;

  if (hostname.includes('localhost')) {
    for (const key of Object.keys(pathOverride)) {
      if (path.startsWith(key)) {
        return pathOverride[key];
      }
    }
  }

  if (hostname.includes('model')) {
    return 'https://connex-model-api.commandalkon.io'
  }

  if (hostname.includes('workspaces-develop')) {
    return 'https://api-stage.commandalkon.io'
  }

  return REACT_APP_ENDPOINT
}

export default hostname;
