import React, { useCallback } from 'react';
import { Space } from 'antd';
import {DynamicForm, Form, TYPE} from "../../../../../components/dynamic-form";

const listValues = {
  projectLabelShowId: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  projectLabelShowName: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  projectLabelShowQuantity: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  displayLegend: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  displayTooltip: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  displayXAxis: [{
    label: 'Yes', value: true
  },{
    label: 'No', value: false
  }],
  maxResults: [
    {
      label: '10', value: 10
    },
    {
      label: '15', value: 15
    },
    {
      label: '25', value: 25
    },
    {
      label: '35', value: 35
    },
    {
      label: '50', value: 50
    },
  ]
}

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [
      {
        id: 'projectLabelShowId',
        label: `Show Project ID On Label?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'projectLabelShowName',
        label: `Show Project Name On Label?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'projectLabelShowQuantity',
        label: `Show Project Quantity On Label?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'displayLegend',
        label: `Show Product Legend?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'displayTooltip',
        label: `Show Tooltip`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'displayXAxis',
        label: `Show X Axis?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'maxResults',
        label: 'Maximum Rows Shown (Requires Refresh)',
        type: TYPE.STRING,
        ignoreIntl: true,
      }
    ]
  }]
}

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
