import React, { useCallback } from 'react';
import { Space } from 'antd';
import {DynamicForm, Form, TYPE} from "../../../../../components/dynamic-form";

const listValues = {
}

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [
      {
        id: 'displayLegend',
        label: `Show Product Legend?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'displayTooltip',
        label: `Show Tooltip`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      },
      {
        id: 'displayXAxis',
        label: `Show X Axis?`,
        type: TYPE.BOOLEAN,
        ignoreIntl: true,
      }
    ]
  }]
}

const Editor = ({ onChange, options = {}, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
