import http from '../http';

const workspaces = '/connex/services/workspaces';

export default {
  // getUserWorkspaces: entityRef => http.get(`${workspaces}/${entityRef}/user/workspaces`),
  // getCompanyWorkspaces: entityRef => http.get(`${workspaces}/${entityRef}/workspaces`),
  // getCompanyDatasets: entityRef => http.get(`${workspaces}/${entityRef}/datasets`),
  listEntities: entityRef => http.get(`${workspaces}/${entityRef}/entities`),
  getEntity: entityRef => http.get(`${workspaces}/${entityRef}`),
  saveDashboard: (entityRef, dashboardRef, dashboard) =>
    http.post(`${workspaces}/${entityRef}/dashboards/${dashboardRef}`, dashboard),
  createDashboard: (entityRef, dashboard) => http.post(`${workspaces}/${entityRef}/dashboards`, dashboard),
  deleteDashboard: (entityRef, dashboardRef) => http.del(`${workspaces}/${entityRef}/dashboards/${dashboardRef}`),

  getDashboard: (entityRef, dashboardRef) => http.get(`${workspaces}/${entityRef}/dashboards/${dashboardRef}`),

  saveWidget: (entityRef, widget) => http.post(`${workspaces}/${entityRef}/widgets`, widget),
  listWidgets: entityRef =>
    http.get(`${workspaces}/${entityRef}/widgets?source=${localStorage.getItem('source') || 'connex'}`),

  publishWidget: (entityRef, widgetConfig) => http.post(
    `${workspaces}/${entityRef}/widgets?source=${localStorage.getItem('source') || 'connex'}&published=true`,
    widgetConfig
  ),
  listPublishedWidgets: entityRef => http.get(
    `${workspaces}/${entityRef}/widgets?source=${localStorage.getItem('source') || 'connex'}&published=true`
  ),

  executeWidgetQuery: (entityRef, parameters) => http.post(`${workspaces}/${entityRef}/widgets/query`, parameters),
  executeQuery: (entityRef, queryRef, parameters) =>
    http.post(`${workspaces}/${entityRef}/queries/${queryRef}/execute`, parameters),
  executeQueryByName: (entityRef, name, parameters) => http.post(`${workspaces}/${entityRef}/queries/execute`, {
    ...parameters,
    Name: name,
  }),

  listQueries: (entityRef, Tags = []) => http.post(`${workspaces}/${entityRef}/queries`, {Tags}),

  listRoles: entityRef => http.get(`${workspaces}/${entityRef}/roles`),

  listDashboardShares: (entityRef, dashboardRef) =>
    http.get(`${workspaces}/${entityRef}/dashboards/${dashboardRef}/share`),
  unshareDashboard: (entityRef, dashboardRef, unshareData) =>
    http.post(`${workspaces}/${entityRef}/dashboards/${dashboardRef}/un-share`, unshareData),

  shareDashboard: (entityRef, share, dashboard) =>
    http.post(`${workspaces}/${entityRef}/dashboards/${dashboard.crn}/share`, {
    dashboard,
    share
  }),
  refreshDashboards: (dashboard) => {
    const parameters = {
      type: 'refresh',
      dashboard,
    }
    window.parent.postMessage(JSON.stringify(parameters), "*");
  },
  hideDashboard: (dashboard) => {
    const parameters = {
      type: 'hide',
      dashboard,
    }
    window.parent.postMessage(JSON.stringify(parameters), "*");
  },
  getFilterValues: (entityRef, fieldRef, parameters) => http.post(`${workspaces}/${entityRef}/filter-values/${fieldRef}`, parameters),
};
