import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useWorkspacesContext} from "../../context/useWorkspacesContext";
import WidgetEditor from "../../widgets/WidgetEditor";
import {callWithMetrics} from "../../../../util/metrics/recordMetric";
import {useParams} from "react-router-dom";
import {kebabCase} from "lodash";
import {useDashboardContext} from "../../dashboard/useDashboardContext";

const WidgetPreview = ({ widget, className }) => {
  const { isPlatformSupportEntity, addWidgetToCurrentWorksheet, setDrawerContent } = useWorkspacesContext();
  const { entityRef } = useParams();

  const poorLittleOrphanIcon = {
    fontSize: '1.2em',
  };

  const orphansSibling = {
    marginTop: '7px',
    fontStyle: 'italic',
  };

  const handleAdd = useCallback(widget => {
    const newlyAddedWidget = addWidgetToCurrentWorksheet(widget);
    setDrawerContent(<WidgetEditor config={newlyAddedWidget} />);
  }, [addWidgetToCurrentWorksheet, setDrawerContent]);

  const metric = { type: 'user-interaction', data: { type: 'button-click', id: 'workspace-widget-added', widget: widget.crn } };
  return <div className={className} onClick={callWithMetrics(() => handleAdd(widget), metric, entityRef)}  data-testid={`add-widget-${kebabCase(widget.name)}`}>
    <div className={'preview-title-line'}>
      <span><i className={`fad fa-angle-double-left`}
               style={poorLittleOrphanIcon}
               /> </span>
      <span className={'preview-name'}>{widget.name}</span>
      {widget.publishedWidgetRef && isPlatformSupportEntity && <span className="published-icon">Published</span>}
    </div>
    <div  style={orphansSibling}>{widget.description}</div>
  </div>;
};

export default styled(WidgetPreview)`
  min-width: 0;
  width: 100%;
  height: 75px;
  border: solid 1px #333333;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 0.9em;
  cursor: pointer;
  
  .preview-title-line {
    display:flex;
    
    .preview-name {
      flex: 1 0 0;  
    }
    .published-icon {
      background: #DF4E39;
      flex: 0 1 0;
      align-self: flex-end;
      border-radius: 11px;
      padding: 5px;
    }
  }
  
  i {
    font-size: 1.2rem;
    margin-right: 10px;  
    &:hover {
      color: #3c9ae8;
    }
  }
`;
