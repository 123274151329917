import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import EntityFeatures from "./EntityFeatures";
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import cn from "classnames";
import useWidgetSubscription from '../../../hooks/useWidgetSubscription';

const filter = { action: 'select-entity' };

const Component = ({ className }) => {
  const { config, parentRef} = useWidgetContext();
  const {
    features,
    getFeatures,
  } = useAPI();

  const refresh = useCallback((data) => {
    getFeatures(data.value, 1000);
  }, [getFeatures]);

useWidgetSubscription(filter, refresh);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={features.loading}>
        <EntityFeatures
          config={config}
          data={features.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;





