import { useWidgetContext } from './useWidgetContext';
import cn from 'classnames';
import WidgetEditor from './WidgetEditor';
import styled from 'styled-components';
import React from 'react';

let DataContainer = ({ className, children }) => {
  const { loading } = useWidgetContext();

  if (loading) {
    return (
      <span className={'fa-10x'} style={{ margin: 'auto' }}>
        <span className={'fas fa-sync fa-spin'} />
      </span>
    );
  }

  return (
    <div className={cn(className)}>
      {children}
    </div>
  );
};

export default styled(DataContainer)`
  flex: 1;
  min-height: 0;
  overflow: auto;
  background: #000;
`;
