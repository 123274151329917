import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, Treemap } from 'recharts';
import API from './api';
import { useParams } from 'react-router-dom';
import createSummary from './api/create-summary';
import CustomBillable from './CustomBillable';
import getStartDate from './api/get-start-date';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/ConnexDataGrid';

const loading = [
  {
    name: 'loading',
    children: [{ name: 'Loading...', size: 1 }],
  },
];

const columns = [
  { key: 'locationId', name: 'Location' },
  { key: 'customerId', name: 'Customer ID' },
  { key: 'projectId', name: 'Project ID' },
  { key: 'orderId', name: 'Order ID' },
  { key: 'ticketId', name: 'Ticket ID' },
  { key: 'dispatchDate', name: 'Dispatch Date' },
];

const BILLABLE_LIST_OPTION = 'billable-list';

const BillableSummary = ({ config, filterValues }) => {
  const [summary, setSummary] = useState(null);
  const [billables, setBillables] = useState(null);
  const [widgetType, setWidgetType] = useState(null);
  const [loadingGrid, setLoadingGrid] = useState(false);

  const { entityRef } = useParams();

  useEffect(() => {
    const params = { startDate: getStartDate(filterValues), endDate: filterValues?.endDateTime };

    if (entityRef && filterValues) {
      setLoadingGrid(true);
      API.searchBillables(entityRef, params)
        .then(rs => {
          setBillables(rs['billables'] ?? []);
        })
        .catch(e => alert(e.message))
        .finally(() => setLoadingGrid(false));
    }
  }, [entityRef, filterValues]);

  useEffect(() => {
    if (Array.isArray(billables) && widgetType && widgetType !== BILLABLE_LIST_OPTION) {
      setSummary(createSummary(billables).filter(d => d.name === widgetType));
    }
  }, [billables, widgetType]);

  useEffect(() => {
    config && setWidgetType(config.options?.widgetType ?? 'progress');
  }, [config]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      {widgetType === BILLABLE_LIST_OPTION ? (
        <ConnexDataGrid
          loading={loadingGrid}
          gridName={`${config.crn}::${config.id}`}
          friendlyName={config.name}
          columns={columns}
          rows={Array.isArray(billables) ? billables.filter(b => b.isBillable === true) : []}
          rowKeyPath="crn"
          autoSizeColumns
        />
      ) : (
        <Treemap
          width={400}
          height={200}
          data={summary ?? loading}
          dataKey="size"
          ratio={4 / 4}
          isAnimationActive={true}
          animationDuration={summary ? 250 : 0}
          content={<CustomBillable />}
        />
      )}
    </ResponsiveContainer>
  );
};

export default BillableSummary;
