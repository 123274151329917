import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
  text-align: center;
`;

const GridCounts = props => {
  const { rows, totalRowCount } = useGridContext();
  return (
    <Styled className={cn('grid-counts')} {...props}>
      {`Displaying ${rows?.length < totalRowCount ? `${rows?.length || 0} of ` : ''} ${totalRowCount} Rows`}
    </Styled>
  );
};

export default GridCounts;
