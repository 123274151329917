import styled from 'styled-components';
import React from 'react';
import DateRangePicker from './date-range-picker';
import DashboardActions from '../../dashboard/DashboardActions';
import Button from '../../../../components/button/Button';
import { useDashboardContext } from '../../dashboard/useDashboardContext';
import { useWorkspacesContext } from '../../context/useWorkspacesContext';
import { Space, Collapse } from 'antd';
import ExtraFilters from './extra-filters';
import RefreshButton from './refresh-button';

const Styled = styled.div`
  .bar-container {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .filter-group {
    flex-direction: column;
    flex: 1;
    display: flex;
    width: auto;
  }

  .filter-component {
    margin-right: 5px;
    margin-left: 5px;
  }

  .ant-form-item {
    margin: 0;
  }

  .button-group {
    float: right;
  }

  .filter-bar-button {
    height: 24px;
    padding: 1px 4px 1px 4px;
  }
`;

const ContainerForExtraFilters = styled.div`
  .ant-collapse {
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;
    border: 0;
    .ant-collapse-content {
      background: transparent;
      border: 0;
    }
    .ant-collapse-item {
      border: 0;
    }
  }
`;

const { Panel } = Collapse;

const FilterBar = () => {
  const { refreshAllWidgets, dashboard, frozen } = useDashboardContext();
  const { dashboardStateDirty, saveDashboard } = useWorkspacesContext();

  const BaseFilterBar = (
    <Styled>
      {dashboard.showRefreshButton && <RefreshButton onClick={refreshAllWidgets} />}
      <div className="bar-container">
        <div className={'filter-group'}>
          {dashboard?.hideDateFilter !== true && <DateRangePicker />}
          {/*{entityRef === 'command-alkon-inc' && <EntityPicker />}*/}
        </div>
        <Space className={'button-group'}>
          {dashboardStateDirty && !frozen && (
            <Button className={'filter-bar-button'} onClick={() => saveDashboard()}>
              Save Dashboard
            </Button>
          )}
          {!dashboard.showRefreshButton && (
            <Button className={'filter-bar-button'} onClick={() => refreshAllWidgets()}>
              Refresh All
            </Button>
          )}
        </Space>
        <DashboardActions />
      </div>
    </Styled>
  );

  if (dashboard.hideExtraFilters) {
    return BaseFilterBar;
  }

  return (
    <ContainerForExtraFilters>
      {BaseFilterBar}
      <Collapse>
        <Panel header={'Filters'} key={'Filters'}>
          <ExtraFilters />
        </Panel>
      </Collapse>
    </ContainerForExtraFilters>
  );
};

export default FilterBar;
