import {cloneDeep} from 'lodash';

const getParametersFromFilters = (filterValues = {}) => {
  const filterValueCopy = cloneDeep(filterValues);

  const Parameters = [];

  const addParameter = (Name, Value, Type = 'string') => {
    Parameters.push({
      Name,
      Value,
      Type,
    })
  }

  addParameter('StartDt', filterValueCopy.startDateTime);
  addParameter('EndDt', filterValueCopy.endDateTime);

  delete filterValueCopy._id;
  delete filterValueCopy.startDateTime;
  delete filterValueCopy.endDateTime;
  delete filterValueCopy.quickDate;

  for(const [key, value] of Object.entries(filterValueCopy)) {
    let Type = 'string';

    const valueType = typeof value;

    if(valueType === 'string') {
      Type = 'string';
    } else if (valueType === 'number') {
      Type = 'float';
    } else if(value) {
      Type = 'json';
    }

    addParameter(key, value, Type);
  }

  return Parameters;
};

export default getParametersFromFilters;