import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [avgLoadTime, setAvgLoadTime] = useState({ loading: false, data: 0 });

  const getAvgLoadTime = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setAvgLoadTime(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Avg Load Time', {Parameters}).then(result => {
        setAvgLoadTime({ data: result, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    avgLoadTime,
    getAvgLoadTime,
  };
};

export default useEntityMetrics;