import React from 'react';
import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

const Select = props => {
  return <AntSelect {...props} />;
};

Select.Option = AntSelect.Option;

export default styled(Select)`
  width: 100%;
`;
