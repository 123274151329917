import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Entity Feature Flags',
  description: 'Select Entity from Entity Feature Counts widget.'
}

export default DisplayOptions;
