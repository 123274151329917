import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  text-align: center;
  .fa-times {
    color: red;
  }

  .fa-check {
    color: green;
  }
`;

const BillableRenderer = props => (
  <Styled className={cn('cell')} {...props}>
    <i className={`fa fa-${props.row.isBillable ? 'check' : 'times'}`} />
  </Styled>
);

export default BillableRenderer;
