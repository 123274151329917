import React, { useCallback } from 'react';
import { Space } from 'antd';
import {DynamicForm, Form, TYPE} from "../../../../../components/dynamic-form";

const listValues = {
  timeWindow: [
    {
      label: '5', value: 5
    },
    {
      label: '10', value: 10
    },
    {
    label: '15', value: 15
    },
    {
      label: '20', value: 20
    },
    {
      label: '30', value: 30
    },
    {
      label: '60', value: 60
    },
  ]
}

const formConfig = {
  sections: [{
    label: 'Section A',
    fields: [
      {
        id: 'timeWindow',
        label: 'On Time Window In Mins (+/-)',
        type: TYPE.STRING,
        ignoreIntl: true,
      }
    ]
  }]
}

const Editor = ({ onChange, options = {}}) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
