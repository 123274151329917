import React from 'react';
import cx from 'classnames';
import { values, isArray } from 'lodash';
import { DynamicFieldFactory } from './DynamicFieldFactory';
import Tabs from '../tabs';

const FieldSetName = ({ name }) => {
  return <div className={cx('fieldSet-name')}>{name}</div>;
};

const FieldSetWrapper = ({ children, fieldSetName }) => {
  const shouldHide = fieldSetName.startsWith('HIDE_ME');
  const goodName = fieldSetName && fieldSetName !== 'unspecified' && !shouldHide;
  return (
    <div className={cx('fieldSet-wrapper', { bordered: goodName })}>
      {goodName && <FieldSetName name={fieldSetName} />}
      {children}
    </div>
  );
};

const splitSectionIntoFieldSets = section => {
  const fieldSets = {};

  section.fields.forEach(({ fieldSet = { name: 'unspecified' }, ...field }) => {
    fieldSets[fieldSet.name] = {
      ...fieldSets[fieldSet.name],
      name: fieldSet.name,
      fields: [...(fieldSets[fieldSet.name] || { fields: [] }).fields, field],
    };
  });

  return values(fieldSets);
};

const DynamicFormSection = ({ section, listValues }) => {
  const fieldSets = React.useMemo(() => splitSectionIntoFieldSets(section), [section]);
  return fieldSets.map(fieldSet => (
    <FieldSetWrapper fieldSetName={fieldSet.name} key={fieldSet.name}>
      <div className={`fieldSet fieldSet_${fieldSet.name} fieldSet-${section.direction ?? 'column'}`}>
        {fieldSet.fields.map(fieldConfig => (
          <DynamicFieldFactory
            key={fieldConfig.label}
            fieldConfig={fieldConfig}
            listValues={listValues[fieldConfig.id]}
          />
        ))}
      </div>
    </FieldSetWrapper>
  ));
};

const TabbedForm = ({ formConfig, listValues }) => {
  return (
    <Tabs>
      {formConfig.sections.map(section => (
        <Tabs.TabPane tab={section.label} key={section.label}>
          <DynamicFormSection section={section} listValues={listValues} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

const SinglePageForm = ({ section, listValues }) => {
  return <DynamicFormSection section={section} listValues={listValues} />;
};

export const FormFactory = ({ formConfig, listValues }) => {
  if (isArray(formConfig)) {
    const section = { name: 'unspecified', fields: formConfig };
    return <SinglePageForm listValues={listValues} section={section} />;
  } else if (formConfig.sections.length > 1) {
    return <TabbedForm listValues={listValues} formConfig={formConfig} />;
  } else if (formConfig.sections.length === 1) {
    return <SinglePageForm listValues={listValues} section={formConfig.sections[0]} />;
  }
  return null;
};
