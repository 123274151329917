import styled from 'styled-components';
import cn from 'classnames';

const Spinner = ({ className, spin, children }) => {
  return spin ? (
    <div className={cn('spinner', className)}>
      <i className="fas fa-spin fa-circle-notch " />
    </div>
  ) : (
    children
  );
};

export default styled(Spinner)`
  &.spinner {
    text-align: center;
    margin-top: 10%;
    .fas {
      font-size: 3em;
      opacity: 0.3;
    }
  }
`;
