import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import { useFormik } from 'formik';
// import { PubSub } from 'pubsub-js';
import { prepareConfig, useDefaultFormValues, useTranslateObject } from '../util';
import prepareValidation from '../validation';

const DynamicFormContext = React.createContext();

export const useDynamicFormContext = () => {
  const context = useContext(DynamicFormContext);
  if (!context) {
    throw new Error(
      `useDynamicFormContext cannot be rendered outside of the DynamicFormContext context provider`
    );
  }
  return context;
};

export const DynamicForm = ({
  namespace,
  children,
  values,
  formConfig = {sections: []},
  onChange = () => {},
  onSubmit = () => {},
  onCancel = () => {},
  onReset = () => {},
  listValues = {},
  initialFocus,
  fieldFocused = () => {},
  enableReinitialize,
  resetOnSubmit = false,
  busyFields = {},
}) => {
  // TODO: Extract prepareConfig
  const translatedFormConfig = useTranslateObject(formConfig, namespace);

  const preparedConfig = useMemo(() => prepareConfig(translatedFormConfig), [translatedFormConfig]);
  // End Prepare Config

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: prepareValidation(preparedConfig),
  });

  useEffect(() => {
    formik.setValues(values);
  }, [values])

  const handleSubmit = useCallback(() => {
    onSubmit(formik.values, formik)?.then?.(data => {
      if (resetOnSubmit) {
        formik.resetForm();
      }
      return data;
    });
  }, [formik, onSubmit, resetOnSubmit]);

  const handleReset = useCallback(() => {
    formik.resetForm();
    onReset();
  }, [formik, onReset]);

  const { defaultForm } = useDefaultFormValues(formConfig);

  const onFieldChange = useCallback(({id, value}) => {
    onChange(id, value)
  }, [onChange])

  const publish = useCallback((eventType, data) => {
    switch(eventType) {
      case DynamicForm.EVENTS.CHANGE:
        onFieldChange(data);
    }
  }, [onFieldChange])

  return !preparedConfig ? null : (
    <DynamicFormContext.Provider
      value={{
        ...formik,
        // ...PubSub,
        formConfig: preparedConfig,
        listValues,
        onSubmit: handleSubmit,
        onCancel,
        publish,
        onReset: handleReset,
        initialFocus,
        defaultForm,
        namespace,
        enableReinitialize,
        busyFields,
        fieldFocused,
      }}
    >
      {children}
    </DynamicFormContext.Provider>
  );
};

DynamicForm.EVENTS = {
  FOCUS: 'focus',
  CHANGE: 'change',
  BLUR: 'blur',
};
