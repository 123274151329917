import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import ProbeVehicles from "./ProbeVehicles";
import useAPI from './useAPI';
import cn from "classnames";

const Component = ({ className }) => {
  const { config, filterValues, parentRef} = useWidgetContext();
  const {
    probeVehicles,
    getProbeVehicles,
  } = useAPI();

  const refresh = useCallback(() => {
    getProbeVehicles(filterValues, 1000);
  }, [filterValues, getProbeVehicles]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <ProbeVehicles
        loading={probeVehicles.loading}
        config={config}
        data={probeVehicles.data}
      />
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  * > .filter-input-wrapper {
    > input {
      background-color: #eee !important;
    }
  }
  &&& {
    > .connex-data-grid, .default-header {
      width: 100%;

      > .filtered-data-grid {
        overflow-x: hidden;
      }
    }
  }

`;





