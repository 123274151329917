import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { get } from 'lodash';
import { Select as AntSelect } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import style from './style';
import Dropdown from './Dropdown';
import useIntlProps from '../../components/i18n/useIntlProps';

const Select = ({
  id,
  name,
  mode,
  valuePath = 'id',
  displayPath = 'description',
  options = [],
  disabled,
  className,
  defaultValue,
  onChange,
  placeholder,
  showArrow,
  style,
  value,
  allowClear,
  error,
  touched,
  onFocus,
  innerRef,
  onBlur,
  loading,
  loadingPlaceholder = 'selectLoadingPlaceholder',
  onInputKeyDown,
  onDropdownVisibleChange,
  dropdownRender,
  open,
  tabIndex,
}) => {
  const { Option } = AntSelect;

  AntSelect.displayName = 'AntSelect';

  const [translatedPlaceholder, translatedLoadingPlaceholder] = useIntlProps(placeholder, loadingPlaceholder);

  return loading ? (
    <div className={cn(className, 'cx-loading-field')}>
      <div className="loading-spinner">
        <LoadingOutlined />
      </div>
      <div className="loading-placeholder">{translatedLoadingPlaceholder}</div>
    </div>
  ) : (
    <>
      <AntSelect
        id={id}
        name={name}
        mode={mode}
        disabled={disabled}
        showSearch
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().replace(/\s/g, '').indexOf(input.toLowerCase().replace(/\s/g, '')) >=
          0
        }
        defaultValue={defaultValue}
        value={value}
        className={cn(className, 'cx-select', { 'cx-select-disabled': disabled })}
        size="medium"
        labelInValue={false}
        onChange={onChange}
        placeholder={translatedPlaceholder}
        style={style || { minWidth: '200px' }}
        allowClear={allowClear}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={innerRef}
        showArrow={showArrow}
        onInputKeyDown={onInputKeyDown}
        onDropdownVisibleChange={onDropdownVisibleChange}
        dropdownRender={dropdownRender}
        open={open}
        tabIndex={tabIndex}
        // dropdownRender={menu => <Dropdown>{menu}</Dropdown>}
        // dropdownStyle={{ backgroundColor: 'var(--select-dropdown-background-color)' }}
      >
        <Option value="">Select...</Option>
        {options?.map(option => {
          const label = []
            .concat(displayPath)
            .map(path => get(option, path))
            .join(' / ');

          return (
            <Option
              key={`${option[valuePath]}__${option[[].concat(displayPath).join('')]}`}
              className="cx-option"
              value={get(option, valuePath)}
            >
              {option.icon && <span className='option-icon'><i className={option.icon} /></span>} {label}
            </Option>
          );
        })}
      </AntSelect>
      {error && touched && <div className="validation-error">{error}</div>}
    </>
  );
};
Select.Option = AntSelect.Option;

Select.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  mode: propTypes.string,
  valuePath: propTypes.string,
  displayPath: propTypes.oneOfType([propTypes.string, propTypes.array]),
  options: propTypes.array,
  disabled: propTypes.bool,
  className: propTypes.string,
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.array]),
  onChange: propTypes.func,
  placeholder: propTypes.string,
  showArrow: propTypes.bool,
  style: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.array]),
  allowClear: propTypes.bool,
  error: propTypes.string,
  touched: propTypes.bool,
  onFocus: propTypes.func,
  onInputKeyDown: propTypes.func,
  innerRef: propTypes.object,
  onBlur: propTypes.func,
  loading: propTypes.bool,
  loadingPlaceholder: propTypes.string,
};

Select.defaultProps = {
  valuePath: 'id',
  displayPath: 'description',
  options: [],
  loadingPlaceholder: 'selectLoadingPlaceholder',
};

export default styled(Select)`
  width: 100%;
  
  .option-icon {
    margin-right: 10px;
  }
`;
