import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import { useWidgetContext } from '../../useWidgetContext';
import style from './style';
import API from '../../../../../api';
import cn from "classnames";
import {useParams} from "react-router-dom";
import {useWorkspacesContext} from "../../../context/useWorkspacesContext";
import PublishWidget from "./publish-widget";
import visualizations from "./visualizations";
import OpenPublishedWidget from "./open-published-widget";
import getParametersFromFilters from "../../../filters/getParametersFromFilters";
import Spinner from "../../../../../components/spinner/Spinner";

const Styled = styled.div`
  ${style}
`

const CustomWidgetComponent = ({ className }) => {
  const {entityRef} = useParams();
  const { config, parentRef, filterValues, setExtraActions } = useWidgetContext();
  const {saveWidget} = useWorkspacesContext();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();

  useEffect(() => {
    const queryRef = config?.options?.queryRef;

    const run = async () => {
      setLoading(true);
      const Parameters = getParametersFromFilters(filterValues);
      const parameters = {
        queryRef,
        visualizationType: config?.options?.visualizationType || 'table',
        Parameters,
      };

      const results = await API.executeWidgetQuery(entityRef, parameters);

      setData(results)
      setLoading(false);
    };

    if(queryRef && entityRef) {
      run().then();
    }
  }, [entityRef, filterValues, config?.options?.queryRef, config?.options?.visualizationType])

  useEffect(() => {
    if(config.typeId === 'published-widget') {
      return;
    }

    setExtraActions([
      {
        Label: 'Publish Widget',
        handler: () => {
          setDrawerContent(<PublishWidget setDrawerVisible={setDrawerVisible} config={config} />);
          setDrawerVisible(true);
        }
      },
      {
        Label: 'Open Published Widget',
        handler: () => {
          setDrawerContent(<OpenPublishedWidget config={config} onOpenPublishedWidget={saveWidget} setDrawerVisible={setDrawerVisible} />);
          setDrawerVisible(true);
        }
      },
    ])
  }, [saveWidget, setDrawerVisible, setDrawerContent, config])

  const Component = useMemo(() => {
    const visualization = visualizations[config?.options?.visualizationType || 'table'];
    return visualization || React.Fragment;
  }, [config])

  return (
    <Styled ref={parentRef} className={cn(className)}>
      <Spinner spin={loading}>
        <Component data={data} />
      </Spinner>
    </Styled>
  );
};

export default CustomWidgetComponent;

