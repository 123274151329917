import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {useParams} from 'react-router';
import WidgetEditor from './WidgetEditor';
import {useWorkspacesContext} from "../context/useWorkspacesContext";

import API from '../../../api';
import {useDashboardContext} from "../dashboard/useDashboardContext";

const {
  executeDataset,
} = API;

const WidgetContext = createContext();
const useWidget = ({ config }) => {
  // may need callbacks to update things at the dashboard level
  // ie: dashboard needs to be saved, widgets on screen, etc.
  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();
  const {filterValues} = useDashboardContext();
  const [extraActions, setExtraActions] = useState([]);

  const showEditor = useCallback(() => {
    setDrawerContent(<WidgetEditor config={config} />);
    setDrawerVisible(true)
  }, [config]);

  return { config, showEditor, filterValues, extraActions, setExtraActions };
};

export const WidgetContextProvider = ({ children, config, key }) => {
  const contextProps = useWidget({ config, key });
  return <WidgetContext.Provider value={{ ...contextProps }}>{children}</WidgetContext.Provider>;
};

export const useWidgetContext = () => {
  const value = useContext(WidgetContext);
  if (!value) {
    throw new Error('Widget Context must be used beneath WidgetContextProvider');
  }
  return value;
};

export default { WidgetContextProvider, useWidgetContext };
