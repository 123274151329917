import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { motion } from 'framer-motion';

const Styled = styled(motion.div)`
  background-color: #0e67c2;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: width 1s linear;
`;

const Progress = ({ durationMs }) => (
  <Styled
    initial={{ width: 0 }}
    animate={{ width: '100%' }}
    transition={{ ease: 'linear', duration: (durationMs - 1000) / 1000 }}
    className={cn('progress')}
  />
);

export default Progress;
