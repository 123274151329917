import React from 'react';
import { SizeMe } from 'react-sizeme';
import { useColumnResize } from './useColumnResize';

const ColumnSizer = ({ children, autoSizeColumns, columns }) => {
  const { userDefinedWidths } = useColumnResize();

  if (!autoSizeColumns) return children;

  return (
    <SizeMe monitorWidth refreshRate={100}>
      {({ size }) => {
        const preassignedWidths = columns.reduce(
          (acc, col) => {
            if (col.width) {
              acc.width += col.width;
              acc.columnCount++;
            }
            return acc;
          },
          { width: 0, columnCount: 0 }
        );

        const autoSizedColumns = columns.map(column => ({
          width: (size.width - 2 - preassignedWidths.width) / (columns.length - preassignedWidths.columnCount),
          ...column,
        }));

        const autoSizedWithUserDefinedWidthsApplied = autoSizedColumns.map(column => {
          return { ...column, width: userDefinedWidths[column.key] || column.width };
        });

        return React.Children.map(children, child => {
          return React.cloneElement(child, { ...child.props, columns: autoSizedWithUserDefinedWidthsApplied });
        });
      }}
    </SizeMe>
  );
};

export default ColumnSizer;
