import React, { useCallback, useEffect } from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import TicketCountByStatusBar from './TicketCountByStatusBar';
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import cn from "classnames";
import styled from "styled-components";

const Component = ({ className }) => {
  const { filterValues, parentRef} = useWidgetContext();
  const {
    totalCountByStatus,
    getTotalCountByStatus,
  } = useAPI();

  const refresh = useCallback(() => {
    getTotalCountByStatus(filterValues);
  }, [filterValues, getTotalCountByStatus]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={totalCountByStatus.loading}>
        <TicketCountByStatusBar
          data={totalCountByStatus.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;