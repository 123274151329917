import Editor from './Editor';
import Component from './Component';

const TicketsExpectingUnloadingEventsSummary = {
  Editor,
  Component,
  name: 'Tickets Expecting Unloading Events',
  description: 'List of tickets that are expecting unloading events.'
}

export default TicketsExpectingUnloadingEventsSummary;
