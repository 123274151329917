import { find, findIndex, sortBy } from 'lodash';
import React from 'react';
import { setItem, getItem } from '../../../util/cache';
import { getFormatter, getHeaderRenderer } from './defaults';

export const useColumnDef = (gridName, columnDef) => {
  const localStorageKey = `${gridName?.toLowerCase?.()?.replace(/ /g, '-')}-grid-prefs`;

  // Apply defaults to each column.
  const preparedColumnDefs = React.useMemo(() => {
    return columnDef.map(({ filterable, ...col }) => ({
      ...col,
      headerRenderer: col.headerRenderer || getHeaderRenderer(filterable, col),
      formatter: col.formatter || getFormatter(col),
    }));
  }, [columnDef]);

  const [userColumns, setUserColumns] = React.useState(preparedColumnDefs);

  React.useEffect(() => {
    const preferences = JSON.parse(getItem(localStorageKey));

    if (!preferences) {
      setUserColumns(preparedColumnDefs);
      return preparedColumnDefs;
    }

    const preferredVisibilityApplied = preparedColumnDefs.map(columnDef => {
      const userColumn = find(preferences, { key: columnDef.key });
      return { ...columnDef, hidden: userColumn?.hidden ?? columnDef.hidden };
    });

    const preferredSortingApplied = sortBy(preferredVisibilityApplied, col =>
      findIndex(preferences, pref => col.key === pref.key)
    );

    setUserColumns(preferredSortingApplied);
  }, [localStorageKey, preparedColumnDefs]);

  const saveColumnPreferences = React.useCallback(
    cols => {
      setItem(localStorageKey, JSON.stringify(cols));

      const mergedColumns = cols.map(column => {
        const originalColumn = find(preparedColumnDefs, { key: column.key });
        return { ...originalColumn, hidden: column.hidden };
      });

      setUserColumns(mergedColumns);
    },
    [localStorageKey, preparedColumnDefs]
  );

  const visibleColumns = React.useMemo(() => {
    return userColumns.filter(c => !c.hidden);
  }, [userColumns]);

  const result = { visibleColumns, userColumns, saveColumnPreferences, columnDef };

  return result;
};
