const getStartDate = filterValues => {
  const { quickDate, startDateTime } = filterValues;

  switch (quickDate) {
    case 'Today':
      return 'today';
    case 'Yesterday':
      return 'yesterday';
    case 'This Month':
      return 'currentMonth';
  }

  return startDateTime
}

export default getStartDate;
