import style from './style';
import styled from 'styled-components';
import {useEffect, useState} from "react";
import {Input, Button} from 'antd';


const Styled = styled.div`
  ${style}
`;

const EditableInput = ({value, readOnly, onChange}) => {
  const [editingValue, setEditingValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setEditingValue(value)
  }, [value])

  useEffect(() => {
    if(readOnly) {
      setEditingValue(value);
      setIsEditing(false);
    }
  }, [readOnly])

  return <Styled>
    {
      isEditing && <Input.Group compact>
        <Input onKeyDown={e => e.stopPropagation()} defaultValue={value} onChange={e => {
          setEditingValue(e.target.value)
        }} />
        <Button icon={<span className={'fa fa-times'} />} onClick={() => {
          setIsEditing(false);
          setEditingValue(value);
        }} />
        <Button icon={<span className={'fa fa-check'} />} onClick={() => {
          onChange(editingValue)
          setIsEditing(false);
        }} />
      </Input.Group>}

    {!isEditing && <div onClick={() => !readOnly && setIsEditing(true)}>
      {editingValue}
    </div>}
  </Styled>
};

export default EditableInput;