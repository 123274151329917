import {css} from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  * > .filter-input-wrapper {
    > input {
      background-color: #eee !important;
    }
  }
  &&& {
    > .connex-data-grid, .default-header {
      width: 100%;

      > .filtered-data-grid {
        overflow-x: hidden;
      }
    }
  }
  
`;