import * as yup from 'yup';
import { TYPE } from '../index';
import { flattenConfig } from '../util';

const prepareValidation = formConfig => {
  /*
    {
      id: 'firstName',
      ...
      type: TYPE.STRING,
      validation: {
        firstName: {
          required: ['validation.first.name.required'],
          min: [2, 'validation.first.name.minLength'],
          max: [10, 'validation.first.name.maxLength'],
      }
    }

    Takes the above and invokes:

      yup.object().shape({
        firstName: yup.string()
          .required('validation.first.name.required')
          .min(2, 'validation.first.name.minLength')
          .max(10, 'validation.first.name.maxLength')
      })
 */

  const flattenedConfig = flattenConfig(formConfig);

  const getShape = config =>
    config.reduce((acc, field) => {
      let initialSchema = yup[field.type]();

      if (field.type === TYPE.ARRAY && field.arrayOf) {
        initialSchema = initialSchema.of(prepareValidation(field.arrayOf));
      }

      let schema;

      if (field.validation) {
        schema = Object.keys(field.validation).reduce((fieldAcc, validationType) => {
          fieldAcc = fieldAcc[validationType](...field.validation[validationType]);
          return fieldAcc;
        }, initialSchema);
      }

      acc[field.id] = schema;

      return acc;
    }, {});

  return yup.object().shape(getShape(flattenedConfig));
};

export default prepareValidation;
