import React from 'react';
import { useWidgetContext } from '../../useWidgetContext';

const Component = () => {
  const { config, } = useWidgetContext();

  const iframeUrl = config?.options?.iframeUrl;

  let queryString = '';

  if(config?.options?.useConnexAuthentication === true) {
    queryString = `?source=${localStorage.getItem('source') || 'connex'}&x-connex-id=${localStorage.getItem('x-connex-id')}`;
  }

  return (
    <div style={{width:'100%', height: '100%'}}>
      {!iframeUrl && 'IFrame url not configured.'}
      {iframeUrl && <iframe style={{width: '100%', height: '100%', border: '0'}} src={`${iframeUrl}${queryString}`}></iframe>}
    </div>
  );
};

export default Component;
