import { css } from 'styled-components';

export default css`
  font-size: ${({ theme }) => `${theme.resolvedFontSize}px`};
  color: var(--label-color);
  transition: all 0.3s;
  .required-indicator {
    color: red;
    margin-left: 5px;
  }
`;
