import { useIntl } from 'react-intl';
import { isArray } from 'lodash';
import { useMemo } from 'react';

const translateFields = (originalFields, formatMessage) => {
  const fields = [...originalFields];

  fields.forEach(field => {
    // arrayOf contains an separate schema that defines the elements of the array.
    // recursively translate those messages also.
    if (field.arrayOf) {
      field.arrayOf = translateFields(field.arrayOf, formatMessage);
    }

    // For each field, see if it has a validation key.

    if (field.validation) {
      Object.keys(field.validation).forEach(validationKey => {
        /*
            Iterate over each validation type.  e.g. required, min, max...
           */
        const validation = field.validation[validationKey];

        /*
           Each validation is an array of arguments, one of which might
           be an object representing the message to display if that validation fails.
           When it exists, it will be the last element of the array.
           */
        if (isArray(validation)) {
          const lastElementIndex = validation.length - 1;
          let lastElement = validation[lastElementIndex];

          // We're looking for an object with an 'id'.
          if (lastElement.id) {
            let values = {};

            /*
               Build a values object that contains the other validation parameters.

               In the case of:
                  min: [2, { id: 'validation.first.name.minLength' }]

               values will equal {arg1: 2}  (Note: not zero-based)

               This permits a translation with placeholders.  (e.g. 'Minimum length is {arg1}')
               */

            for (const [index, arg] of validation.entries()) {
              if (index !== lastElementIndex) values[`arg${index + 1}`] = String(arg);
            }

            /*
               Finally, replace the object with the translated string.

               e.g. min: [2, { id: 'validation.first.name.minLength' }]
                    becomes
                    min: [2, 'Minimum length is 2']
               */

            /*
            field.validation[validationKey][lastElementIndex] = (
              <Text text={lastElement.id} values={values} styled={false} />
            );
             */
            field.validation[validationKey][lastElementIndex] = formatMessage(lastElement, values);
          }
        }
      });
    }
  });
  return fields;
};

export const useTranslateObject = formConfig => {
  /* Example formConfig:

    {
    sections: [
      {
        label: 'Section A',
        fields: [
          {
            id: 'firstName',
            label: 'form.name.first',
            type: TYPE.STRING,
            validation: {
                required: [{ id: 'validation.first.name.required' }],
                min: [2, { id: 'validation.first.name.minLength' }],
                max: [10, { id: 'validation.first.name.maxLength' }],
              },
            },
          },
          { id: 'lastName', label: 'form.name.last', type: TYPE.STRING },
          { id: 'thing', label: 'form.thing', type: TYPE.STRING },
        ],
      },
      ...


      an alternative format is just an array of fields:
      [
          {
            id: 'firstName',
            label: 'form.name.first',
            type: TYPE.STRING,
            validation: {
              required: [{ id: 'validation.first.name.required' }],
              min: [2, { id: 'validation.first.name.minLength' }],
              max: [10, { id: 'validation.first.name.maxLength' }],
            },
          },
          { id: 'lastName', label: 'form.name.last', type: TYPE.STRING },
          { id: 'thing', label: 'form.thing', type: TYPE.STRING },
        ]

  */
  const { formatMessage } = useIntl();

  const translatedFields = useMemo(() => {
    let translated = isArray(formConfig) ? [...formConfig] : { ...formConfig };

    if (isArray(translated)) {
      translated = translateFields(translated, formatMessage);
    } else {
      if (translated.sections) {
        translated.sections.forEach(section => {
          section.fields = translateFields(section.fields, formatMessage);
        });
      }
    }
    return translated;
  }, [formConfig, formatMessage]);

  return translatedFields;
};
