import React from 'react';
import Button from '../../i18n-button';
import { useDynamicFormContext } from './DynamicFormContext';

export const SubmitButton = ({ label, loading, metricId }) => {
  const { onSubmit, isValid } = useDynamicFormContext();

  return <Button label={label} onClick={onSubmit} metricId={metricId} disabled={!isValid} loading={loading} />;
};

export const useSubmitButtonProps = label => {
  const { onSubmit, isValid, dirty } = useDynamicFormContext();
  return { label, onClick: onSubmit, disabled: !isValid };
};

export const CancelButton = ({ label, metricId }) => {
  const { onCancel } = useDynamicFormContext();
  return <Button label={label} onClick={onCancel} metricId={metricId} />;
};

export const ResetButton = ({ label }) => {
  const { onReset } = useDynamicFormContext();
  return <Button label={label} onClick={onReset} />;
};

export const useResetButtonProps = label => {
  const { onReset, dirty } = useDynamicFormContext();
  return { label, onClick: onReset, disabled: !dirty };
};
