import React from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import styled from 'styled-components';


const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const columns = [
  { key: 'entity', name: 'Entity' },
  { key: 'featureId', name: 'Feature ID' },
  { key: 'friendlyFeatureName', name: 'Feature' },
];

const EntityFeatures = ({ data, loading, config }) => {


  return (
    <Styled>
      <ConnexDataGrid
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        autoSizeColumns
      />
    </Styled>
  );
};

export default EntityFeatures;
