const { REACT_APP_ENDPOINT = 'https://api.commandalkon.io' } = process.env;

const hostname = () => {
  const { hostname } = window.location;

  if (hostname.includes('localhost')) {
    return 'http://localhost:8001'
  }

  if (hostname.includes('model')) {
    return 'https://connex-model-api.commandalkon.io'
  }

  if (hostname.includes('workspaces-develop')) {
    return 'https://api-stage.commandalkon.io'
  }

  return REACT_APP_ENDPOINT
}

export default hostname;
