import React, { useMemo } from 'react';
import _ from 'lodash';
import { trimName } from '../../../../../components/charts/util';
import BarChart from '../../../../../components/charts/BarChart';

const TotalQuantitesBySupplier = ({ data, config, onClick, limit = 35 }) => {
  const fieldName = 'supplierParty_name_F';
  let sortedData = [];

  const reducedData = useMemo(() => {
    return data?.reduce((acc, item) => {
      const supplierName = trimName(item[fieldName], 'No Plant');
      const newItem = {};
      const type = trimName(item.type, 'NONE');
      const predicate = {};
      _.set(predicate, fieldName, supplierName);
      const indexOfExistingItemInResult = _.findIndex(acc, predicate);
      let displayLabelText = supplierName;

      if (indexOfExistingItemInResult === -1) {
        if (config) {
          displayLabelText = `
        ${config.showSupplierName ? supplierName : ''}        
        ${config.showQuantity ? ` : ${item.val}` : ''} 
        `;
        }
        _.set(newItem, fieldName, supplierName);
        _.set(newItem, 'val', item.val);
        _.set(newItem, 'name', displayLabelText);
        _.set(newItem, 'type', type);
        _.set(newItem, type, item.val);
        _.set(newItem, 'qty_sum', item.val);
        acc.push(newItem);
      } else {
        if (acc[indexOfExistingItemInResult][type] > 0) {
          _.set(acc[indexOfExistingItemInResult], type, +acc[indexOfExistingItemInResult][type] + +item.val);
        } else {
          _.set(acc[indexOfExistingItemInResult], type, item.val);
        }
        _.set(acc[indexOfExistingItemInResult], 'qty_sum', +acc[indexOfExistingItemInResult].qty_sum + +item.val);
        if (config) {
          displayLabelText = `
        ${config.showSupplierName ? supplierName : ''}
        ${config.productLabelShowQuantity ? ` : ${acc[indexOfExistingItemInResult].qty_sum}` : ''} 
       `;
        }
        _.set(acc[indexOfExistingItemInResult], 'name', displayLabelText);
      }
      return acc;
    }, []);
  }, [data, config]);

  if (reducedData) {
    sortedData = _.orderBy(reducedData, ['val'], ['desc']);
  }

  return (
    <BarChart
      fieldName={fieldName}
      onClick={onClick}
      showXAxis={config?.displayXAxis || false}
      xAxisLabel={false}
      yAxis={false}
      data={sortedData.slice(0, limit)}
      xValue={'qty_sum'}
      xValueType={'number'}
      layout={'vertical'}
      yAxisWidth={500}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
    />
  );
};

export default TotalQuantitesBySupplier;
