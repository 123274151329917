import cn from 'classnames';
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import styled from 'styled-components';
import ColumnSizer from '../../ColumnSizer';
import { useGridContext } from '../../context/context';
import { useColumnResize } from '../../useColumnResize';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const Grid = () => {
  const { filterEnabled, visibleColumns, rowClickable, autoSizeColumns, reactDataGridProps } = useGridContext();
  const { handleColumnResize } = useColumnResize();

  return (
    <Styled className={cn('connex-data-grid')} filterEnabled={filterEnabled} rowClickable={rowClickable}>
      <ColumnSizer autoSizeColumns={autoSizeColumns} columns={visibleColumns}>
        <ReactDataGrid {...reactDataGridProps} onColumnResize={handleColumnResize} />
      </ColumnSizer>
    </Styled>
  );
};

export default Grid;
