import { Form } from './Form';
import styled from 'styled-components';

export default styled(Form)`
  .fieldSet-wrapper {
    &.bordered {
      border: 1px solid #ccc;
    }
    margin: 5px 0;
    .fieldSet-name {
      font-weight: bold;
      margin: 5px 0 0 5px;
    }
    .fieldSet {
      display: flex;
      flex: 1;
      padding: 0 10px 10px 10px;
    }

    .fieldSet-column {
      flex-direction: column;
    }

    .fieldSet-row {
      flex-direction: row;
    }
    .form-group {
      margin: 0 5px 0 5px;
      width: 100%;
    }
    .hidden {
      display: none;
    }
  }
`;
