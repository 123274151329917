import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const InputNumber = ({ children, className, min, ...props }) => {
  return (
    <div className={cn('number-input', className)}>
      <input type="number" {...props} min={min || 0} />
    </div>
  );
};

export default styled(InputNumber)`
  &&& {
    input {
      width: 100%;
      height: 32px;
      color: #fff;
      background-color: transparent;
      border: 1px solid #434343;
      padding-left: 5px;
      font-size: .9rem;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */

      input[type='number'] {
        -moz-appearance: textfield;
      }

      &:focus-visible {
        outline: none;
      }

      &:disabled {
        color: hsla(0, 0%, 100%, 0.85) !important;
      }
    }
  }
`;
