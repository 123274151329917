import { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import style from './style';
import Progress from './components/Progress';
import cn from 'classnames';

const Style = styled.div`
  ${style}
`;

const RefreshButton = ({ autoRefresh = 30000, onClick }) => {
  const [refreshInProgress, setRefreshInProgress] = useState(true);

  const timeoutRef = useRef(null);

  const setTimeoutRef = value => {
    timeoutRef.current = value;
  };
  const clearTimeoutRef = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);

  const initiateAutoRefresh = useCallback(() => {
    clearTimeoutRef();
    setTimeoutRef(
      setTimeout(() => {
        onClick();
        setRefreshInProgress(false);
      }, autoRefresh)
    );
  }, [autoRefresh, setRefreshInProgress, clearTimeoutRef, onClick]);

  useEffect(() => {
    if (!refreshInProgress) {
      setRefreshInProgress(true);
      initiateAutoRefresh();
    }
  }, [refreshInProgress, initiateAutoRefresh]);

  useEffect(() => {
    initiateAutoRefresh();
  }, [initiateAutoRefresh]);

  useEffect(() => {
    return () => {
      clearTimeoutRef();
    };
  }, [clearTimeoutRef]);

  const handleClick = useCallback(() => {
    onClick();
    setRefreshInProgress(false);
  }, [setRefreshInProgress, onClick]);

  return (
    <Style>
      <div data-testid="button-refresh" className="button-content" onClick={handleClick}>
        {refreshInProgress ? <Progress durationMs={autoRefresh} /> : null}
        <div className="icon-label x">
          <i className={cn('icon fa fa-sync', { 'fa-spin': !refreshInProgress })} />
          <div className="label">Refresh</div>
        </div>
      </div>
    </Style>
  );
};

export default RefreshButton;
