import React, { useContext } from 'react';

const UserContext = React.createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext cannot be rendered outside of the UserContext context provider`);
  }
  return context;
};

const UserContextProvider = ({ children, user, entity }) => {
  return <UserContext.Provider value={{...user, entity}}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
