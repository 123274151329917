import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthCheck from './AuthCheck';
import { Internationalization } from './i18n';
import Welcome from './Welcome';

function App() {
  return (
    <BrowserRouter>
      <Internationalization>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/:entityRef/*" element={<AuthCheck />} />
        </Routes>
      </Internationalization>
    </BrowserRouter>
  );
}

export default App;
