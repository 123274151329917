import React from "react";
import cn from 'classnames';
import style from './style';
import styled from 'styled-components';
import FieldContainer from "../../../../../../../../components/field-container/FieldContainer";
import Input from "../../../../../../../../components/form/Input";

const View = styled(FieldContainer)`${style}`;

const InputText = ({ className, id, label, value, onChange, disabled = false, innerRef }) => {
  return (
    <View className={cn(id, className)}>
      <div className="label-spinner">
        <div className="label">{label}</div>
      </div>
      <Input type="text"
             value={value || ''}
             disabled={disabled}
             data-testid={id}
             innerRef={innerRef}
             onChange={e => onChange ? onChange(id, e.target?.value) : console.log(id, e.target?.value)}
      />
    </View>
  );
};

export default InputText;
