import queryString from 'query-string';
import React from 'react';
import { useParams } from 'react-router';
import { useAppContext } from '../../../AppContext';
import Button from '../../../components/button/Button';
// import { Translate } from '../../../order/components/localization/Translate';

export const useDashboardPreview = () => {
  const { v4Tokens } = useAppContext();
  const { entityRef } = useParams();

  const isPreviewButtonVisible = React.useMemo(() => {
    return !!v4Tokens?.lt && !!v4Tokens?.aet;
  }, [v4Tokens]);

  const handlePreviewClick = React.useCallback(() => {
    const getHostname = () => {
      const currentHostname = window.location.hostname;
      if (currentHostname === 'connex.commandalkon.io') {
        return 'connex.us.commandalkon.io';
      }
      return 'connex.develop.commandalkon.io';
    };

    const queryParams = queryString.stringify({ token: v4Tokens.lt, aet: v4Tokens.aet })

    const url = `https://${getHostname()}/app/dashboards-exp/${entityRef}/${queryParams ? `?${queryParams}` : ''}`;

    window.open(url, '_blank');
  }, [entityRef, v4Tokens.lt, v4Tokens.aet]);

  return React.useMemo(() => {
    return () => {
      if (!isPreviewButtonVisible) {
        return null;
      }
      return (
        <Button type="primary" onClick={handlePreviewClick}>
          Preview in Dashboards 2.0
        </Button>
      );
    };
  }, [handlePreviewClick, isPreviewButtonVisible]);
};