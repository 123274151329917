import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import { useWidgetContext } from '../../useWidgetContext';

export const useEntityMetrics = () => {
  const { appId } = useParams();
  const { filterValues } = useWidgetContext();
  const [probeEvents, setProbeEvents] = useState({ loading: false, data: [] });

  const getProbeEvents = useCallback(
    (entityRef, vehicleId) => {
        const params = {
            "Parameters": [
                {
                    "Value": entityRef,
                    "Type": "string",
                    "Name": "EntityRef"
                },
                {
                    "Value": vehicleId,
                    "Type": "string",
                    "Name": "VehicleID"
                },
                {
                    "Value": filterValues.startDateTime,
                    "Type": "string",
                    "Name": "StartDt"
                },
                {
                    "Value": filterValues.endDateTime,
                    "Type": "string",
                    "Name": "EndDt"
                },
            ]
        };
      setProbeEvents(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Probe Event History By Vehicle', params).then(rs => {
        setProbeEvents({ data: rs && rs.items, loading: false });
      });
    },
    [appId, filterValues]
  );

  return {
    probeEvents,
    getProbeEvents,
  };
};

export default useEntityMetrics;
