import React, { useRef, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';

export const ModalContext = React.createContext();

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext cannot be rendered outside of the ModalContext context provider`);
  }
  return context;
};

export const ModalContextProvider = ({ children }) => {
  const Component = useRef(<div>Modal</div>);

  const [title, setTitle] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const openModal = useCallback((currentTitle, component) => {
    Component.current = component;
    setTitle(currentTitle);
    setIsOpen(true);
  }, []);

  return (
    <ModalContext.Provider value={{ closeModal, openModal }}>
      <>
        {children}
        <Modal title={title} visible={isOpen} footer={null}>
          {Component.current}
        </Modal>
      </>
    </ModalContext.Provider>
  );
};
