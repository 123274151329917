import { css } from 'styled-components';

export default css`
  &&& {
    height: 100%;
    display: flex;
    flex-direction: column;

    .grid-row-summary {
      --background-color: #21487c;
    }

    .rdg {
      flex: 1;
      --background-color: #000;
      --header-background-color: #21487c;
      --border-color: #434343;
      --color: #eee;
      --row-hover-background-color: #333;
      --selection-color: transparent;
      overflow-y: scroll;
      .rdg-group-row {
       --header-background-color: #222;
      }

      .rdg-row {
        cursor: ${({ rowClickable }) => (rowClickable ? 'pointer' : 'default')};
      }

      .rdg-header-row {
        transition: height 0.3s;
        .rdg-cell {
          display: flex;
          .header-cell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            cursor: pointer;
          }
        }
        .filter-input-wrapper {
          opacity: ${({ filterEnabled }) => (filterEnabled ? 1 : 0)};
          transition: opacity 0.3s;
        }
      }

      .rdg-cell {
        transition: height 0.3s;
        padding: 0 5px;
        .cell {
        }
        &:focus {
          outline: none;
        }
      }

      &.filtered-data-grid {
        .rdg-cell {
          line-height: 30px;
          padding: 0 5px;
        }
      }

      .rdg-header-sort-cell {
        display: flex;
        align-items: center;
      }
    }
  }
`;
