import React, {useCallback, useEffect, useState} from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import styled from 'styled-components';
import useAPI from "./useAPI";
import {useParams} from "react-router-dom";

const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const getURLPrefix = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost')) {
    return 'http://localhost:3000';
  }
  if (hostname.includes('develop')) {
    return 'https://connex-stage.commandalkon.io';
  }
  if(hostname.includes('model')) {
    return 'https://connex-model.commandalkon.io';
  }
  return 'https://connex.commandalkon.io';
}

const ticketNameFormatter = (data) => {
  const row = data.row;
  const {entityRef} = useParams();
  const urlPrefix = getURLPrefix();
  return <a href={`${urlPrefix}/#/apps/connex/${entityRef}/tickets/view-shipments/${row.ticketID}`} target="_blank">{row.ticketName}</a>;
}

const driverFormatter = (data) => {
  const driver = data?.row?.driver?.driver;
  return <span>{driver?.firstName} {driver?.lastName} {driver?.id && <span>(driver.id)</span>}</span>;
}

const columns = [
  { key: 'ticketName', name: 'Ticket Name', formatter: ticketNameFormatter },
  { key: 'productType', name: 'Product Type' },
  { key: 'driver', name: 'Driver', formatter: driverFormatter },
  { key: 'status', name: 'Status' },
  { key: 'dispatchDate', name: 'Dispatch Date' },
];

const TicketList = ({ filterValues, config }) => {
  const [loading, setLoading] = useState(false);
  const [ticketsInProgressCount, setTicketsInProgressCount] = useState(0);
  const {
    ticketCount,
    ticketsInProgress,
    getTicketCount,
    getTicketsInProgress,
  } = useAPI();

  const refresh = useCallback(() => {
    setLoading(true);
    getTicketCount(filterValues, 1000);
    getTicketsInProgress(filterValues, 1000);
    setLoading(false);
  }, [filterValues, getTicketCount, getTicketsInProgress]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setTicketsInProgressCount(ticketsInProgress?.data.length || 0);
  }, [ticketsInProgress]);

  return (
    <Styled>
      {ticketsInProgressCount > 0 && ticketCount.data > 0 && <h1>Tickets Expecting Unloading Events: {ticketsInProgressCount} / {ticketCount.data}  ({Math.round(ticketsInProgressCount / ticketCount.data * 10000) / 100}%)</h1>}
      <ConnexDataGrid
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(ticketsInProgress?.data) ? ticketsInProgress?.data : []}
        rowKeyPath="crn"
        onRowClick={(row) => console.log("SELECTED ROW:", row)}
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default TicketList;
