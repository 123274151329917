import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'First Load On Time',
  description: 'Displays percentage of tickets where the first load was on time (default time window is 15 min).'
}

export default DisplayOptions;