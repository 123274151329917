import {css} from 'styled-components';

export default css`
  width: 100%;
  
  .label {
    //margin-bottom: 3px;
  }
  
  .ant-input-group-addon {
    width: 50px;
  }
`;
