import React, { useMemo } from 'react';
import _ from 'lodash';
import { trimName } from '../../../../../components/charts/util';
import BarChart from '../../../../../components/charts/BarChart';
import getUomAbbreviationOrDescription from '../../../../../util/uom';

const TotalQuantitiesByProduct = ({ data, config, onClick, limit = 35 }) => {
  const fieldName = 'productId';

  let sortedData = useMemo(() => {
    const reducedData = data?.reduce((acc, item) => {
      const itemName = trimName(item.productDescription, 'No Product');
      const newItem = {};
      const type = item.productType || 'Unknown';
      const predicate = {};
      _.set(predicate, fieldName, item.productId);
      const indexOfExistingItemInResult = _.findIndex(acc, predicate);
      const itemUOM = getUomAbbreviationOrDescription(item.UOM);
      let displayLabelText = `${itemName} ${itemUOM}`;
      if (config) {
        displayLabelText = `
        ${config.productLabelShowId ? item.productId : ''}
        ${config.productLabelShowId && config.productLabelShowDescription ? '/' : ''}
        ${config.productLabelShowDescription ? itemName : ''}
        ${config.productLabelShowDescription && config.productLabelShowQuantity ? ':' : ''} 
        ${config.productLabelShowQuantity ? item.qty_sum : ''} 
        ${config.productLabelShoUOM ? itemUOM : ''}`;
      }

      if (indexOfExistingItemInResult === -1) {
        _.set(newItem, fieldName, itemName);
        _.set(newItem, 'qty_sum', item.qty_sum);
        _.set(newItem, 'name', displayLabelText);
        _.set(newItem, 'type', type);
        _.set(newItem, 'unit', itemUOM);
        _.set(newItem, type, item.qty_sum);
        acc.push(newItem);
      } else {
        _.set(acc[indexOfExistingItemInResult], type, item.val);
      }
      return acc;
    }, []);

    if (!reducedData) {
      return [];
    }

    return _.orderBy(reducedData, ['qty_sum'], ['desc']);
  }, [config, data]);

  return (
    <BarChart
      fieldName={fieldName}
      onClick={onClick}
      showXAxis={config?.displayXAxis || false}
      xAxisLabel={false}
      yAxis={false}
      data={sortedData.slice(0, limit)}
      xValue={'qty_sum'}
      xValueType={'number'}
      layout={'vertical'}
      yAxisWidth={500}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
    />
  );
};

export default TotalQuantitiesByProduct;
