import React from 'react';
import styled from 'styled-components';

const InvalidUser = ({ className }) => {
  return (
    <div className={className}>
      <div className="message">CONNEX Workspace: Invalid user.</div>
    </div>
  );
};

export default styled(InvalidUser)`
  background-color: #000;
  height: 100vh;
  color: #ccc;
  padding: 10px;
  font-family: Serif;
  .message {
    font-size: 2em;
  }

  .usage {
  }
`;
