import styled from 'styled-components';
import style from './style';
import {useWorkspacesContext} from "../../context/useWorkspacesContext";
import Button from "../../../../components/button/Button";
import React from 'react';
import API from '../../../../api';
const Styled = styled.div`${style}`



const {
  hideDashboard,
  refreshDashboards,
} = API;

const RemoveDashboard = ({dashboard}) => {
  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();

  return <Styled>
    <span>
      Are you sure you want to remove this dashboard from your list?
    </span>

    <div className={'remove-button-group'}>
      <Button metricId={'workspaces-remove-dashboard-cancel'} onClick={async () => {
        setDrawerVisible(false);
        setDrawerContent(null);
      }}>
        Cancel
      </Button>
      <Button metricId={'workspaces-remove-dashboard-submit'} onClick={async () => {
        hideDashboard(dashboard);
        setDrawerVisible(false);
        setDrawerContent(null);
        refreshDashboards();
      }}>
        Remove
      </Button>
    </div>
  </Styled>
}

export default RemoveDashboard;