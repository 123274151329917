import React from 'react';

import DynamicArray from './DynamicArray';
import DynamicCheckbox from './DynamicCheckbox';
import DynamicCurrency from './DynamicCurrency';
import DynamicCustom from './DynamicCustom';
import DynamicDatePicker from './DynamicDatePicker';
import DynamicDateRangePicker from './DynamicDateRangePicker';
import DynamicInput from './DynamicInput';
import DynamicSelect from './DynamicSelect';
import DynamicTimeZone from './DynamicTimeZone';

export const Array = DynamicArray;
export const Checkbox = DynamicCheckbox;
export const Custom = DynamicCustom;
export const Currency = DynamicCurrency;
export const Date = DynamicDatePicker;
export const DateRange = DynamicDateRangePicker;
export const Input = DynamicInput;
export const Select = DynamicSelect;

export const COMPONENTS = {
  ARRAY: <DynamicArray />,
  CHECKBOX: <DynamicCheckbox />,
  CURRENCY: <DynamicCurrency />,
  DATE: <DynamicDatePicker />,
  DATE_RANGE: <DynamicDateRangePicker />,
  INPUT: <DynamicInput />,
  SELECT: <DynamicSelect />,
  TIME_ZONE: <DynamicTimeZone />,
};
