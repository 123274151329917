import React, { useCallback } from 'react';
import { Space } from 'antd';
import { Form, DynamicForm, TYPE } from '../../../../../components/dynamic-form';

const listValues = {
  selectedType: [
    {
      label: 'AGGREGATE',
      value: 'AGGREGATE',
    },
    {
      label: 'CONCRETE',
      value: 'CONCRETE',
    },
    {
      label: 'ASPHALT',
      value: 'ASPHALT',
    },
  ],
};

const formConfig = {
  sections: [
    {
      label: 'Section A',
      fields: [
        {
          id: 'selectedType',
          label: `Select Product Category`,
          type: TYPE.STRING,
          ignoreIntl: true,
        },
      ],
    },
  ],
};

const Editor = ({ onChange, options = { selectedType: 'CONCRETE' }, widget }) => {
  const onConfigChange = useCallback(
    (field, value) => {
      const newConfig = { ...options };
      newConfig[field] = value;
      onChange(newConfig);
    },
    [options, onChange]
  );

  return (
    <Space direction={'vertical'}>
      <DynamicForm values={options} onChange={onConfigChange} formConfig={formConfig} listValues={listValues}>
        <Form />
      </DynamicForm>
    </Space>
  );
};

export default Editor;
