import { css } from 'styled-components';

export default css`
  border-color: var(--input-border-color);
  background-color: var(--input-background-color);
  INPUT {
    color: var(--input-color);
  }
  SPAN {
    color: var(--input-color);
    background-color: var(--input-background-color);
  }
`;
