import React, { useContext } from 'react';

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext cannot be rendered outside of the AppContext context provider`);
  }
  return context;
};

export const AppContextProvider = ({ data, children }) => {
  return <AppContext.Provider value={{ ...data }}>{children}</AppContext.Provider>;
};
