import {DateTime} from 'luxon';

export const rangeOptions = [
  'Today',
  'Yesterday',
  'Tomorrow',
  'This Week',
  'Last 7 days',
  'Last 30 days',
  'This Month',
  'Last Month',
  'Last 3 Months',
  'Last 6 Months',
  'Last 12 Months',
  'This Year',
  'Last Year',
  'Custom Range',
];

const now = (timeZone) => {
  let result = DateTime.now();
  if(timeZone) {
    result = result.setZone(timeZone);
  }
  return result;
}

export const formatToString = dateTime => {
  return dateTime.toUTC().toISO();
};
export const getToday = (timeZone, inclusive = false) => {
  if(inclusive) {
    return now().endOf('day');
  }
  return now().startOf('day');
};
const getLastXDays = (timeZone, numDays) => {
  const start = now().minus({days: numDays}).startOf('day');
  const end = getToday(true);
  return { startDate: start, endDate: end };
};

const getLastXMonths = (timeZone, numMonths, upToToday) => {
  const retObj = { startDate: null, endDate: null };
  retObj.startDate = now().minus({months: numMonths}).startOf('month');
  if (upToToday) {
    retObj.endDate = getToday(true);
  } else {
    retObj.endDate = now().minus({months: numMonths}).endOf('month');
  }
  return retObj;
};

export const getDateRange = (option, timeZone) => {
  let retObj = { startDate: getToday(timeZone), endDate: getToday(true), option };
  switch (option) {
    case 'Yesterday':
      const yesterday = getToday(timeZone).minus({days: 1})
      retObj.startDate = yesterday;
      retObj.endDate = yesterday.endOf('day');
      break;
    case 'Tomorrow':
      const tomorrow = getToday(timeZone).plus({days: 1});
      retObj.startDate = tomorrow;
      retObj.endDate = tomorrow.endOf('day');
      break;
    case 'This Week':
      const today = getToday(timeZone);
      // isoWeek starts on Monday
      retObj.startDate = now().startOf('week');
      retObj.endDate = now().endOf('week');
      break;
    case 'Last 7 days':
      retObj = getLastXDays(timeZone, 6);
      break;
    case 'Last 30 days':
      retObj = getLastXDays(timeZone, 29);
      break;
    case 'This Month':
      retObj = getLastXMonths(timeZone, 0, false);
      break;
    case 'Last Month':
      retObj = getLastXMonths(timeZone, 1, false);
      break;
    case 'Last 3 Months':
      retObj = getLastXMonths(timeZone, 2, true);
      break;
    case 'Last 6 Months':
      retObj = getLastXMonths(timeZone, 5, true);
      break;
    case 'Last 12 Months':
      retObj = getLastXMonths(timeZone, 11, true);
      break;
    case 'This Year':
      retObj.startDate = now().startOf('year');
      retObj.endDate = getToday(timeZone, true);
      break;
    case 'Last Year':
      retObj.startDate = now().minus({year: 1}).startOf('year');
      retObj.endDate = now().minus({year: 1}).endOf('year');
      break;
    default:
      retObj.startDate = getToday(timeZone);
      retObj.endDate = getToday(timeZone, true);
  }

  retObj.startDate = formatToString(retObj.startDate);
  retObj.endDate = formatToString(retObj.endDate);

  return retObj;
};
