import React from 'react';
import cx from 'classnames';
import Input from '../../input';
import { useDynamicFormContext } from '../context/DynamicFormContext';
import { isRequired } from '../util';

// TODO: impl placeholder text translation.

const DynamicInput = ({ config, ...otherProps }) => {
  const { touched, errors, isValid } = useDynamicFormContext();

  const hasError = React.useMemo(() => errors[config.id] && touched[config.id], [
    errors,
    config.id,
    touched,
  ]);

  return (
    <div className="cx-dynamic-field">
      <Input
        name={config.id}
        {...otherProps}
        className={cx('cx-dynamic-input', {
          'has-error': hasError,
          required: isRequired(config),
          valid: isValid,
        })}
        // error={errors[config.id]}
        // touched={touched[config.id]}
        password={config.password}
      />
      {hasError && <div className="validation-error">{errors[config.id]}</div>}
    </div>
  );
};

export default DynamicInput;
