import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Delivered Quantity',
  description: 'Delivered Quantity'
}

export default DisplayOptions;