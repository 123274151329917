import React from 'react';
import styled from 'styled-components';
import { DashboardContextProvider } from './useDashboardContext';
import StyledDashboardContent from './StyledDashboardContent';
import {useWorkspacesContext} from "../context/useWorkspacesContext";

const Dashboard = ({ frozen }) => {
  const {selectedDashboard} = useWorkspacesContext();

  return (
    <DashboardContextProvider dashboard={selectedDashboard} frozen={frozen || (selectedDashboard?.shared === true)}>
      <StyledDashboardContent />
    </DashboardContextProvider>
  );
};

export default styled(Dashboard)`
  
`;
