import React, { useMemo } from 'react';
import _ from 'lodash';
import MultipleRowsBarChart from '../../../../../components/charts/MultipleRowsBarChart';

const TotalMarginsByCustomer = ({ data, config, limit = 35 }) => {
  const reducedData = useMemo(() => {
    const result = [];
    data?.forEach(item => {
      if (_.isNil(item?.margin)) return;

      let displayLabelText = `${item.primaryId} /  ${item.primaryName}`;

      if (config) {
        displayLabelText = `
        ${config.primaryLabelShowId ? item.primaryId : ''}
        ${config.primaryLabelShowId && config.primaryLabelShowDescription ? '/' : ''}
        ${config.primaryLabelShowDescription ? item?.primaryName : ''}
        `;
      }

      result.push({
        margin: item?.margin,
        accumulatedCost: item?.accumulatedCost,
        accumulatedDeliveryCostPerMinute: item?.accumulatedDeliveryCostPerMinute,
        name: displayLabelText,
        primaryId: item.primaryId,
      });
    });
    if (
      config?.groupBy === 'plant' &&
      (config?.plantMarginTrend === 'daily' || config?.plantMarginTrend === 'weekly')
    ) {
      return _.orderBy(result, ['primaryId'], ['asc']);
    }
    return _.orderBy(result, ['margin'], ['desc']);
  }, [data, config]);

  return (
    <MultipleRowsBarChart
      showXAxis={config?.displayXAxis || false}
      xAxisLabel={false}
      yAxis={false}
      data={reducedData.slice(0, limit)}
      xValueType={'number'}
      layout={'vertical'}
      yAxisWidth={200}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
      bars={[
        { name: 'Margin', key: 'margin', dataKey: 'margin', stackId: '1', fill: 'DEFAULT' },
        { name: 'Material Costs', key: 'accumulatedCost', dataKey: 'accumulatedCost', stackId: '1', fill: 'ORANGE' },
        {
          name: 'Delivery Costs',
          key: 'accumulatedDeliveryCostPerMinute',
          dataKey: 'accumulatedDeliveryCostPerMinute',
          stackId: '1',
          fill: 'GRAY',
        },
      ]}
      displayLabelList={config?.displayRowNumbers || false}
      valueFormatter={value => `$${value}`}
    />
  );
};

export default TotalMarginsByCustomer;
