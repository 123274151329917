import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './app.css';
import '../node_modules/antd/dist/antd.dark.min.css';
import queryString from 'query-string';
import { AppContextProvider } from './AppContext';

const queryParams = queryString.parse(window.location.search);

const { source, 'x-connex-id': xConnexId, lt, aet } = queryParams;

source && localStorage.setItem('source', source);
xConnexId && localStorage.setItem('x-connex-id', xConnexId);

// console.log({source, xConnexId, search: window.location.search});

ReactDOM.render(
  <AppContextProvider data={{ v4Tokens: { lt, aet } }}>
    <App />,
  </AppContextProvider>,
  document.getElementById('root')
);
