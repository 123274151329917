import styled from 'styled-components';
import style from './style';
import {useParams} from "react-router";
import API from '../../../../api';
import {useWorkspacesContext} from "../../context/useWorkspacesContext";
import Button from "../../../../components/button/Button";

const {
  deleteDashboard,
  refreshDashboards,
} = API;

const Styled = styled.div`${style}`

const RenameDashboard = ({dashboard}) => {
  const {setDrawerContent, setDrawerVisible} = useWorkspacesContext();
  const {entityRef} = useParams();

  return <Styled>
    <span>
      Are you sure you want to delete?
    </span>

    <div className={'delete-button-group'}>
      <Button metricId={'workspaces-delete-dashboard-cancel'} onClick={async () => {
        setDrawerVisible(false);
        setDrawerContent(null);
      }}>
        Cancel
      </Button>
      <Button metricId={'workspaces-delete-dashboard-submit'} onClick={async () => {
        await deleteDashboard(entityRef, dashboard.crn)
        setDrawerVisible(false);
        setDrawerContent(null);
        refreshDashboards();
      }}>
        Delete
      </Button>
    </div>
  </Styled>
}

export default RenameDashboard;