import Editor from './Editor';
import Component from './Component';

const UnbilledDeliverySummary = {
  Editor,
  Component,
  name: 'Unbilled Delivery Summary',
  description: 'Summary of tickets that have not yet been invoiced'
}

export default UnbilledDeliverySummary;
