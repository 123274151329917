import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Attachments Per Origin',
  description: 'Displays total ticket count and total attachment count per origin.'
}

export default DisplayOptions;
