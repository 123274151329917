import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {Divider, Dropdown, Menu} from 'antd';
import {callWithMetrics} from "../../../util/metrics/recordMetric";
import {useParams} from "react-router-dom";
import {kebabCase} from "lodash";

const WidgetActions = ({ className, actions, config }) => {
  const { entityRef } = useParams();

  const menu = (
    <Menu>
      {actions.map((action, key) => {

        if(action.type === 'divider') {
          return <Divider style={{margin:'0'}} />;
        }

        const metric = { type: 'user-interaction', data: { type: 'button-click', id: `workspace-widget-${action.Label.replace(/\s+/g, '-').toLowerCase()}`, widget: config.crn } };
        return (
          <Menu.Item key={key} onClick={callWithMetrics(action.handler, metric, entityRef)}>
            {action.Label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className={cn(className)} trigger={['click']}>
      <span className={'fa fa-ellipsis-v'}  data-testid={`widget-menu-${kebabCase(config.name)}`}/>
    </Dropdown>
  );
};

export default styled(WidgetActions)`
  float: right;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
`;
