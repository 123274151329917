import Editor from "./Editor";
import Component from "./Component";

const DisplayOptions = {
  Editor,
  Component,
  name: 'Custom Widget',
  description: 'Custom Visualization using a given SQL query as the basis of the data.',
}

export default DisplayOptions;