import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";


export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [totalQuantitiesByStatus, setTotalQuantitiesByStatus] = useState({ loading: false, data: [] });

  const getTotalQuantitiesByStatus = useCallback(
    (filters) => {
      const Parameters = getParametersFromFilters(filters);
      setTotalQuantitiesByStatus(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Total Quantities By Status', {Parameters}).then(rs => {
        setTotalQuantitiesByStatus({ data: rs && rs.items, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    totalQuantitiesByStatus,
    getTotalQuantitiesByStatus,
  };
};

export default useEntityMetrics;
