import React, { useCallback, useEffect } from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import TotalQuantitiesByPlant from './TotalQuantitiesByPlant';
import useAPI from './useAPI';
import Spinner from '../../../../../components/spinner/Spinner';
import styled from 'styled-components';
import cn from 'classnames';

const Component = ({ className }) => {
  const { config, filterValues, parentRef } = useWidgetContext();
  const { totalQuantitiesByPlant, getTotalQuantitiesByPlant } = useAPI();

  const refresh = useCallback(() => {
    getTotalQuantitiesByPlant(filterValues);
  }, [filterValues, getTotalQuantitiesByPlant]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={totalQuantitiesByPlant.loading}>
        <TotalQuantitiesByPlant
          config={config?.options}
          data={totalQuantitiesByPlant.data}
          limit={config?.options?.maxResults}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
