import React, {useMemo} from 'react';
import _ from 'lodash';
import { productColors, trimName } from "../../../../../components/charts/util";
import BarChart from "../../../../../components/charts/BarChart";
import getUomAbbreviationOrDescription from  '../../../../../util/uom';

const TotalQuantitiesByStatus = ({ data, config, onClick }) => {
  const fieldName = 'statusCode';
  const {sortedData, typesArrayValue} = useMemo(() => {
    const reducedData = [];
    const typesArray = [];

    if(data) {
      for (let i = 0; i < data.length; i++){
        const d = data[i];
        const itemName = trimName(d.statusCode, 'No Status');
        const newItem = {};
        const itemUOM = getUomAbbreviationOrDescription(d.UOM);
        const type = itemUOM || 'Unknown';
        const index = _.findIndex(reducedData, {statusCode: d.statusCode});
        let displayLabelText = `${itemName}`;

        switch(itemName){
          case "ACCEPTED":
            displayLabelText = `Accepted`;
            break;
          case "PENDING":
            displayLabelText = `Pending`;
            break;
          case "REJECTED":
            displayLabelText = `Rejected`;
            break;
        }

        if (!typesArray.includes(itemUOM)) {
          typesArray.push(itemUOM);
        }

        if (index > -1) {
          if (reducedData[index][d.UOM]) {
            _.set(reducedData[index], 'qty_sum', +reducedData[index].qty_sum + +d.val);
            _.set(reducedData[index], type, +reducedData[index][type] + d.val);
          } else {
            _.set(reducedData[index], 'qty_sum', +reducedData[index].qty_sum + +d.val);
            _.set(reducedData[index], type, d.val);
          }
        } else {
          _.set(newItem, fieldName, itemName);
          _.set(newItem, 'qty_sum', d.val);
          _.set(newItem, 'name', displayLabelText);
          _.set(newItem, 'type', type);
          _.set(newItem, 'unit', itemUOM);
          _.set(newItem, type, d.val);
          reducedData.push(newItem);
        }
      }
    }

    return {typesArrayValue: typesArray, sortedData: _.orderBy(reducedData, ['name'], ['asc'])};
  }, [data, config])
  return (
    <BarChart
      fieldName={fieldName}
      onClick={onClick}
      showXAxis={config?.displayXAxis || false}
      yAxis={false}
      data={sortedData}
      xValue={'qty_sum'}
      xValueType={'number'}
      layout={'vertical'}
      showTooltip={config?.displayTooltip || false}
      showLegend={config?.displayLegend || false}
      barSize={48}
      barCategoryGap={12}
      typesArray={typesArrayValue}
    />
  );
};

export default TotalQuantitiesByStatus;


