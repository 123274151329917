import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Ticket Count By Status',
  description: 'Displays Ticket Counts by customerStatus'
}

export default DisplayOptions;