import React from "react";

/**
 *
 * @param root
 * @param depth - data level in the tree
 * @param x
 * @param y
 * @param width
 * @param height
 * @param index - order that the tile is rendered
 * @param payload
 * @param rank
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */

const COLORS = {
  'Created': '#2981D7',
  'Printed': '#8889DD',
  'Delivered': '#00A82D',
}

const CustomBillable = ({ root, depth, x, y, width, height, name }) => {
  /// name && (depth === 2) && console.log(depth, name, index, root);
  const value = root?.children?.find(c => c.name === name)?.value ?? 0;

  const isValidCoordinate = (!isNaN(x) && !isNaN(y))

  return (
    <g>
      {isValidCoordinate && <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: COLORS[name] ?? '#2981D7',
          stroke: '#fff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />}
      { isValidCoordinate && (depth === 2) ? (
        <text x={x + width / 2} y={y + height / 2 + 4} textAnchor="middle" fill="#fff" fontSize={14}>
          {name}
        </text>
      ) : null}
      {(name !== 'Loading...') && isValidCoordinate && (depth === 2) ? (
        <text x={x + width / 2} y={y + height / 2 + 20} textAnchor="middle" fill="#fff" fontSize={14}>
          {value}
        </text>
      ) : null}
    </g>
  );
}

export default CustomBillable;
