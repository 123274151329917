import React, {useMemo, useState} from 'react';
import cx from 'classnames';
import Select from '../../select';
import { useDynamicFormContext } from '../context/DynamicFormContext';

const { Option } = Select;

const DynamicArray = ({ config, listValues = [], onBlur, ...otherProps }) => {
  const [touched, setTouched] = useState(false);

  const { setFieldTouched, errors, values, fieldFocused } = useDynamicFormContext();

  const handleBlur = () => {
    // Ant Design's Select doesn't pass down the name prop which
    // breaks formik validation.
    // https://github.com/ant-design/ant-design/issues/12612

    // capture the onBlur event so we can manually set touched.

    setTouched(true);
    setFieldTouched(config.id, true);
  };

  const disabled = useMemo(() => {
    if(typeof config.disabled === 'function') {
      return config.disabled(values);
    }

    return config.disabled;
  }, [config, values])

  const options = listValues || [];

  return (
    <div className="cx-dynamic-field">
      <Select
        name={config.id}
        mode="multiple"
        className={cx('cx-dynamic-select', { 'has-error': touched && errors[config.id] })}
        options={options}
        valuePath="value"
        displayPath="label"
        error={errors[config.id]}
        disabled={disabled}
        touched={touched}
        onBlur={handleBlur}
        onFocus={() => fieldFocused(config.id)}
        {...otherProps}
      />

    </div>
  );
};

export default DynamicArray;
