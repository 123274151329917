import {useCallback} from "react";
import _ from "lodash";
import moment from "moment";

export const body = {
  Parameters: [
    {
      Value: 'false',
      Type: 'bool',
      Name: 'Voided',
    },
    {
      Value: moment().startOf('day'),
      Type: 'string',
      Name: 'StartDt',
    },
    {
      Value: moment().endOf('day'),
      Type: 'string',
      Name: 'EndDt',
    },
    {
      Value: 10,
      Type: 'int',
      Name: 'Limit',
    },
    {
      Value: '["ALL"]',
      Type: 'string',
      Name: 'ItemTypes',
    },
    {
      Value: '["ALL"]',
      Type: 'string',
      Name: 'PlantIds',
    },
    {
      Value: '["ALL"]',
      Type: 'string',
      Name: 'ProjectIds',
    },
  ],
};

export const filterValue = filter => {
  if (filter && filter.length > 0) {
    let value = '[';
    filter.map((f, index) => {
      value += `${index > 0 ? ',' : ''}"${f}"`;
      return value;
    });
    value += ']';
    return value;
  }
  return '["ALL"]';
};

export const applyFilters = (filters, limit = null) => {
  const newBody = _.cloneDeep(body);
  const parameters = [];
  // Find item index using _.findIndex (thanks @AJ Richardson for comment)
  for (let i = 0; i < body.Parameters.length; i++) {
    let filter;
    let endDateTime;
    let startDateTime;
    switch (newBody.Parameters[i].Name) {
      case 'EndDt':
        endDateTime = moment(filters.endDateTime);
        filter = endDateTime.endOf('day');
        break;
      case 'StartDt':
        startDateTime = moment(filters.startDateTime);
        filter = startDateTime.startOf('day');
        break;
      case 'ItemTypes':
        filter = filterValue(filters.itemTypes);
        break;
      case 'PlantIds':
        filter = filterValue(filters.plants);
        break;
      case 'ProjectIds':
        filter = filterValue(filters.projects);
        break;
      case 'Limit':
        filter = limit || (filters.limit && filters.limit.length > 0 ? filters.limit : 10);
        break;
      case 'Voided':
        filter = 'false';
        break;
      default:
        break;
    }
    parameters.push({ Name: newBody.Parameters[i].Name, Type: newBody.Parameters[i].Type, Value: filter });
  }

  return { Parameters: parameters };
};