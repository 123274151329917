import React from 'react';
import _ from 'lodash';
import BarChart from "../../../../../components/charts/BarChart";

export default ({ data, onClick }) => {
  const acceptedVal = _.get(data, 'data.ACCEPTED', 0);
  const pendingVal = _.get(data, 'data.PENDING', 0);
  const rejectedVal = _.get(data, 'data.REJECTED', 0);

  const reducedData = [
    {
      name: 'Accepted',
      Accepted: acceptedVal,
      val: acceptedVal,
      type: 'Accepted',
    },
    {
      name: 'Pending',
      Pending: pendingVal,
      val: pendingVal,
      type: 'Pending',
    },
    {
      name: 'Rejected',
      Rejected: rejectedVal,
      val: rejectedVal,
      type: 'Rejected',
    },
  ];

  const noData = acceptedVal === 0 && pendingVal === 0 && rejectedVal === 0;

  return (
    <BarChart
      fieldName={'name'}
      onClick={onClick}
      yAxis={false}
      data={noData ? [] : reducedData}
      yValue="name"
      yValueType="category"
      xValue="val"
      xValueType="number"
      barSize={48}
      barCategoryGap={12}
      layout={'vertical'}
    />
  );
};
