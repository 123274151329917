import React, {useEffect, useMemo, useState} from "react";
import style from './style';
import styled from 'styled-components';
import {pick} from 'lodash';
import InputQuantity from "./input-quantity";
import InputText from "./input-text";
import InputSelect from "./input-select";
import InputNumber from "../../../../../../../components/form/InputNumber";

const Styled = styled.div`${style}`;

const ProductEntry = ({ id, item, products, onChange }) => {
  const [productList, setProductList] = useState(null);

  useEffect(() => {
    products && setProductList(products.map(p => {
      return {
        value: p.crn,
        label: `${p.id} / ${p.name}`,
      }
    }))
  }, [products])

  const productDescription = useMemo(() => {
    return `${item?.productId} / ${item?.productName}`
  }, [item])

  return (
    <tr key={`${id}-product-entry`}>
      <td>
        <InputQuantity id='quantity'
                       label='Quantity'
                       value={item?.quantity}
                       uomCode={item?.uomCode}
                       onChange={onChange}
                       disabled
        />
      </td>
      <td colSpan={6}>
        {!item?.isAdded && <InputText
          label='Product ID / Name'
          disabled
          className='product-description'
          value={productDescription}
        />}

        {item?.isAdded && <InputSelect id='productRef'
                                       label='Product ID / Name'
                                       value={item?.productRef}
                                       onChange={onChange}
                                       options={productList}
                                       disabled
                                       required
        />}
      </td>
    </tr>
  )
}

const PricingHeader = ({ id }) => {
  return (
    <tr key={`${id}-pricing-header`}>
      <td>Unit Price</td>
      <td>Subtotal</td>
      <td>State Tax</td>
      <td>County Tax</td>
      <td>City Tax</td>
      <td>District Tax</td>
      <td>Total</td>
    </tr>
  )
}

const PriceEntry = ({ id, item, onChange }) => {
  return (
    <tr key={`${id}-price-entry`}>
      <td><InputNumber disabled id='unitPrice' addOn={'USD'} value={item?.unitPrice} onChange={onChange}/></td>
      <td><InputNumber disabled id='extendedPrice' value={item?.extendedPrice} /></td>
      <td><InputNumber disabled id='stateTax' value={item?.stateTax} onChange={onChange}/></td>
      <td><InputNumber disabled id='countyTax' value={item?.countyTax} onChange={onChange}/></td>
      <td><InputNumber disabled id='cityTax' value={item?.cityTax} onChange={onChange}/></td>
      <td><InputNumber disabled id='districtTax' value={item?.districtTax} onChange={onChange}/></td>
      <td><InputNumber disabled id='totalPrice' value={item?.totalPrice} /></td>
    </tr>
  )
}

const fields = [
  'quantity', 'uomCode', 'unitPrice',
  'stateTax', 'countyTax', 'cityTax',
  'districtTax', 'extendedPrice', 'totalPrice',
  'productId', 'productName', 'isAdded',
];

const LineItem = ({ lineItem, products, index, onChange }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    lineItem && setItem({
      index,
      ...pick(lineItem, fields),
    })
  }, [lineItem]);

  const itemChanged = () => {
  }

  const rows = [];

  if (item !== null) {
    const id = `lineItem[${index}]`;

    rows.push(<ProductEntry item={item} products={products} id={id} onChange={itemChanged}/>)

    rows.push(<PricingHeader onChange={onChange} id={id}/>);

    rows.push(<PriceEntry item={item} id={`lineItem[${index}]`} onChange={itemChanged}/>);
  }

  return (
    <Styled>
      {rows}
    </Styled>
  )
}

export default LineItem;
