import React from 'react';
import _ from 'lodash';
import useFitText from '../../components/useFitText'

const AvgLoadTime = ({ data}) => {
  const value = JSON.stringify(data, null, 2);
  const {fontSize, ref, parentRef} = useFitText(value); 
  const avgLoadTime = data?.items?.[0]?.avgLoadTime || 0;

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        <div>{avgLoadTime} {'mins'}</div>
      </div>
    </div>
  );
};

export default AvgLoadTime;