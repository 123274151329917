import 'react-grid-layout/css/styles.css';
import Dashboard from './dashboard/Dashboard';
import { WorkspacesContextProvider } from './context/useWorkspacesContext';
import React from 'react';

const Workspaces = () => {
  return (
    <WorkspacesContextProvider>
      <Dashboard />
    </WorkspacesContextProvider>
  );
};

export default Workspaces;
