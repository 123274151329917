import { Drawer as AntDrawer } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { callWithMetrics } from '../../util/metrics/recordMetric';
import style from './style';

export const Drawer = ({ metricId, onClose, innerRef, ...props }) => {
  let closeHandler = onClose;

  const { entityRef } = useParams();

  if (metricId) {
    const metric = { type: 'user-interaction', data: { type: 'close-drawer', id: metricId } };
    closeHandler = callWithMetrics(onClose, metric, entityRef);
  }

  return <AntDrawer {...props} onClose={closeHandler} ref={innerRef} />;
};

export default styled(Drawer)`
  ${style}
`;
