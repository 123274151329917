import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from "../../../filters/getParametersFromFilters";

export const useEntityMetrics = () => {
  const { appId, entityRef } = useParams();
  const [ticketsProduced, setTicketsProduced] = useState({ loading: false, data: 0 });

  const getTicketsProduced = useCallback(
    (filters, limit) => {
      const Parameters = getParametersFromFilters(filters);
      setTicketsProduced(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Total Tickets Produced', {Parameters}).then(result => {
        setTicketsProduced({ data: result, loading: false });
      });
    },
    [appId, entityRef]
  );

  return {
    ticketsProduced,
    getTicketsProduced,
  };
};

export default useEntityMetrics;