import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Input, Space } from 'antd';
import presetWidgets from './preset-widgets';
import Button from '../../../components/button/Button';
import {useWorkspacesContext} from "../context/useWorkspacesContext";

const getEditor = config => {
  let widgetType = config.crn;

  let visualizationEditor = presetWidgets[widgetType].Editor;

  if (!visualizationEditor) {
    return null;
  }

  return visualizationEditor;
};

const WidgetEditor = ({ className, config}) => {
  const DataEditor = getEditor(config);
  const {setDrawerVisible, saveWidget} = useWorkspacesContext();
  const [editingConfig, setEditingConfig] = useState(config);

  const onChange = useCallback(
    (id, value) => {
      const newConfig = { ...editingConfig };
      newConfig[id] = value;
      setEditingConfig(newConfig);
    },
    [setEditingConfig, editingConfig]
  );

  return (
    <Space className={cn(className)} direction={'vertical'}>
      <div>Display Name</div>
      <Form.Item className={'no-bottom-margin'}>
        <Input defaultValue={config.name} onChange={e => onChange('name', e.target.value)} />
      </Form.Item>
      <DataEditor
        widget={editingConfig}
        options={editingConfig.options}
        onChange={(options) => onChange('options', options)}
      />
      <Space>
        <Button onClick={() => setDrawerVisible(false)}>Cancel</Button>
        <Button type="primary" onClick={() => {
          saveWidget(editingConfig);
          setDrawerVisible(false);
        }}>
          Save
        </Button>
      </Space>
    </Space>
  );
};

export default styled(WidgetEditor)`
  width: 100%;
  margin-top: 15px;
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;
