import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../api';
import getParametersFromFilters from '../../../filters/getParametersFromFilters';

export const useEntityMetrics = () => {
  const { entityRef } = useParams();
  const [totalQuantitiesByPlant, setTotalQuantitiesByPlant] = useState({ loading: false, data: [] });

  const getTotalQuantitiesByPlant = useCallback(
    filters => {
      const Parameters = getParametersFromFilters(filters);
      setTotalQuantitiesByPlant(t => ({ data: t.data, loading: true }));
      API.executeQueryByName(entityRef, 'Total Quantities By Plant', { Parameters }).then(rs => {
        setTotalQuantitiesByPlant({ data: rs && rs.items, loading: false });
      });
    },
    [entityRef]
  );

  return {
    totalQuantitiesByPlant,
    getTotalQuantitiesByPlant,
  };
};

export default useEntityMetrics;
