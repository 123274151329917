import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ModalContextProvider } from './components/modal-context/ModalContext';
import Workspaces from './views/workspaces';

const AuthenticatedRoutes = () => {
  return (
    <ModalContextProvider>
      <Routes>
        <Route path={'workspaces/:dashboardRef/*'} element={<Workspaces />} />
      </Routes>
    </ModalContextProvider>
  );
};

export default AuthenticatedRoutes;
