import React, { useCallback, useEffect } from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import TicketsByDeliveryMethod from './TicketsByDeliveryMethod';
import useAPI from './useAPI';
import Spinner from "../../../../../components/spinner/Spinner";
import cn from "classnames";
import styled from "styled-components";

const Component = ({ className }) => {
  const { config, filterValues, parentRef} = useWidgetContext();
  const {
    ticketsByDeliveryMethod,
    getTicketsByDeliveryMethod,
  } = useAPI();

  const refresh = useCallback(() => {
    getTicketsByDeliveryMethod(filterValues);
  }, [filterValues, getTicketsByDeliveryMethod]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div ref={parentRef} className={cn(className)}>
      <Spinner spin={ticketsByDeliveryMethod.loading}>
        <TicketsByDeliveryMethod
         config={config?.options}
          data={ticketsByDeliveryMethod.data}
        />
      </Spinner>
    </div>
  );
};

export default styled(Component)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;