import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Probe Vehicles',
  description: 'Lists Probe Ticket Counts per Vehicle.'
}

export default DisplayOptions;
