const createSummary = billables => {
  const summary = [];

  const progress = {
    name: 'progress',
    children: [
      { name: 'Created', size: billables.filter(b => b.statusCode === 'CREATED').length },
      { name: 'Printed', size: billables.filter(b => b.statusCode === 'PRINTED').length },
      { name: 'Shipped', size: billables.filter(b => b.statusCode === 'TO_JOB').length },
      { name: 'Delivered', size: billables
          .filter(b => b.statusCode === 'ARRIVE_JOB' ||
            b.statusCode === 'UNLOADING' ||
            b.statusCode === 'END_UNLOADING' ||
            b.statusCode === 'ARRIVE_JOB' ||
            b.statusCode === 'TO_JOB'
          ).length
      },

    ].filter(p => p.size > 0),
  };

  summary.push(progress)

  summary.push({
    name: 'problems',
    children: [
      { name: 'Price', size: billables.filter(b => !b["isPriced"]).length },
      { name: 'Tax', size: billables.filter(b => !b["isTaxed"]).length },
      { name: 'Project', size: billables.filter(b => !b["hasProject"]).length },
      { name: 'Address', size: billables.filter(b => b["hasPartialAddress"]).length },
    ].filter(p => p.size > 0),
  })

  return summary
}

export default createSummary;
