import React from 'react';
import { ConnexDataGrid } from '../../../../../components/connex-data-grid/components';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const Styled = styled.div`
  width: 100%;
  height: 100%;

  table {
    height: 100%;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  .fa-edit {
    cursor: pointer;

    &:hover {
      color: #0e4980;
    }
  }
`;

const columns = [
  { key: 'dispatchDate', name: 'Dispatch Date' },
  { key: 'dispatchOrderId', name: 'Dispatch Order' },
  { key: 'id', name: 'Ticket' },
  { key: 'origin', name: 'Origin' },
];

const getURLPrefix = () => {
    const { origin } = window.location;
    if (origin.includes('localhost')) {
        return 'http://localhost:3000';
    }
    if (origin.includes('develop')) {
        return 'https://connex-stage.commandalkon.io';
    }
    if(origin.includes('model')) {
        return 'https://connex-model.commandalkon.io';
    }
    return 'https://connex.commandalkon.io';
};

const TicketsMissingAttachments = ({ data, loading, config }) => {
    const { entityRef } = useParams();
    const urlPrefix = getURLPrefix();

     const handleRowClick = React.useCallback(
      row => {
          const ticketType = config.options?.ticketType;
          let ticket_path;

           if (ticketType === 'buyer') {
               ticket_path = `/apps/connex/${entityRef}/tickets/accept-materials/${row.crn}`;
           }
           else {
               ticket_path = `/apps/connex/${entityRef}/tickets/view-shipments/${row.crn}`;
           }
          window.open(`${urlPrefix}/#${ticket_path}` , "_blank");
      },
      [config?.options?.ticketType, data]
    );

  return (
    <Styled>
      <ConnexDataGrid
        showFiltersByDefault
        filterable
        loading={loading}
        gridName={`${config.crn}::${config.id}`}
        friendlyName={config.name}
        columns={columns}
        rows={Array.isArray(data) ? data : []}
        rowKeyPath="crn"
        onRowClick={handleRowClick}
        rowClickable
        autoSizeColumns
      />
    </Styled>
  );
};

export default TicketsMissingAttachments;
