import React from 'react';
import Select from '../../select';

export const Zone = props => {
  return (
    <div className="zone">
      <Select {...props} displayPath="name" valuePath="name" />
    </div>
  );
};

export default Zone;
