import Editor from './Editor';
import Component from './Component';

const BigNumber = {
  Editor,
  Component,
  name: 'Total Quantities By Hour',
  description: 'Displays Total Quantities By Hour.'
}

export default BigNumber;