import table  from './table';
import unshared  from './unshared';
import bigNumber  from './big-number';

const visualizations = {
  table,
  unshared,
  bigNumber,
}

export default visualizations;